import { LoKationButton } from '@components/button/button'
import { HorizontalRule } from '@components/horizontal-rule/horizontal-rule'
import { Paper } from '@components/paper/paper'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { useLoaderData } from 'react-router-dom'

import { FAQ } from '../../services/faq/faq.types'

export function SingleFAQRoute() {
	const faq = useLoaderData() as FAQ | undefined

	if (!faq) {
		return <div>ERROR</div>
	}

	return (
		<RouteWrapperV2>
			<RouteBodyV2 className="mb-20 flex flex-column">
				<LoKationButton
					to="/faqs"
					className="mb-10"
					label="All Frequently Asked Questions"
					icon={'arrow-left'}
					iconPosition={'left'}
					variant={'text'}
					size={'lg'}
				/>
				<Paper bgColor="primary" padding={['all']} className="col-xs-12">
					<h1>{faq.question}</h1>
					<HorizontalRule className="my-20" />
					<div dangerouslySetInnerHTML={{ __html: faq.answer }} />
				</Paper>
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}
