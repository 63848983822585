import { useEffect, useState } from 'react'

export const DISMISS_DELAY = 500

type HookProps = {
	isPopoverOpen: boolean
	delay?: number
	setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export namespace PopoverServices {
	// Hook to aid hiding and showing of popovers when a user mouses into an element
	export function usePopover(props: HookProps) {
		const [showPopover, setShowPopover] = useState(false)
		const [showPopoverTimer, setShowPopoverTimer] = useState<null | NodeJS.Timeout>(null)

		const delay = props.delay ?? DISMISS_DELAY

		useEffect(() => {
			if (delay > 0) {
				if (props.isPopoverOpen) {
					const timer = setTimeout(() => {
						setShowPopover(true)
						props.setIsPopoverOpen(true)
					}, delay)
					setShowPopoverTimer(timer)
				} else {
					if (showPopoverTimer) {
						clearTimeout(showPopoverTimer)
					}
					setShowPopoverTimer(null)
					setShowPopover(false)
					props.setIsPopoverOpen(false)
				}
			} else {
				if (props.isPopoverOpen) {
					setShowPopover(true)
					props.setIsPopoverOpen(true)
				} else {
					setShowPopover(false)
					props.setIsPopoverOpen(false)
				}
			}

			/**
			 * Note: the condition below occurs when a user tells the popover to close before it was shown
			 * to the user in the first place (i.e. a cancellation of opening the popover)
			 */
			if (!props.isPopoverOpen && showPopoverTimer) {
				setShowPopover(false)
				props.setIsPopoverOpen(false)
				setShowPopoverTimer(null)
			}
		}, [props.isPopoverOpen])

		return { showPopover, setShowPopover: props.setIsPopoverOpen }
	}
}
