import { GoogleFont } from './google-api.types'

const USERS_GOOGLE_API_KEY = 'AIzaSyB1WN8tTsOe-Sog3ua2CeU7QhRxsoRSESY'
const USERS_GOOGLE_CALENDAR_ID =
	'fd152fa26ddf46517a6d857a5368318532e2599290b15a126e10a099ad9725d9@group.calendar.google.com'

/** Documentation */
/** https://github.com/google/google-api-javascript-client/ */
/** https://medium.com/google-cloud/gapi-the-google-apis-client-library-for-browser-javascript-5896b12dbbd5 */
/** https://www.googleapis.com/discovery/v1/apis/webfonts/v1/rest */

export class GoogleAPI {
	static async loadGAPI(): Promise<void> {
		return new Promise((resolve) => {
			if ('gapi' in window) {
				resolve()
			} else {
				const script = document.createElement('script')
				script.src = 'https://apis.google.com/js/api.js'
				script.addEventListener('load', (evt) => {
					gapi.load('client', () => {
						gapi.client
							.init({
								apiKey: USERS_GOOGLE_API_KEY,
							})
							.then((res) => {
								const allModulsPromises: Promise<void>[] = []
								allModulsPromises.push(
									gapi.client.load('https://www.googleapis.com/discovery/v1/apis/webfonts/v1/rest'),
								)
								allModulsPromises.push(
									gapi.client.load('https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'),
								)

								Promise.all(allModulsPromises).then(() => {
									resolve()
								})
							})
					})
				})
				document.head.insertBefore(script, null)
			}
		})
	}

	static getFonts(): Promise<GoogleFont[]> {
		return new Promise((resolve) => {
			this.loadGAPI().then(() => {
				//@ts-ignore
				gapi.client.webfonts.webfonts
					.list({ sort: 'POPULARITY' })
					.then((res: { result: { items: GoogleFont[] } }) => {
						resolve(res.result.items)
					})
			})
		})
	}

	static getCalendarEvents(): Promise<unknown> {
		return new Promise((resolve) => {
			this.loadGAPI().then(() => {
				gapi.load('client:auth2', () => {
					gapi.auth2.init({ client_id: USERS_GOOGLE_API_KEY }).then(() => {
						// @ts-ignore
						gapi.client.calendar.calendarList
							.get({
								calendarId: USERS_GOOGLE_CALENDAR_ID,
							})
							.then((res: unknown) => {
								resolve(res)
							})
					})
				})
			})
		})
	}

	static loadFontToDom(fontFamily: string): void {
		const linkElt = document.createElement('link')
		linkElt.href = `https://fonts.googleapis.com/css2?family=${fontFamily}:wght@300;400;500;600;700&display=swap`
		linkElt.rel = 'stylesheet'
		document.body.appendChild(linkElt)
	}
}
