import './messages-admin.scss'

import { LoKationButton } from '@components/button/button'
import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { ListItem } from '@components/list-item/list-item'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Tabs } from '@components/tabs/tabs'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { DateService } from '../../../services/date.service'
import useAdminRedirect from '../../../services/hooks/user-admin-redirect'
import { MessageDraftsAPI } from '../../../services/messaging-service/message-drafts.api'
import { MessageDraftTypes } from '../../../services/messaging-service/message-drafts.types'
import { MessagingServiceService } from '../../../services/messaging-service/messaging-service.service'
import { EndUserProps } from '../../../services/user/user.types'
import { Utilities } from '../../../services/utilities.service'
import { ComposeMessageSideSheet } from './components/compose-message__side-sheet/compose-message__side-sheet'
import { MessagesAdminRouteNoSelectedDraftPlaceholder } from './messages-admin__no-selected-draft-placeholder'
import { useMessagesAdmin, useMessagesAdminDispatch } from './state/messages-admin__state'
interface ConnectedProps {
	user: EndUserProps | null
}

export function MessagesAdminInnerPrototype(props: ConnectedProps) {
	/** ================================= */
	/** State */

	const navigate = useNavigate()
	const [messageView, setMessageView] = useState<'messageDrafts' | 'sentMessages'>('messageDrafts')
	const [disableFields, setDisabledFields] = useState<boolean>(false)

	/** ================================= */
	/** Hooks */
	useAdminRedirect(props.user)
	const messagesState = useMessagesAdmin()
	const messagesDispatch = useMessagesAdminDispatch()

	/** ================================= */
	/** Effects */

	useEffect(() => {
		MessageDraftsAPI()
			.getDrafts(false, {
				page: 0,
				size: 50,
			})
			.then((res) => {
				messagesDispatch({ type: 'get-drafts', payload: res.data })
			})
		MessageDraftsAPI()
			.getDrafts(true, {
				page: 0,
				size: 50,
			})
			.then((res) => {
				messagesDispatch({ type: 'get-sentMessages', payload: res.data })
			})
	}, [])

	useEffect(() => {
		if (messageView === 'sentMessages') {
			setDisabledFields(true)
		} else if (messageView === 'messageDrafts') {
			setDisabledFields(false)
		}
	}, [messageView])

	/** ================================= */
	/** Methods */

	function sortDraftsByDate(): MessageDraftTypes.MessageDraft[] {
		if (!messagesState.drafts) {
			return []
		}

		return messagesState.drafts.sort((a, b) => {
			return a.lastupdatedtimestamp > b.lastupdatedtimestamp ? -1 : 1
		})
	}

	function sortSentMessagesByDate(): MessageDraftTypes.MessageDraft[] {
		if (!messagesState.sentMessages) {
			return []
		}

		return messagesState.sentMessages.sort((a, b) => {
			return a.lastupdatedtimestamp > b.lastupdatedtimestamp ? -1 : 1
		})
	}

	/** ================================= */
	/** Render Component */

	return (
		<>
			<RouteWrapperV2>
				<RouteSubnavigation
					className="pr-0 flex flex-column"
					variant={'wide'}
					title={`Messages`}
					backButton={{
						label: 'Administration',
						onClick: () => {
							navigate(`/administration`)
						},
					}}
					isCustom={true}
				>
					<Tabs
						className="col-xs-12 pr-20"
						options={[
							{
								name: 'Message Drafts',
								id: 'messageDrafts',
								onClick: () => {
									setMessageView('messageDrafts')
								},
								width: 'evenly-distributed',
							},
							{
								name: 'Sent Messages',
								id: 'sentMessages',
								onClick: () => {
									setMessageView('sentMessages')
								},
								width: 'evenly-distributed',
							},
						]}
						selectedOptionId={messageView}
						handleUpdateSelectedOption={() => {}}
					/>

					<div className="overflow-y__scroll flex flex-column flex-fillSpace">
						{!messagesState.drafts && (
							<div className="flex flex-column flex-alignItems-center flex-justifyContent-center flex-fillSpace">
								<GenericContentLoader
									width={'fill'}
									height={60}
									className="mb-10"
								></GenericContentLoader>
								<GenericContentLoader
									width={'fill'}
									height={60}
									className="mb-10"
								></GenericContentLoader>
							</div>
						)}

						{messagesState.drafts &&
							messagesState.drafts.length === 0 &&
							messageView === 'messageDrafts' && (
								<div className="flex flex-column flex-alignItems-center flex-justifyContent-center flex-fillSpace border-radius__std">
									<FontAwesomeIcon
										icon={['far', 'mailbox']}
										size="4x"
										style={{ opacity: '0.75' }}
										className="mb-20"
									/>
									<Typography type="h6">You have not created any message drafts</Typography>
								</div>
							)}
						{messagesState.sentMessages &&
							messagesState.sentMessages.length === 0 &&
							messageView === 'sentMessages' && (
								<div className="flex flex-column flex-alignItems-center flex-justifyContent-center flex-fillSpace border-radius__std">
									<FontAwesomeIcon
										icon={['far', 'mailbox']}
										size="4x"
										style={{ opacity: '0.75' }}
										className="mb-20"
									/>
									<Typography type="h6">You have not sent any messages</Typography>
								</div>
							)}

						{messageView === 'messageDrafts' && (
							<section id="messagesDrafts">
								{sortDraftsByDate().map((draft) => {
									return (
										<ListItem
											body={
												<div className="col-xs-12">
													<div>
														<strong>
															{Utilities.truncateString(
																Utilities.stripHTMLTagsFromString(draft.message),
																100,
															)}
														</strong>
													</div>
													<div className="mt-5 opacity-60">
														<em>
															{DateService.getFormattedDateFromDateObj(
																new Date(draft.lastupdatedtimestamp),
															)}
														</em>
													</div>
												</div>
											}
											onClick={() => {
												messagesDispatch({ type: 'select-message', payload: draft })
											}}
										/>
									)
								})}
							</section>
						)}

						{messageView === 'sentMessages' && (
							<section id="sentMessages">
								<div className="message-list-container">
									{sortSentMessagesByDate().map((sentMessage) => {
										return (
											<ListItem
												body={
													<div className="col-xs-12">
														<div>
															<strong>
																{Utilities.truncateString(
																	Utilities.stripHTMLTagsFromString(
																		sentMessage.message,
																	),
																	100,
																)}
															</strong>
														</div>
														<div className="mt-5 opacity-60">
															<em>
																{DateService.getFormattedDateFromDateObj(
																	new Date(sentMessage.lastupdatedtimestamp),
																)}
															</em>
														</div>
													</div>
												}
												onClick={() => {
													messagesDispatch({
														type: 'select-message',
														payload: sentMessage,
													})
												}}
											/>
										)
									})}
								</div>
							</section>
						)}
					</div>

					<div className="bg-color__adjust-0 pt-20 pr-20">
						<LoKationButton
							label="Compose"
							variant="contained"
							size="sm"
							icon={'pen-to-square'}
							className="col-xs-12"
							onClick={() => {
								setMessageView('messageDrafts')
								const newMessageProps = MessagingServiceService().getNewMessageDraftProps()
								messagesDispatch({ type: 'select-message', payload: newMessageProps })
							}}
						/>
					</div>
				</RouteSubnavigation>

				<RouteBodyV2 className="col-xs-12 flex flex-column">
					<div className="flex flex-justifyContent-spaceBetween flex-alignItems-center">
						<h1 className="mb-20">Messages</h1>
					</div>
					<div className="col-xs-12 flex flex-column height__100pct p-20">
						{messagesState.selectedMessage === null && (
							<>
								<MessagesAdminRouteNoSelectedDraftPlaceholder messageText={messageView} />
							</>
						)}
					</div>
				</RouteBodyV2>
			</RouteWrapperV2>
			{messagesState.selectedMessage && (
				<ComposeMessageSideSheet
					message={messagesState.selectedMessage}
					onClose={() => {
						messagesDispatch({ type: 'select-message', payload: null })
					}}
					onSendDraft={(draftId) => {
						messagesDispatch({ type: 'delete-message', payload: draftId })
					}}
					disableFields={disableFields}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		user: state.user,
	}
}

export const MessagesAdminInner = connect(mapStateToProps)(MessagesAdminInnerPrototype)
