import { CurrentSessionSliceProps } from '@redux/reducers/current-session-reducer'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { UserAPI } from 'src/services/user/user.api'
import { EndUserProps } from 'src/services/user/user.types'

import { UserProfileProvider } from '../administration/user-profile/state/user-profile__state'
// import { UserProfileProvider } from '../administration/user-profile/state/user-profile__state'
import { Rejoin } from './rejoin'

export function RejoinRoutePrototype(props: { currentSession: CurrentSessionSliceProps }) {
	const token = props.currentSession.token

	const [mergedUserProps, setMergedUserProps] = useState<EndUserProps | null>(null)

	useEffect(() => {
		if (token) {
			UserAPI.getUserDetails(token.id).then((res) => {
				setMergedUserProps(res.data)
			})
		}
	}, [])

	if (!mergedUserProps) {
		return <></>
	}

	return (
		<UserProfileProvider userToEdit={mergedUserProps}>
			{mergedUserProps && <Rejoin userToEdit={mergedUserProps} />}
		</UserProfileProvider>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentSession: state.currentSession,
	}
}

export const RejoinRoute = connect(mapStateToProps)(RejoinRoutePrototype)
