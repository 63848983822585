import './desktop-app-header.scss'

import { RootState } from '@redux/store'
import { connect, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'

import { UserDropdownMenu } from '../../routes/shared/components/user-dropdown-menu/user-dropdown-menu'
import { ColorService } from '../../services/color/color.service'
import { DomainTheme } from '../../services/theme/theme.types'
import { EndUserProps } from '../../services/user/user.types'
import { IconButton } from '../icon-button/icon-button'
import { NotificationsButton } from './components/notifications-button/notifications-button'

export type AppHeaderStyle = 'FULL' | 'MOBILE'

function DesktopAppHeaderPrototype(props: { user: EndUserProps | null; theme: DomainTheme.CompleteTheme }) {
	const themeProps = props.theme

	const navigate = useNavigate()
	const isTrayDocked = useSelector((state: RootState) => state.ui.isTrayDocked)

	return (
		<div className="desktop-app-header">
			<div className="desktop-app-header__inner">
				<div className="flex-fillSpace flex flex-alignItems-center">
					{!isTrayDocked && (
						<>
							{ColorService.getColorTone(themeProps.appHeaderBgColor) === 'dark' && (
								<img
									onClick={() => {
										navigate(`/hubs`)
									}}
									src={themeProps.logoWhite}
									className="App-logo"
									alt="logo"
									height="20"
									style={{ marginRight: '50px' }}
								/>
							)}
							{ColorService.getColorTone(themeProps.appHeaderBgColor) === 'light' && (
								<img
									onClick={() => {
										navigate(`/hubs`)
									}}
									src={themeProps.logo}
									className="App-logo"
									alt="logo"
									height="20"
									style={{ marginRight: '50px' }}
								/>
							)}
						</>
					)}
				</div>
				<div className="flex flex-alignItems-center">
					<NotificationsButton />

					<NavLink to="/search">
						<IconButton
							tooltip="Search"
							tooltipPlacement="bottom"
							icon={'search'}
							color={themeProps.appHeaderTextColor}
							className="ml-10"
							id="desktop-app-header__search__btn"
						/>
					</NavLink>

					<UserDropdownMenu />
				</div>
			</div>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		user: state.user,
		theme: state.theme,
	}
}

export const DesktopAppHeader = connect(mapStateToProps)(DesktopAppHeaderPrototype)
