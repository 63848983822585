import './button.scss'

import { IconName, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

type LoKationButtonVariant = 'contained' | 'outlined' | 'text'
type LoKationIconPosition = 'left' | 'right'
type LoKationButtonSize = 'lg' | 'sm'

export interface LoKationButtonProps {
	variant: LoKationButtonVariant
	label: string
	onClick?: (evt: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void | Promise<void>
	style?: React.CSSProperties
	className?: string
	to?: string
	icon?: IconName
	iconPosition?: LoKationIconPosition
	iconSize?: SizeProp
	disabled?: boolean
	size: LoKationButtonSize
	primary?: boolean
	isPending?: boolean
}

export function LoKationButton(props: LoKationButtonProps) {
	const [isPending, setIsPending] = useState(false)
	const isPrimaryButton = getIsPrimaryButton()

	/** ======================================= */
	/** Render Component */

	function getIsPrimaryButton(): boolean {
		return (typeof props.primary === 'boolean' && props.primary === true) || typeof props.primary === 'undefined'
	}

	function handleClick(evt: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void {
		if (isPending || props.isPending) {
			return
		}

		evt.stopPropagation()
		if (props.onClick) {
			const clickResponse = props.onClick(evt)
			if (clickResponse) {
				setIsPending(true)
				clickResponse.finally(() => {
					setIsPending(false)
				})
			}
		}
	}

	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`button`)

		classes.push(props.variant)
		classes.push(props.size)

		if (isPrimaryButton) {
			classes.push(`primary`)
		} else {
			classes.push(`secondary`)
		}

		if (props.icon) {
			classes.push(`flex flex-alignItems-center`)
		}

		if (props.className) {
			classes.push(props.className)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	function renderButtonContents(): JSX.Element {
		if (isPending || props.isPending) {
			return (
				<>
					<FontAwesomeIcon icon={['fas', 'spinner']} spin={true} />
				</>
			)
		}

		return (
			<>
				{props.icon && props.iconPosition === 'left' && (
					<FontAwesomeIcon
						icon={['far', props.icon]}
						size={props.iconSize}
						className="mr-10"
					></FontAwesomeIcon>
				)}
				{props.label}
				{props.icon && props.iconPosition === 'right' && (
					<FontAwesomeIcon
						icon={['far', props.icon]}
						size={props.iconSize}
						className="ml-10"
					></FontAwesomeIcon>
				)}
			</>
		)
	}

	/** ======================================= */
	/** Render Component */

	if (props.to) {
		return (
			<NavLink
				to={props.to}
				style={props.style}
				onClick={handleClick}
				className={({ isActive }) => `${getClass()} ${isActive ? 'active' : ''}`}
			>
				{renderButtonContents()}
			</NavLink>
		)
	} else {
		return (
			<button
				style={props.style}
				onClick={handleClick}
				disabled={typeof props.disabled === 'boolean' ? props.disabled : false}
				className={getClass()}
			>
				{renderButtonContents()}
			</button>
		)
	}
}
