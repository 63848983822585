import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'

import { LoginCarouselEditor } from '../login-carousel-editor'
import { useDomainAdmin, useDomainAdminDispatch } from '../state/domain-admin__state'

export function DomainAdminLoginSection() {
	const domainAdminState = useDomainAdmin()
	const domainAdminDispatch = useDomainAdminDispatch()

	return (
		<>
			<h1 className="mb-10">Login</h1>
			<Paper bgColor="primary" padding={['all']} marginSize="section" margins={['bottom']}>
				<Typography type="h6" color="primary">
					Carousel Text
				</Typography>
				<Typography type="normal" color="secondary" margins={['bottom']}>
					Present text to prospective users on the login page. If more than one text block is added to the
					carousel, one block will be randomly presented to the user on each page refresh.
				</Typography>
				<LoginCarouselEditor
					className="mb-40"
					nodes={domainAdminState.modified.loginCarousel}
					updateCarousel={(updatedCarousel) => {
						domainAdminDispatch({ type: 'update-login-carousel', payload: updatedCarousel })
					}}
				/>
			</Paper>
		</>
	)
}
