import './news-feed__story-preview.scss'

import { Typography } from '@components/text/text'
import { useSearchParams } from 'react-router-dom'

import { DateService } from '../../services/date.service'
import { NewsFeed } from '../../services/news-feed/news-feed.types'
import { Utilities } from '../../services/utilities.service'
import { Paper } from '../paper/paper'

export function NewsFeedStoryPreview(props: NewsFeed.Story) {
	const [searchParams, setSearchParams] = useSearchParams()

	function openStory(): void {
		searchParams.set('story', String(props.newsStoryId))
		setSearchParams(searchParams)
	}

	return (
		<>
			<Paper
				bgColor="primary"
				className={`news-feed__story-preview`}
				onClick={() => {
					openStory()
				}}
				onKeyDown={(evt) => {
					if (evt.key === 'Enter') {
						openStory()
					}
				}}
				tabIndex={0}
			>
				<div className="news-feed__story-header">
					<Typography type="strong" color="primary">
						{Utilities.truncateString(props.title, 100)} <span>·</span>{' '}
						<span className="opacity-60">
							{DateService.getFormattedDateFromISO8601(props.createdTimestamp)}
						</span>
					</Typography>
				</div>
				<Typography type="normal" color="secondary">
					{Utilities.truncateString(Utilities.stripHTMLTagsFromString(props.description), 100)}
				</Typography>
			</Paper>
		</>
	)
}
