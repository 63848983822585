/* eslint-disable react/jsx-pascal-case */
import { LoKationButton } from '@components/button/button'
import { IconButton } from '@components/icon-button/icon-button'
import { Paper } from '@components/paper/paper'
import { SideSheet__Section } from '@components/side-sheet/side-sheet__section'
import { RootState } from '@redux/store'
import _ from 'lodash'
import { useState } from 'react'
import React from 'react'
import { connect } from 'react-redux'
import { GenericItemSelectorModal } from 'src/modals/generic-item-selector/generic-item-selector'
import { HubsService } from 'src/services/hubs/hubs.service'
import { Hub } from 'src/services/hubs/hubs.types'
import { Utilities } from 'src/services/utilities.service'

import { Ad__ToggleSelector } from '../../ad__toggle-selector'
import { useAdEditor, useAdEditorDispatch } from '../../state/ad-editor__state'
import { Ad__SideSheet__Placement__HeaderConfig } from './ad__side-sheet__placement__header-config'
import { Ad__SideSheet__Placement__ResourceConfig } from './ad__side-sheet__placement__resource-config'

type ConnectedProps = {
	hubs: Hub[]
}

function Ad__SideSheet__Tab__DesignPrototype(props: ConnectedProps) {
	const adEditorState = useAdEditor()
	const adEditorDispatch = useAdEditorDispatch()
	const [showHubModal, setShowHubModal] = useState(false)
	const resourceStyleAdImage = adEditorState.modifiedAdImages.length > 0 ? adEditorState.modifiedAdImages[0] : null

	const selectedHubs = HubsService.getHubsFromIds(props.hubs, adEditorState.modifiedAd.applicableHubs)

	/** ================================ */
	/** Methods */

	function createHubListItem(hubId: number): React.ReactNode {
		const hubProps = props.hubs.find((hub) => hub.hubId === hubId)

		if (!hubProps) {
			return <React.Fragment key={hubId}></React.Fragment>
		}

		return (
			<Paper
				bgColor="primary"
				padding={['all']}
				key={hubId}
				className="flex flex-alignItems-center flex-justifyContent-spaceBetween col-xs-12"
			>
				<strong>{hubProps.title}</strong>
				<IconButton
					icon="times"
					onClick={() => {
						removeHub(hubProps)
					}}
				/>
			</Paper>
		)
	}

	/** Removes a single hub from the selected hubs list */
	function removeHub(hub: Hub): void {
		let updatedHubs = _.cloneDeep(adEditorState.modifiedAd.applicableHubs)
		updatedHubs = updatedHubs.filter((thisHubId) => thisHubId !== hub.hubId)
		adEditorDispatch({ type: 'update-property', payload: { applicableHubs: updatedHubs } })
	}

	/** ================================ */
	/** Render Component */

	return (
		<>
			<strong>Design your ad and determine its placement with LoKation Sphere</strong>
			<SideSheet__Section label="Placement" icon="location-pin" mode="expandable" sectionKey="account__placement">
				<Ad__ToggleSelector
					name={`Header Banner`}
					description={`Place this ad in the header of a hub. The header will receive prominent placement at the top of the page`}
					selected={adEditorState.modifiedAd.placement === 'HUB_HEADER'}
					disabled={adEditorState.originalAd.advertisementId > 0}
					onClick={() => {
						adEditorDispatch({
							type: 'change-ad-type',
							payload: 'HUB_HEADER',
						})
						adEditorDispatch({
							type: 'clear-ad-images',
							payload: null,
						})
					}}
				/>
				<Ad__ToggleSelector
					name={`Sponsored Resource`}
					description={`Place this ad as a sponsored hub inline with other resources. This resource will be marked as "sponsored"`}
					selected={adEditorState.modifiedAd.placement === 'SPONSORED_RESOURCE'}
					disabled={adEditorState.originalAd.advertisementId > 0}
					onClick={() => {
						adEditorDispatch({
							type: 'change-ad-type',
							payload: 'SPONSORED_RESOURCE',
						})
						adEditorDispatch({
							type: 'clear-ad-images',
							payload: null,
						})
						adEditorDispatch({
							type: 'create-ad-image',
							payload: {
								advertisementImageId: 0 - Utilities.generateRandomInt(1, 10000000),
								platform: 'DESKTOP',
								base64Image: '',
								impressions: 0,
								link: '',
							},
						})
					}}
				/>
			</SideSheet__Section>
			<SideSheet__Section
				label="Applicable Hubs"
				icon="chart-network"
				mode="expandable"
				sectionKey="account__applicable-hubs"
			>
				<div className="mb-10">
					<strong>Select all hubs that you would like this ad to appear in</strong>
				</div>
				{adEditorState.modifiedAd.applicableHubs.length === 0 && <em>No hubs have been selected</em>}
				{adEditorState.modifiedAd.applicableHubs.map((hubId) => {
					return createHubListItem(hubId)
				})}
				<LoKationButton
					variant="outlined"
					label="Add Hub"
					size="sm"
					className="mt-10 col-xs-12"
					onClick={() => {
						setShowHubModal(true)
					}}
				/>
			</SideSheet__Section>
			{adEditorState.modifiedAd.placement === 'HUB_HEADER' && <Ad__SideSheet__Placement__HeaderConfig />}
			{adEditorState.modifiedAd.placement === 'SPONSORED_RESOURCE' && resourceStyleAdImage && (
				<Ad__SideSheet__Placement__ResourceConfig adImage={resourceStyleAdImage} />
			)}

			{showHubModal && (
				<GenericItemSelectorModal<Hub>
					title={`Select Locations`}
					description={`Select which locations you would like your ad to target`}
					zIndex={1000}
					items={props.hubs}
					selectedItems={selectedHubs}
					itemLabelKey={'title'}
					itemIdKey={'hubId'}
					onSelect={(selectedItems) => {
						adEditorDispatch({
							type: 'update-property',
							payload: { applicableHubs: selectedItems.map((hub) => hub.hubId) },
						})
					}}
					onClose={() => {
						setShowHubModal(false)
					}}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		hubs: state.hubs,
	}
}

export const Ad__SideSheet__Tab__Design = connect(mapStateToProps)(Ad__SideSheet__Tab__DesignPrototype)
