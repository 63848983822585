import { LoKationButton } from '@components/button/button'
import { NewsFeedStory } from '@components/news-feed__story/news-feed__story'
import { Paper } from '@components/paper/paper'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { useLoaderData } from 'react-router-dom'

import { NewsFeed } from '../../services/news-feed/news-feed.types'

export function SingleNewsFeedStoryRoute() {
	const newsFeedStory = useLoaderData() as NewsFeed.Story | undefined

	if (!newsFeedStory) {
		return <div>ERROR</div>
	}

	return (
		<RouteWrapperV2>
			<RouteBodyV2 className="mb-20 flex flex-column">
				<LoKationButton
					to="/news-feed"
					className="mb-10"
					label="All News"
					icon={'arrow-left'}
					iconPosition={'left'}
					variant={'text'}
					size={'lg'}
				/>
				<Paper bgColor="primary" padding={['all']} className="col-xs-12">
					<NewsFeedStory story={newsFeedStory} />
				</Paper>
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}
