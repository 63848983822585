import './multi-column-table.scss'

import _ from 'lodash'
import { useMemo, useRef } from 'react'
import { useComponentSize } from 'src/services/hooks/use-component-size'

import { MultiColumnTableBody } from './components/multi-column-table-body'
import { MultiColumnTableHeader } from './components/multi-column-table-header'
import { MultiColumnTablePlaceholderBody } from './components/multi-column-table-placeholder'
import { MultiColumnTableService } from './multi-column-table.service'
import { MultiColumnTableTypes } from './multi-column-table.types'
import { useMultiColumnTable } from './state/multi-column-table__state'

/** Displays a collection of items in a list, allowing users to select from that list. Additional options allow users to sort and search the list
 * ##### Note: T represents the interface of the items displayed in this table
 */
export function MultiColumnTableInner<T extends Object>(props: MultiColumnTableTypes.Component<T>) {
	const newItemTableState = useMultiColumnTable()

	const tableWrapperRef = useRef<HTMLDivElement>(null)
	const canvasSize = useComponentSize(tableWrapperRef)
	const showAllColumns = getShowAllColumns()

	const sortedFilteredItems = useMemo(() => {
		if (props.items) {
			let mutatedItems = _.cloneDeep(props.items)

			/** Sort items if table is not sortable */
			mutatedItems = MultiColumnTableService.sortItems<T>({
				tableProps: props,
				items: props.items,
				sortMethod: newItemTableState.sortMethod as MultiColumnTableTypes.SortMethod<T>,
				columnSetup: props.columns,
			})

			/** Filter items if a search string is present */
			if (props.searchString) {
				mutatedItems = MultiColumnTableService.filterItemsBySearchString<T>({
					items: mutatedItems,
					searchString: props.searchString,
				})
			}
			return mutatedItems
		}

		return []
	}, [props.items, newItemTableState.sortMethod, props.searchString])

	/** ================================ */
	/** Methods */

	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`fs-unmask flex flex-column`)

		if (props.className) {
			classString = `${props.className} `
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	function isHeaderVisible(): boolean {
		if (props.showHeader === undefined) {
			return true
		}
		return props.showHeader
	}

	function getShowAllColumns(): boolean {
		let widthRequiredToShowAllColumns = 300
		props.columns.forEach((column) => {
			const isColumnVisible = MultiColumnTableService.isColumnVisible(
				column,
				newItemTableState.columnConfigOverrides,
			)
			if (!isColumnVisible) {
				return
			}

			if (typeof column.width === 'number') {
				widthRequiredToShowAllColumns += column.width
			}
		})
		if (canvasSize.width < widthRequiredToShowAllColumns) {
			return false
		}
		return true
	}

	/** ================================ */
	/** Render Component */

	return (
		<div className={getClass()} style={props.style} ref={tableWrapperRef}>
			{!props.items && <MultiColumnTablePlaceholderBody />}
			{props.items && (
				<>
					{isHeaderVisible() && (
						<MultiColumnTableHeader
							columns={props.columns}
							tableId={props.tableId}
							showAllColumns={showAllColumns}
							isConfigurable={props.isConfigurable}
						/>
					)}
					{sortedFilteredItems.length > 0 && (
						<MultiColumnTableBody
							columns={props.columns}
							disabled={props.disabled}
							disableMultiselectRule={props.disableMultiselectRule}
							dropBehavior={props.dropBehavior}
							idKey={props.idKey}
							items={sortedFilteredItems}
							onDoubleClick={props.onDoubleClick}
							onLongPress={props.onLongPress}
							onSelect={props.onSelect}
							selectBehavior={props.selectBehavior}
							selectedItems={props.selectedItems}
							showAllColumns={showAllColumns}
							sortBehavior={props.sortBehavior}
						/>
					)}
					{sortedFilteredItems.length === 0 && props.emptyPlaceholder && (
						<div className="flex flex-column flex-fillSpace flex-alignItems-center flex-justifyContent-center">
							{props.emptyPlaceholder}
						</div>
					)}
				</>
			)}
		</div>
	)
}
