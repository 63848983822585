/* eslint-disable react/jsx-pascal-case */
import { LoKationButton } from '@components/button/button'
import { Chip } from '@components/chip/chip'
import { LabelValueDisplay } from '@components/label-value-display/label-value-display'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { useOutletContext } from 'react-router-dom'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import { Transaction__ContactCard } from './contact-card'

export function TransactionMgmt__Transaction__Overview__Route() {
	const data = useOutletContext() as TransactionMgmtTypes.TransactionTypes.Transaction

	return (
		<div className="col-xs-12" style={{ maxWidth: '1200px' }}>
			<Typography type="h1" margins={['bottom']}>
				Overview
			</Typography>

			<div className="flex flex-justifyContent-spaceBetween flex-alignItems-center">
				<Typography type="h5" color="secondary" margins={['bottom']}>
					General
				</Typography>
				<LoKationButton
					variant="text"
					size="sm"
					label="Edit"
					onClick={() => {
						/** Empty */
					}}
				/>
			</div>

			<Paper
				elevate={true}
				bgColor="white"
				padding={['all']}
				marginSize="section"
				margins={['bottom']}
				className="col-xs-12 flex flex-wrap "
			>
				<div className="col-xs-12 col-sm-6">
					<LabelValueDisplay
						label="Status"
						value={TransactionManagementService.getLabelForTransactionStatus(data.status)}
					/>
					<LabelValueDisplay label="Location" value={data.location} />
					<LabelValueDisplay
						label="Type"
						value={TransactionManagementService.getLabelForTransactionType(data.type)}
					/>
					<LabelValueDisplay
						label="Side"
						value={TransactionManagementService.getLabelForTransactionSide(data.side)}
					/>
				</div>

				<div className="col-xs-12 col-sm-6">
					<LabelValueDisplay label="MLS Number" value={data.mlsNumber} />
					<LabelValueDisplay label="Accepted Date" value={data.acceptedDate} />
					<LabelValueDisplay label="Close Date" value={data.closeDate} />
				</div>
			</Paper>

			<div className="flex flex-justifyContent-spaceBetween flex-alignItems-center">
				<Typography type="h5" color="secondary" margins={['bottom']}>
					Transaction Owners
				</Typography>
				<LoKationButton
					variant="text"
					size="sm"
					label="Edit"
					onClick={() => {
						/** Empty */
					}}
				/>
			</div>
			<Paper
				elevate={true}
				bgColor="white"
				padding={['all']}
				marginSize="section"
				margins={['bottom']}
				className="col-xs-12 flex flex-wrap"
			>
				{data.transactionOwners.length === 0 && (
					<Typography type="normal" variant={['italic']} color="disabled">
						This transaction has no owners
					</Typography>
				)}
				{data.transactionOwners.map((user) => {
					return <Chip label={`${user.firstName} ${user.lastName}`} color="var(--colorAdjust50)" />
				})}
			</Paper>

			<div className="flex flex-justifyContent-spaceBetween flex-alignItems-center">
				<Typography type="h5" color="secondary" margins={['bottom']}>
					Transaction Watchers
				</Typography>
				<LoKationButton
					variant="text"
					size="sm"
					label="Edit"
					onClick={() => {
						/** Empty */
					}}
				/>
			</div>
			<Paper
				elevate={true}
				bgColor="white"
				padding={['all']}
				marginSize="section"
				margins={['bottom']}
				className="col-xs-12 flex flex-wrap"
			>
				{data.transactionWatchers.length === 0 && (
					<Typography type="normal" variant={['italic']} color="disabled">
						This transaction has no watchers
					</Typography>
				)}
				{data.transactionWatchers.map((user) => {
					return <Chip label={`${user.firstName} ${user.lastName}`} color="var(--colorAdjust50)" />
				})}
			</Paper>

			<div className="flex flex-justifyContent-spaceBetween flex-alignItems-center">
				<Typography type="h5" color="secondary" margins={['bottom']}>
					Seller & Buyer
				</Typography>
				<LoKationButton
					variant="text"
					size="sm"
					label="Edit"
					onClick={() => {
						/** Empty */
					}}
				/>
			</div>
			<Paper
				elevate={true}
				bgColor="white"
				padding={['all']}
				marginSize="section"
				margins={['bottom']}
				className="col-xs-12 "
			>
				<LabelValueDisplay label="Seller" value={data.sellerContact.fullName} />
				<LabelValueDisplay label="Buyer" value={data.buyerContact.fullName} />
			</Paper>

			<div className="flex flex-justifyContent-spaceBetween flex-alignItems-center">
				<Typography type="h5" color="secondary" margins={['bottom']}>
					Contacts
				</Typography>
				<LoKationButton
					variant="text"
					size="sm"
					label="Edit"
					onClick={() => {
						/** Empty */
					}}
				/>
			</div>
			{data.contacts.map((contact) => {
				return <Transaction__ContactCard contact={contact} />
			})}
		</div>
	)
}
