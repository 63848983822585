import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type ComponentProps = {
	isExternalUrl: boolean
	isExternalUrlYouTubeVideo: boolean
	isSponsored: boolean
	isPublished: boolean
}

export function ResourceCard__FloatingTags(props: ComponentProps) {
	return (
		<div className="resource-card__floating-tag-wrapper">
			{props.isExternalUrl && !props.isExternalUrlYouTubeVideo && !props.isSponsored && (
				<FontAwesomeIcon icon={['far', 'arrow-up-right']} />
			)}
			{props.isExternalUrl && !props.isExternalUrlYouTubeVideo && props.isSponsored && (
				<div style={{ fontSize: '8pt' }}>SPONSORED</div>
			)}
			{!props.isPublished && <div style={{ fontSize: '8pt' }}>UNPUBLISHED</div>}

			{props.isExternalUrl && props.isExternalUrlYouTubeVideo && (
				<div>
					<FontAwesomeIcon icon={['far', 'play']} />
				</div>
			)}
		</div>
	)
}
