import _ from 'lodash'

import { MultiColumnTableTypes } from './multi-column-table.types'

export namespace MultiColumnTableService {
	export function getLocalStorageKey(tableId: string, type: 'column-config' | 'sort-method'): string {
		return `table__${tableId}__${type}`
	}

	/** Determines what sort methods should be used when this table is first rendered */
	export function getDefaultSortMethod<T extends Object>(props: {
		defaultSortMethod: MultiColumnTableTypes.SortMethod<T>
		tableId: string
	}): MultiColumnTableTypes.SortMethod<T> {
		/** If the sort method doesn't support user  configuration, return the sort method defined on the table */
		if (
			props.defaultSortMethod.sortMethod === 'display-index' ||
			props.defaultSortMethod.sortMethod === 'sort-function'
		) {
			return props.defaultSortMethod
		}

		/** Attempt to load the cached sort method */
		const cachedSortMethod = localStorage.getItem(getLocalStorageKey(props.tableId, 'sort-method'))
		if (cachedSortMethod) {
			const parsedSortMethod = JSON.parse(cachedSortMethod) as MultiColumnTableTypes.ColumnValueSortMethod
			return parsedSortMethod
		}

		return props.defaultSortMethod
	}

	export function getDefaultColumnsConfig(props: { tableId: string }): MultiColumnTableTypes.ColumnConfigOverrides {
		const cachedVisibleColumns = localStorage.getItem(getLocalStorageKey(props.tableId, 'column-config'))
		if (cachedVisibleColumns) {
			const parsedVisibleColumns = JSON.parse(cachedVisibleColumns) as MultiColumnTableTypes.ColumnConfigOverrides
			return parsedVisibleColumns
		}

		return {}
	}

	/** Sorts all items in the item table based off the item tables sort method */
	export function sortItems<T extends Object>(props: {
		tableProps: MultiColumnTableTypes.Component<T>
		items: T[]
		sortMethod: MultiColumnTableTypes.SortMethod<T>
		columnSetup: MultiColumnTableTypes.Column<T>[]
	}): T[] {
		const sortDirectionToUse: MultiColumnTableTypes.SortDirection =
			props.sortMethod.sortMethod === 'column-value' && props.sortMethod.direction
				? props.sortMethod.direction
				: 'desc'

		const sortedColumnProps = props.columnSetup.find(
			(columnProps) =>
				props.sortMethod.sortMethod === 'column-value' && columnProps.columnId === props.sortMethod.columnId,
		)

		/** If the table is not sortable, and a sort function is present, use this to sort the items */
		if (props.tableProps.sortBehavior.sortMethod === 'sort-function') {
			return props.tableProps.sortBehavior.sortFunction(props.items)
		}

		return _.cloneDeep(props.items).sort((a, b) => {
			/** If table is sortable, sort items by their display index */
			if (props.sortMethod.sortMethod === 'display-index') {
				const aDisplayIndex = a[props.sortMethod.displayIndexKey]
				const bDisplayIndex = b[props.sortMethod.displayIndexKey]

				return aDisplayIndex > bDisplayIndex ? 1 : -1
			}

			/** If table is not sortable, sort items by the selected column and sort direction */
			if (props.sortMethod.sortMethod === 'column-value' && sortedColumnProps) {
				let aValue: unknown = ''
				let bValue: unknown = ''

				/** If the column uses a function to resolve the value of this item, call the function to resolve values */
				if (typeof sortedColumnProps.value === 'function') {
					aValue = sortedColumnProps.value(a)
					bValue = sortedColumnProps.value(b)
				} else {
					aValue = a[sortedColumnProps.value]
					bValue = b[sortedColumnProps.value]
				}

				if (
					(typeof aValue === 'string' || typeof aValue === 'number') &&
					(typeof bValue === 'string' || typeof bValue === 'number')
				) {
					if (sortDirectionToUse === 'desc') {
						return aValue.toString().toLocaleLowerCase() > bValue.toString().toLocaleLowerCase() ? 1 : -1
					}
					return aValue.toString().toLocaleLowerCase() < bValue.toString().toLocaleLowerCase() ? 1 : -1
				}
			}

			return 1
		})
	}

	export function filterItemsBySearchString<T extends Object>(props: { items: T[]; searchString: string }): T[] {
		return props.items.filter((item) => {
			let isStringInColumn = false

			Object.keys(item).forEach((key) => {
				// @ts-ignore
				const keyValue = item[key]
				if (typeof keyValue !== 'number' && typeof keyValue !== 'string') {
					return
				}
				if (String(keyValue).toLocaleLowerCase().includes(props.searchString.toLocaleLowerCase())) {
					isStringInColumn = true
				}
			})

			return isStringInColumn
		})
	}

	export function getColumnStyle<T extends Object>(column: MultiColumnTableTypes.Column<T>): React.CSSProperties {
		const style: React.CSSProperties = {}
		if (column.width === 'fill') {
			style.flexGrow = '1'
		} else {
			style.width = `${column.width}px`
			style.flexShrink = '0'
		}

		if ((!column.align && column.displayIndex > 0) || column.align === 'right') {
			style.textAlign = 'right'
		} else {
			style.textAlign = 'left'
		}

		return style
	}

	export function getSelectedItemIds<T extends Object>(props: {
		selectedItems: T[]
		idKey: string
	}): (number | string)[] {
		const selectedIds: (number | string)[] = []
		props.selectedItems.forEach((item) => {
			// @ts-ignore
			const itemId = item[props.idKey]
			if (typeof itemId === 'number' || typeof itemId === 'string') {
				selectedIds.push(itemId)
			}
		})
		return selectedIds
	}

	export function isColumnVisible<T extends Object>(
		column: MultiColumnTableTypes.Column<T>,
		columnConfigOverrides: MultiColumnTableTypes.ColumnConfigOverrides,
	): boolean {
		const columnDisplayIndex = getColumnDisplayIndex(column, columnConfigOverrides)

		// First column will always be visible
		if (columnDisplayIndex === 0) {
			return true
		}

		const columnOverrides = columnConfigOverrides[column.columnId]
		return columnOverrides ? columnOverrides.visible : column.visible
	}

	export function getColumnDisplayIndex<T extends Object>(
		column: MultiColumnTableTypes.Column<T>,
		columnConfigOverrides: MultiColumnTableTypes.ColumnConfigOverrides,
	): number {
		const columnOverrides = columnConfigOverrides[column.columnId]
		return columnOverrides ? columnOverrides.displayIndex : column.displayIndex
	}

	export function sortColumnsByDisplayIndex<T extends Object>(
		columns: MultiColumnTableTypes.Column<T>[],
		columnConfigOverrides: MultiColumnTableTypes.ColumnConfigOverrides,
	): MultiColumnTableTypes.Column<T>[] {
		const sortedColumns = columns.sort((a, b) => {
			const aDisplayIndex = MultiColumnTableService.getColumnDisplayIndex(a, columnConfigOverrides)
			const bDisplayIndex = MultiColumnTableService.getColumnDisplayIndex(b, columnConfigOverrides)
			return aDisplayIndex > bDisplayIndex ? 1 : -1
		})
		sortedColumns.forEach((column, index) => {
			column.displayIndex = index
		})
		return sortedColumns
	}
}
