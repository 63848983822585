import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface MessagesAdminRouteNoSelectedDraftPlaceholderProps {
	messageText: 'sentMessages' | 'messageDrafts'
}

export function MessagesAdminRouteNoSelectedDraftPlaceholder(props: MessagesAdminRouteNoSelectedDraftPlaceholderProps) {
	const contentView = (type: string) => {
		switch (type) {
			case 'messageDrafts':
				return 'draft'
			case 'sentMessages':
				return 'sent message'
			default:
				return 'draft'
		}
	}

	const textForView = contentView(props.messageText)
	return (
		<div className="flex flex-column flex-alignItems-center flex-justifyContent-center flex-fillSpace height__100pct">
			<FontAwesomeIcon icon={['far', 'message']} size="4x" style={{ opacity: '0.75' }} className="mb-20" />
			<Typography type="h6">Select a {textForView}</Typography>
		</div>
	)
}
