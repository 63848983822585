import './text.scss'

import { SharedElementService } from '@components/shared/shared-element.service'

import { TypographyHeaderTypes } from './text.types'

export function Typography(props: TypographyHeaderTypes.Component) {
	function getClass(): string {
		let classes: string[] = []
		let classString = ''

		classes.push(`fs-unmask typogaphy__header`)

		switch (props.color) {
			case 'header':
				classes.push(`color__page-title`)
				break
			case 'disabled':
				classes.push(`text__color__disabled`)
				break
			case 'secondary':
				classes.push(`text__color__secondary`)
				break
			case 'warning':
				classes.push(`text__color__warning`)
				break
			case 'primary':
			default:
				classes.push(`text__color__primary`)
				break
		}

		if (props.clampWidth === undefined || props.clampWidth === false) {
			classes.push(`clamp-width`)
		}

		if (props.margins) {
			classes = [
				...classes,
				...SharedElementService.getMarginClass({ margins: props.margins, marginSize: props.marginSize }),
			]
		}

		props.variant?.forEach((variant) => {
			switch (variant) {
				case 'italic':
					classes.push(`text__variant__italic`)
					break
			}
		})

		if (props.className) {
			classString = `${props.className} `
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	switch (props.type) {
		case 'h1':
			return (
				<h1 style={props.style} className={getClass()} onClick={props.onClick}>
					{props.children}
				</h1>
			)
		case 'h2':
			return (
				<h2 style={props.style} className={getClass()} onClick={props.onClick}>
					{props.children}
				</h2>
			)
		case 'h3':
			return (
				<h3 style={props.style} className={getClass()} onClick={props.onClick}>
					{props.children}
				</h3>
			)

		case 'h4':
			return (
				<h4 style={props.style} className={getClass()} onClick={props.onClick}>
					{props.children}
				</h4>
			)
		case 'h5':
			return (
				<h5 style={props.style} className={getClass()} onClick={props.onClick}>
					{props.children}
				</h5>
			)
		case 'h6':
			return (
				<h6 style={props.style} className={getClass()} onClick={props.onClick}>
					{props.children}
				</h6>
			)
		case 'xsmall':
			return (
				<div style={props.style} className={`font-size__xsmall ${getClass()}`} onClick={props.onClick}>
					{props.children}
				</div>
			)
		case 'normal':
			return (
				<div style={props.style} className={getClass()} onClick={props.onClick}>
					{props.children}
				</div>
			)
		case 'strong':
			return (
				<div style={props.style} className={getClass()} onClick={props.onClick}>
					<strong>{props.children}</strong>
				</div>
			)
		case 'semibold':
			return (
				<div style={props.style} className={getClass()} onClick={props.onClick}>
					<span style={{ fontWeight: '500' }}>{props.children}</span>
				</div>
			)
	}
}
