import { LoKationButton } from '@components/button/button'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownOptionProps } from '@components/dropdown/dropdown.types'
import { Paper } from '@components/paper/paper'
import { PhoneInput } from '@components/text-input/phone-input'
import { TextInput } from '@components/text-input/text-input'
import { useState } from 'react'
import { AdAccountAPI } from 'src/services/advertising/ad-account.api'
import { AdvertisingTypes } from 'src/services/advertising/advertising.types'
import { usStatesList } from 'src/services/state-list'
import { ToastService } from 'src/services/toast/toast.service'

import { useAdvertisingAccount, useAdvertisingAccountDispatch } from '../../state/ad-account__state'

export function AdvertisingAccount__Route__General() {
	const advertisingAccountState = useAdvertisingAccount()
	const advertisingAccountDispatch = useAdvertisingAccountDispatch()

	/** State is persisted locally on this file so that the user can make changes and then click the "save changes" button to persist them */
	const [modifiedState, setModifiedState] = useState(advertisingAccountState.modifiedState)

	function handleSaveAccount(): Promise<void> {
		return new Promise((resolve) => {
			AdAccountAPI.updateAccount(
				advertisingAccountState.modifiedState.accountId,
				advertisingAccountState.modifiedState,
			).then(() => {
				ToastService().create({
					type: 'SUCCESS',
					body: `Your changes have been saved`,
				})
				resolve()
			})
		})
	}

	function getMailingStateOptions(): DropdownOptionProps<string>[] {
		return usStatesList.map((state) => {
			return {
				value: state.abbreviation,
				label: state.name,
			}
		})
	}

	function updateProperties(props: Partial<AdvertisingTypes.Account>): void {
		const updatedState: AdvertisingTypes.Account = { ...modifiedState, ...props }
		setModifiedState(updatedState)
	}

	return (
		<>
			<h2 className="mb-10">General</h2>
			<Paper
				bgColor="primary"
				padding={['all']}
				marginSize="section"
				margins={['bottom']}
				className="col-xs-12"
				style={{ maxWidth: `800px` }}
			>
				<h5 className="mb-20">Organization Information</h5>
				<TextInput
					label="Company Name"
					value={modifiedState.organizationName}
					onChange={(updatedValue) => {
						updateProperties({ organizationName: updatedValue })
					}}
					dataType="text"
					width={300}
					className="mb-20"
				/>

				<TextInput
					label="Address"
					value={modifiedState.address1}
					onChange={(updatedValue) => {
						updateProperties({ address1: updatedValue })
					}}
					className="mb-10"
					dataType="text"
					width={300}
				/>

				<TextInput
					value={modifiedState.address2}
					onChange={(updatedValue) => {
						updateProperties({ address2: updatedValue })
					}}
					className="mb-10"
					dataType="text"
					width={300}
				/>

				<TextInput
					className="mb-10"
					value={modifiedState.city}
					onChange={(updatedValue) => {
						updateProperties({ city: updatedValue })
					}}
					dataType="text"
					width={300}
				/>
				<Dropdown<string>
					value={modifiedState.mailingState ? [modifiedState.mailingState] : []}
					width={300}
					className="mb-10"
					searchable={true}
					options={getMailingStateOptions()}
					onSelect={(selectedValues) => {
						if (selectedValues.length > 0) {
							updateProperties({ mailingState: selectedValues[0] })
						}
					}}
				/>
				<TextInput
					className="mb-40"
					value={modifiedState.zip}
					onChange={(updatedValue) => {
						updateProperties({ zip: updatedValue })
					}}
					placeholder="ZIP"
					dataType="text"
					width={300}
				/>

				<h5 className="mb-20">Contact Information</h5>
				<TextInput
					label="Full Name"
					value={modifiedState.contactFullname}
					onChange={(updatedValue) => {
						updateProperties({ contactFullname: updatedValue })
					}}
					dataType="text"
					width={300}
					className="mb-20"
				/>
				<PhoneInput
					label="Phone Number"
					value={parseFloat(modifiedState.contactPhone)}
					onChange={(updatedValue) => {
						updateProperties({ contactPhone: String(updatedValue) })
					}}
					dataType="number"
					width={300}
					className="mb-20"
				/>
				<TextInput
					label="Email Address"
					value={modifiedState.contactEmail}
					onChange={(updatedValue) => {
						updateProperties({ contactEmail: updatedValue })
					}}
					dataType="text"
					width={300}
					className="mb-40"
				/>

				<h5 className="mb-20">Other</h5>
				<TextInput
					label="Stripe Account ID"
					value={modifiedState.stripeAccountId}
					onChange={(updatedValue) => {
						updateProperties({ stripeAccountId: updatedValue })
					}}
					dataType="text"
					width={300}
					className="mb-20"
				/>
			</Paper>
			<LoKationButton
				label="Save Changes"
				variant="contained"
				className="flex-noShrink"
				size="lg"
				onClick={() => {
					return new Promise((resolve) => {
						handleSaveAccount().then(() => {
							advertisingAccountDispatch({ type: 'update-account', payload: modifiedState })
							resolve()
						})
					})
				}}
			/>
		</>
	)
}
