import React from 'react'
import { ToastService } from 'src/services/toast/toast.service'

import { RegistrationContractsService } from '../../routes/registration/routes/contracts/contracts.service'
import { DateService } from '../../services/date.service'
import { LicensedStateEnums } from '../../services/licensed-states/licenses-states.types'
import { PDFGenerationService } from '../../services/pdf-generation/pdf-generation.service'
import { AgentLicense } from '../../services/user/user.types'
import { LoKationButton } from '../button/button'
import { Checkbox } from '../checkbox/checkbox'
import { Paper } from '../paper/paper'

interface AgentContractCardProps {
	license: AgentLicense.Entry
	signature: string
	firstName: string
	lastName: string
	onChange: (newState: boolean) => void
	hasUserClickedNavButton: boolean
	hasAgreed: boolean
}

export function AgentContractCard(props: AgentContractCardProps) {
	const licenseStateProps = props.license.licensedState

	/** ============================= */
	/** Methods */

	function generatePDF(abbreviation: LicensedStateEnums, license: AgentLicense.PlanType): void {
		fetch(props.signature).then((res) => {
			res.blob().then((signatureBlob: Blob | null) => {
				if (!signatureBlob) {
					ToastService().create({ type: 'ERROR', body: `Agreement could not be generated` })
					return
				}

				if (!props.signature) {
					signatureBlob = null
				}

				const userName = `${props.firstName} ${props.lastName}`
				const todaysDate = DateService.getCurrentDateString(0)

				const template = RegistrationContractsService().getContractTemplate(
					abbreviation,
					license,
					userName,
					todaysDate,
					signatureBlob,
				)

				if (!template) {
					ToastService().create({ type: 'ERROR', body: `Could not generate PDF for agreement` })
					return
				}

				PDFGenerationService()
					.modifyPDF(template.basePDF, template.additions)
					.then((uintArray) => {
						const filename = `${DateService.getCurrentDateString(0)}__${abbreviation.toLocaleLowerCase()}__${license.toLocaleLowerCase()}.pdf`
						PDFGenerationService().downloadBlob(uintArray, filename, `application/octet-stream`)
					})
					.catch((err) => {
						ToastService().create({ type: 'ERROR', body: `Agreement could not be generated` })
					})
			})
		})
	}

	/** ============================= */
	/** Render Component */

	if (!props.license.licensedState || !licenseStateProps) {
		return <React.Fragment key={props.license.licenseInformationId}>ERROR</React.Fragment>
	}

	return (
		<Paper
			bgColor="white"
			padding={['all']}
			margins={['bottom']}
			className="flex flex-alignItems-center flex-justifyContent-spaceBetween col-xs-12 flex-column-xs-down"
			key={props.license.licenseInformationId}
		>
			<div className="mb-20-xs-down">
				<strong>{licenseStateProps.stateName} Agreement</strong>
				<Checkbox
					checked={props.hasAgreed}
					className="mt-5"
					onChange={(newState) => {
						props.onChange(newState)
					}}
					validation={{
						isValid: (value, isUserInteracted) => {
							const isResultValid = value === true
							if (isUserInteracted || props.hasUserClickedNavButton) {
								return {
									isValid: isResultValid,
									showValidationFlag: true,
								}
							} else {
								return {
									isValid: isResultValid,
									showValidationFlag: false,
								}
							}
						},
						message: `You must agree to the terms and conditions of this agreement`,
					}}
					forceRevalidation={props.hasUserClickedNavButton}
				>
					I have read and agree with the terms and conditions of this agreement
				</Checkbox>
			</div>
			<div>
				<LoKationButton
					variant="outlined"
					size="sm"
					label="View Agreement"
					disabled={!props.license.plan}
					onClick={() => {
						if (props.license.plan) {
							generatePDF(licenseStateProps.abbreviation, props.license.plan)
						}
					}}
				/>
			</div>
		</Paper>
	)
}
