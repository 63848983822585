import _ from 'lodash'
import React, { createContext, useContext, useEffect, useReducer } from 'react'

import { MultiColumnTableService } from '../multi-column-table.service'
import { MultiColumnTableTypes } from '../multi-column-table.types'
import { MultiColumnTableState } from './multi-column-table__state.types'

function getInitialState<T extends Object>(
	tableProps: MultiColumnTableTypes.Component<T>,
): MultiColumnTableState.LocalState<T> {
	const defaultSortMethod = MultiColumnTableService.getDefaultSortMethod<T>({
		defaultSortMethod: tableProps.sortBehavior,
		tableId: tableProps.tableId,
	})

	const defaultColumnsConfig = MultiColumnTableService.getDefaultColumnsConfig({
		tableId: tableProps.tableId,
	})

	return {
		sortMethod: defaultSortMethod,
		columnConfigOverrides: tableProps.isConfigurable ? defaultColumnsConfig : {},
		previousExternalSortMethod: tableProps.sortBehavior,
		tableId: tableProps.tableId,
	}
}

const MultiColumnTableContext = createContext(
	getInitialState({
		columns: [],
		idKey: 'toLocaleString',
		items: null,
		onSelect(items: Object[], pointerType: MultiColumnTableTypes.PointerType): void {
			throw new Error('Function not implemented.')
		},
		selectBehavior: 'single',
		selectedItems: [],
		sortBehavior: { sortMethod: 'column-value', columnId: 'constructor' },
		tableId: '',
		isConfigurable: true,
	}),
)
const MultiColumnTableDispatchContext = createContext({} as MultiColumnTableState.DispatchParams<any>)

function newItemTableReducer<T extends Object>(
	state: MultiColumnTableState.LocalState<T>,
	action: MultiColumnTableState.Action<T>,
) {
	switch (action.type) {
		case 'set-sort-method': {
			const updatedState = _.cloneDeep(state)
			updatedState.sortMethod = action.payload
			localStorage.setItem(
				MultiColumnTableService.getLocalStorageKey(state.tableId, 'sort-method'),
				JSON.stringify(action.payload),
			)
			return updatedState
		}
		case 'set-previous-sort-method': {
			const updatedState = _.cloneDeep(state)
			updatedState.previousExternalSortMethod = action.payload
			return updatedState
		}
		case 'set-columns-config': {
			const updatedState = _.cloneDeep(state)
			updatedState.columnConfigOverrides = action.payload
			localStorage.setItem(
				MultiColumnTableService.getLocalStorageKey(state.tableId, 'column-config'),
				JSON.stringify(updatedState.columnConfigOverrides),
			)
			return updatedState
		}
		case 'set-column-config': {
			const updatedState = _.cloneDeep(state)
			updatedState.columnConfigOverrides[action.payload.columnId] = action.payload.config
			localStorage.setItem(
				MultiColumnTableService.getLocalStorageKey(state.tableId, 'column-config'),
				JSON.stringify(updatedState.columnConfigOverrides),
			)
			return updatedState
		}
	}
}

export function MultiColumnTableProvider<T extends Object>(props: {
	children: React.ReactNode
	tableProps: MultiColumnTableTypes.Component<T>
}) {
	const [state, dispatch] = useReducer(newItemTableReducer<T>, getInitialState<T>(props.tableProps))

	/** Force update of the sort method when it is changed externally */
	useEffect(() => {
		if (_.isEqual(props.tableProps.sortBehavior, state.previousExternalSortMethod)) {
			return
		}

		dispatch({ type: 'set-sort-method', payload: props.tableProps.sortBehavior })
		dispatch({ type: 'set-previous-sort-method', payload: props.tableProps.sortBehavior })
	}, [props.tableProps.sortBehavior])

	return (
		<MultiColumnTableContext.Provider value={state as any}>
			<MultiColumnTableDispatchContext.Provider value={dispatch}>
				{props.children}
			</MultiColumnTableDispatchContext.Provider>
		</MultiColumnTableContext.Provider>
	)
}

export function useMultiColumnTable() {
	return useContext(MultiColumnTableContext)
}

export function useMultiColumnTableDispatch() {
	return useContext(MultiColumnTableDispatchContext)
}
