import { RouteData } from './routes.types'

export namespace RouteService {
	export function getIsRouteVisible(route: RouteData): boolean {
		let isVisible = true
		if (typeof route.visible === 'boolean') {
			isVisible = route.visible
		} else if (typeof route.visible === 'function') {
			isVisible = route.visible()
		}

		return isVisible
	}
}
