import './icon-button.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ForwardedRef, useRef, useState } from 'react'

import { UsePopoverOnEvent } from '../../services/hooks/use-popover-on-event'
import { Badge } from '../badge/badge'
import { Popover } from '../popover/popover'
import { TooltipPopover } from '../tooltip/tooltip-popover'
import { IconButtonProps } from './icon-button.types'

export const IconButton = React.forwardRef(
	(props: IconButtonProps.Component, externalRef: ForwardedRef<HTMLDivElement>) => {
		const [isPopoverOpen, setIsPopoverOpen] = useState(false)
		const popoverProps = UsePopoverOnEvent({
			isPopoverOpen: isPopoverOpen,
			setIsPopoverOpen: setIsPopoverOpen,
			delay: 250,
		})
		const internalRef = useRef<HTMLDivElement>(null)
		const refToUse = externalRef && externalRef instanceof HTMLDivElement ? externalRef : internalRef.current
		const iconButtonStyle = props.style ? props.style : {}

		if (props.color) {
			iconButtonStyle.color = props.color
		}

		function getClass(): string {
			const classes: string[] = []
			let classString = ''

			classes.push(`icon-button`)

			switch (props.variant) {
				case 'inline':
					classes.push(`is-inline`)
					break
				case 'outline':
				default:
					classes.push(`is-outline`)
					break
			}

			if (props.disabled) {
				classes.push(`is-disabled`)
			}

			if (props.className) {
				classes.push(props.className)
			}

			classes.forEach((thisClass) => {
				classString += `${thisClass} `
			})

			return classString
		}

		function getIconStyle(): React.CSSProperties {
			let style: React.CSSProperties = {}
			if (props.iconStyle) {
				style = { ...style, ...props.iconStyle }
			}
			return style
		}

		return (
			<div
				id={props.id}
				className={getClass()}
				ref={externalRef ? externalRef : internalRef}
				style={iconButtonStyle}
				onClick={(evt) => {
					if (props.onClick) {
						props.onClick(evt)
					}
				}}
				onMouseEnter={() => {
					setIsPopoverOpen(true)
				}}
				onMouseLeave={() => {
					setIsPopoverOpen(false)
				}}
			>
				<FontAwesomeIcon icon={['far', props.icon]} className={props.iconClassName} style={getIconStyle()} />
				{props.badge && <Badge {...props.badge} className="icon-button__badge" />}
				{refToUse instanceof HTMLElement && props.tooltip && (
					<Popover
						hideOnMouseOut={true}
						hideOnClickOutside={true}
						isScrimVisible={false}
						{...popoverProps}
						refElement={refToUse}
						setShowPopover={(newState) => {
							setIsPopoverOpen(newState)
						}}
						options={{
							placement: props.tooltipPlacement,
						}}
					>
						<TooltipPopover width={'min-content'}>
							<div className="col-xs-12 text-center">{props.tooltip}</div>
						</TooltipPopover>
					</Popover>
				)}
			</div>
		)
	},
)
