import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { ItemSearchTagFilter } from '@components/item-search__tag-filter/item-search__tag-filter'
import { Paper } from '@components/paper/paper'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { UIState } from '@redux/reducers/ui-reducer'
import { UserState } from '@redux/reducers/user-reducer'
import { RootState, store } from '@redux/store'
import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'

import { FAQAPI } from '../../services/faq/faq.api'
import { FAQ, FAQSearchParams } from '../../services/faq/faq.types'
import { LokationTag } from '../../services/tags/tag.types'
import { useItemAdminHook } from '../administration/use-item-admin-hook'

export function FAQsRoutePrototype(props: { user: UserState; ui: UIState }) {
	const availableTags = store.getState().tags
	const [selectedTags, updateSelectedTags] = useState<LokationTag[]>(availableTags)
	const [isFAQLoadPending, setIsFAQLoadPending] = useState(true)
	const appViewMode = props.ui.viewMode

	const faqAdminHook = useItemAdminHook<FAQ, FAQSearchParams>({
		getItemsFunction: (page, size, sort, searchParams) => {
			return FAQAPI.findFAQs(
				{ page, size, sort },
				{ tags: searchParams.tags, licensedStateIds: searchParams.licensedStateIds },
			)
		},
		searchParams: getFAQSearchParams(),
		itemIdKey: 'faqId',
		itemTitleKey: 'question',
		setIsPending: (state) => {
			setIsFAQLoadPending(state)
		},
	})

	function createFAQCard(faq: FAQ): React.ReactElement {
		return (
			<Paper
				bgColor="primary"
				padding={['all']}
				margins={['right', 'bottom']}
				marginSize="section"
				className="col-xs-12 col-md-5"
				key={faq.faqId}
			>
				<h4 className="mb-20">{faq.question}</h4>
				<div dangerouslySetInnerHTML={{ __html: faq.answer }} />
			</Paper>
		)
	}

	function getFAQSearchParams(): FAQSearchParams {
		const faqSearchParams: FAQSearchParams = {}

		faqSearchParams['tags'] = selectedTags.map((tag) => tag.tagId)
		faqSearchParams['licensedStateIds'] = props.user
			? props.user.licenseInformation.map((license) => license.licensedState.licensedStateId)
			: []

		return faqSearchParams
	}

	return (
		<>
			{appViewMode === 'FULL' && (
				<RouteWrapperV2>
					<RouteSubnavigation title={`Frequently Asked Questions`} isCustom={true}>
						<>
							{availableTags && (
								<ItemSearchTagFilter
									selectedTags={selectedTags}
									onChange={(selectedTags) => {
										updateSelectedTags(selectedTags)
									}}
								/>
							)}
						</>
					</RouteSubnavigation>

					<RouteBodyV2 id="all-faqs__route-body">
						<h1 className="mb-20">Frequently Asked Questions</h1>
						<div className="flex flex-wrap">
							{faqAdminHook.items.length === 0 && isFAQLoadPending && (
								<>
									<GenericContentLoader width={'fill'} height={300} />
								</>
							)}
							{faqAdminHook.items.length > 0 && (
								<InfiniteScroll
									dataLength={faqAdminHook.items.length}
									next={faqAdminHook.loadNextPageOfItems}
									hasMore={faqAdminHook.hasMoreItems}
									scrollableTarget={`all-faqs__route-body`}
									loader={
										<>
											<GenericContentLoader width={'fill'} height={300} />
										</>
									}
								>
									<div className="flex flex-wrap col-xs-12">
										{faqAdminHook.items.map((faq) => {
											return createFAQCard(faq)
										})}
									</div>
								</InfiniteScroll>
							)}
						</div>
					</RouteBodyV2>
				</RouteWrapperV2>
			)}
			{appViewMode === 'MOBILE' && (
				<RouteWrapperV2>
					<RouteBodyV2 id="all-faqs__route-body">
						<h1 className="mb-20">Frequently Asked Questions</h1>
						<div className="flex flex-wrap">
							{faqAdminHook.items.length > 0 && (
								<InfiniteScroll
									dataLength={faqAdminHook.items.length}
									next={faqAdminHook.loadNextPageOfItems}
									hasMore={faqAdminHook.hasMoreItems}
									scrollableTarget={`all-faqs__route-body`}
									loader={
										<>
											<GenericContentLoader width={'fill'} height={300} />
										</>
									}
								>
									<div className="flex flex-wrap col-xs-12">
										{faqAdminHook.items.map((faq) => {
											return createFAQCard(faq)
										})}
									</div>
								</InfiniteScroll>
							)}
						</div>
					</RouteBodyV2>
				</RouteWrapperV2>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		ui: state.ui,
		user: state.user,
	}
}

export const FAQsRoute = connect(mapStateToProps)(FAQsRoutePrototype)
