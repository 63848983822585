import { Paper } from '@components/paper/paper'

export function SupportRouteTexas() {
	return (
		<>
			<h2 className="mb-20">Texas</h2>
			<Paper bgColor="white" padding={['all']}>
				<h5>Department Email Contacts</h5>
				<table className="lokation__table mb-20" style={{ width: '100%', maxWidth: '1000px' }}>
					<thead>
						<tr>
							<td>Department</td>
							<td>Email</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Compliance / Support</td>
							<td>matt@lokationre.com</td>
						</tr>
						<tr>
							<td>Document Submission</td>
							<td>docs+tx@lokationre.com </td>
						</tr>
						<tr>
							<td>Commission / Payments</td>
							<td>payments@lokationre.com</td>
						</tr>
						<tr>
							<td>Billing Inquiries</td>
							<td>billing@lokationre.com</td>
						</tr>
					</tbody>
				</table>
				<h5 className="mb-10">Support</h5>
				<div>Phone: (972) 502-9095</div>
				<div>
					Set an Appointment with an Online Calendar for Agents: https://appt.link/texas-employing-broker
				</div>
				<div>Live Zoom Room for Support questions from 9 AM-5 PM M-F: http://www.lokationsupport.com</div>

				<h5 className="mt-20 mb-10">Texas Office</h5>
				<div>6160 Warren Pkwy, Ste 100</div>
				<div>Frisco, Texas 75034</div>
				<div>(972) 502-9095</div>
				<div>www.lokationre.com | info@lokationre.com</div>
			</Paper>
		</>
	)
}
