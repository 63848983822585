import { useState } from 'react'

/** Hook used for maintaining the state for a context menu */
export const useContextMenu = () => {
	const [x, setX] = useState(0)
	const [y, setY] = useState(0)
	const [isVisible, setIsVisible] = useState(false)
	const [clickTarget, setClickTarget] = useState<EventTarget | null>(null)

	function set(props: { x?: number; y?: number; isVisible?: boolean; clickTarget?: EventTarget | null }): void {
		if (props.x) {
			setX(props.x)
		}
		if (props.y) {
			setY(props.y)
		}
		if (props.isVisible) {
			setIsVisible(props.isVisible)
		}
		if (props.clickTarget !== undefined) {
			setClickTarget(props.clickTarget)
		}
	}

	const contextMenu = { x, y, isVisible, clickTarget, set, setX, setY, setIsVisible, setClickTarget }

	return { contextMenu }
}
