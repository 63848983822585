import './hub-header.scss'

import { Typography } from '@components/text/text'
import React, { useEffect, useState } from 'react'

import { ColorService } from '../../services/color/color.service'
import { LoKationButton } from '../button/button'
import { FloatingActionButton } from '../floating-action-button/floating-action-button'
import { Modal } from '../modal/modal'
import { ModalBody } from '../modal/modal-body'
import { ModalHeader } from '../modal/modal-header'

interface HubHeaderProps {
	title: string
	description: string | JSX.Element
	imageUrl: string
	isEditorPreview?: boolean
	backgroundColor: string
	// Temp - checking body content on whether to show button or not
	bodyContent: string
	isPublished?: boolean
}

export function HubHeader(props: HubHeaderProps) {
	const [showModal, setShowModal] = useState(false)

	function getMaskStyle(): React.CSSProperties {
		const style: React.CSSProperties = {}
		const bgColorRGB = ColorService.mixColors(props.backgroundColor, '#000000', 0.25, 'rgb')
		style.background = `linear-gradient(45deg, ${bgColorRGB} 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.5) 100%)`
		return style
	}

	const [windowWidth, setWindowWidth] = useState(window.innerWidth)

	// Function to update the window width when the window is resized
	const handleResize = () => {
		setWindowWidth(window.innerWidth)
	}

	useEffect(() => {
		// Add event listener for window resize
		window.addEventListener('resize', handleResize)

		// Remove event listener when component unmounts
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return (
		<div
			className={`hub__header ${props.isEditorPreview ? 'is-preview' : ''}`}
			style={{ backgroundImage: `url(${props.imageUrl})`, backgroundColor: props.backgroundColor }}
		>
			<div className="hub__header__mask p-40 p-20-md-down" style={getMaskStyle()}>
				{!props.isPublished && <div className="flex flex-justifyContent-end">UNPUBLISHED</div>}
				<div className="hub__header__stripe" style={{ backgroundColor: props.backgroundColor }}></div>
				<div className="flex flex-column py-40 py-20-md-down">
					<div style={{ maxWidth: '800px', width: '100%' }}>
						<h1 className="mb-10">{props.title}</h1>
						{typeof props.description === 'string' && (
							<>
								{windowWidth >= 999 ? (
									<Typography type="semibold" style={{ color: '#fff' }}>
										{props.description}
									</Typography>
								) : (
									<div>
										<div>
											<span>
												{windowWidth <= 999
													? props.description.length > 120
														? props.description.split(' ').reduce((prev, curr) => {
																return (prev + curr).length < 120
																	? `${prev} ${curr}`
																	: prev
															}, '')
														: props.description
													: props.description}
											</span>
											{props.description.length > 120 && windowWidth <= 999 && (
												<FloatingActionButton
													className="bg-color__adjust-5 read-more-ellipsis"
													icon="ellipsis"
													onClick={(evt) => {
														evt.stopPropagation()
														setShowModal(true)
													}}
												/>
											)}
										</div>
										{showModal && (
											<Modal
												maxHeight={800}
												maxWidth={800}
												fixedHeight={false}
												className="flex flex-column"
												onClose={() => {
													setShowModal(false)
												}}
											>
												<>
													<ModalHeader title={props.title}>
														<></>
													</ModalHeader>
													<ModalBody>{props.description}</ModalBody>
												</>
											</Modal>
										)}
									</div>
								)}
							</>
						)}
						{/* Temporary button for hubs */}
						{props.bodyContent.length > 0 && (
							<LoKationButton
								variant="contained"
								size="sm"
								label="Scroll To Resources"
								className="mt-20"
								onClick={() => {
									const element = document.getElementById('resources')
									if (element) {
										element.scrollIntoView({ behavior: 'smooth' })
									}
								}}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
