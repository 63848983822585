import './list-option.scss'

import { Checkbox } from '@components/checkbox/checkbox'
import { Typography } from '@components/text/text'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import React from 'react'

import { ListOptionTypes } from './list-option.types'

/** UI for a generic option within a list (i.e. Dropdown popover, context menu) */
export function ListOption(props: ListOptionTypes.Component) {
	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`list-option fs-unmask`)

		if (props.disabled) {
			classes.push(`is-disabled`)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	function renderIcon(icon: IconName | FontAwesomeIconProps[]): React.ReactNode {
		if (!Array.isArray(icon)) {
			return <FontAwesomeIcon icon={['far', icon]} />
		} else {
			return (
				<span className="fa-stack" style={{ width: '100%' }}>
					{icon.map((thisIcon, index) => {
						return <FontAwesomeIcon className={`fa-stack-1x`} {...thisIcon} />
					})}
				</span>
			)
		}
	}

	return (
		<div
			className={getClass()}
			onMouseDown={(evt) => {
				evt.preventDefault()
			}}
			onClick={(evt) => {
				evt.preventDefault()
				evt.stopPropagation()
				props.onClick()
			}}
			style={props.style}
			tabIndex={props.disabled ? undefined : 0}
			onKeyDown={(evt) => {
				if (evt.key === 'Enter') {
					evt.preventDefault()
					evt.stopPropagation()
					props.onClick()
				}
			}}
		>
			<div className="flex flex-alignItems-center">
				{props.includeCheckbox && (
					<>
						<Checkbox
							checked={props.isChecked}
							className="mr-10"
							style={{ pointerEvents: 'none' }}
						></Checkbox>
					</>
				)}
				{props.icon && <div className="icon-wrapper">{renderIcon(props.icon)}</div>}
				<div>
					<Typography type="strong" color="primary">
						{props.label}
					</Typography>
					{props.desc && (
						<Typography type="xsmall" color="secondary">
							{props.desc}
						</Typography>
					)}
				</div>
			</div>
			{props.hotkey && <div className="list-option__hot-key">{props.hotkey}</div>}
			{props.expandCaret && (
				<FontAwesomeIcon
					icon={['fal', props.expandCaret === 'expanded' ? 'angle-down' : 'angle-up']}
				></FontAwesomeIcon>
			)}
		</div>
	)
}
