import { LoKationButton } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalHeader } from '@components/modal/modal-header'
import { Paper } from '@components/paper/paper'
import { RootState } from '@redux/store'
import { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ToastService } from 'src/services/toast/toast.service'

import { EmailNotificationAPI } from '../../services/email-notification/email-notification.api'
import { EmailNotificationTypes } from '../../services/email-notification/email-notification.typings'
import { UserAPI } from '../../services/user/user.api'
import { useRegistration } from './state/registration__state'

interface RegistrationEmailExistsModalProps {
	dismissModal: () => void
}

export function RegistrationEmailExistsModalPrototype(props: RegistrationEmailExistsModalProps) {
	const regState = useRegistration()
	const navigate = useNavigate()
	const [submitted, setSubmitted] = useState(false)

	async function sendNotification(notificationType: string) {
		const existUserData: EmailNotificationTypes.EmailExistingUser = {
			firstName: regState.fields.firstName,
			lastName: regState.fields.lastName,
			email: regState.fields.email,
			phone: regState.fields.phone,
			address1: regState.fields.address1,
			address2: regState.fields.address2 || '',
			city: regState.fields.city,
			state: regState.fields.state,
			zip: regState.fields.zip || '',
			notificationType: notificationType as 'CURRENT' | 'RETURNING',
		}

		EmailNotificationAPI.existingEmailNotification(existUserData)
			.then((res) => {
				setSubmitted(true)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	async function sendPasswordResetEmail() {
		const email = regState.fields.email
		UserAPI.sendResetPasswordEmail({
			email,
		}).then((res) => {
			ToastService().create({
				type: 'SUCCESS',
				body: `Email reset instructions will be sent to your inbox soon`,
			})
			navigate(`/`)
		})
	}

	return (
		<Modal onClose={props.dismissModal} maxWidth={800} maxHeight={800} fixedHeight={false}>
			<ModalHeader title={submitted ? 'Your request has been submitted' : 'This email already exists'}>
				<></>
			</ModalHeader>
			<ModalBody>
				<div className="col-12 flex flex-wrap pb-20">
					{!submitted ? (
						<>
							<div className="col-12 col-md-6 pr-10-md mb-20-md-down">
								<Paper bgColor="primary" padding={['all']}>
									<h4 className="mb-15">Are you a Returning Agent?</h4>
									<div className="mb-20 email-exist-modal-text">
										We're happy to have you back! Click the link below to have a member of the
										LoKation Staff reach out to you about reactivating your account.
									</div>
									<LoKationButton
										variant="outlined"
										size="sm"
										primary={false}
										label="Help Me Come Back"
										onClick={() => sendNotification('RETURNING')}
									/>
								</Paper>
							</div>
							<div className="col-12 col-md-6 pl-10-md">
								<Paper bgColor="primary" padding={['all']}>
									<h4 className="mb-15">Are you currently with us?</h4>
									<div className="mb-20 email-exist-modal-text">
										It looks like your email already exists in our system. You may be trying to get
										access back or add an additional state you are licensed in. Click the option
										below to have a member of our Staff reach out to you.
									</div>
									<div>
										<LoKationButton
											variant="outlined"
											size="sm"
											primary={false}
											label="Reset Password"
											className="mr-10 mb-10-sm-down"
											onClick={sendPasswordResetEmail}
										/>
										<LoKationButton
											variant="outlined"
											size="sm"
											primary={false}
											label="Speak With LoKation"
											className="mr-10"
											onClick={() => sendNotification('CURRENT')}
										/>
									</div>
								</Paper>
							</div>
						</>
					) : (
						<>
							<div>
								Your request has been submitted and someone from LoKation will be reaching out shortly.
							</div>
						</>
					)}
				</div>
			</ModalBody>
		</Modal>
	)
}

function mapStateToProps(state: RootState) {
	return {
		user: state.user,
		domain: state.domain,
	}
}

export const RegistrationEmailExistsModal = connect(mapStateToProps)(RegistrationEmailExistsModalPrototype)
