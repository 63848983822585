import { Checkbox } from '@components/checkbox/checkbox'
import { RadioGroup } from '@components/radio-group/radio-group'
import { Typography } from '@components/text/text'
import _ from 'lodash'

import { UserOnboardingTypes } from '../../../../services/user-onboarding/user-onboarding.types'
import { NUORouteImage } from '../../components/route-image/route-image'
import { useNewUserOnboardingDispatch } from '../../state/new-user-onboarding__state'

interface NUOPromptRouteProps {
	field: UserOnboardingTypes.Question
}

export function NUOPromptRoute(props: NUOPromptRouteProps) {
	const NUODispatch = useNewUserOnboardingDispatch()

	function renderField(): React.ReactNode {
		switch (props.field.type) {
			case 'CHECKBOX':
				return renderCheckboxField(props.field)
			case 'RADIO':
				return renderRadioField(props.field)
			default:
				return <></>
		}
	}

	function renderCheckboxField(field: UserOnboardingTypes.Questions.Checkbox): React.ReactNode {
		return (
			<>
				{field.options.map((option) => {
					const isOptionChecked = field.userResponse.includes(option.value)
					return (
						<Checkbox
							checked={isOptionChecked}
							className="mb-10"
							onChange={(updatedState) => {
								let updatedSelectedValues = _.cloneDeep(field.userResponse)
								if (updatedState === true) {
									updatedSelectedValues.push(option.value)
								} else {
									updatedSelectedValues = updatedSelectedValues.filter(
										(thisValue) => thisValue !== option.value,
									)
								}

								NUODispatch([
									{
										type: 'set-question-response',
										payload: {
											questionIndex: field.questionIndex,
											userResponse: updatedSelectedValues,
										},
									},
								])
							}}
						>
							{option.label}
						</Checkbox>
					)
				})}
			</>
		)
	}

	function renderRadioField(field: UserOnboardingTypes.Questions.Radio): React.ReactNode {
		return (
			<RadioGroup
				onChange={(value) => {
					if (!value) {
						return
					}
					NUODispatch([
						{
							type: 'set-question-response',
							payload: {
								questionIndex: field.questionIndex,
								userResponse: [value],
							},
						},
					])
				}}
				options={field.options}
				value={field.userResponse.length > 0 ? field.userResponse[0] : undefined}
				variant={'SMALL'}
				optionClassName="mb-10"
			/>
		)
	}

	return (
		<>
			<NUORouteImage imageUrl={props.field.imageUrl} />
			<div className="flex-fillSpace flex flex-column flex-justifyContent-center">
				<Typography type="h6" margins={['bottom']}>
					{props.field.question}
				</Typography>
				{renderField()}
			</div>
		</>
	)
}
