import './radio-group.scss'

import React, { ReactElement } from 'react'

import { Paper } from '../paper/paper'

type RadioGroupVariant = 'SMALL' | 'LARGE'

export interface RadioGroupProps<T> {
	onChange: (value: T) => void
	options: Array<RadioGroupOption<T>>
	value: T | undefined
	optionClassName?: string
	groupName?: string
	/** If invalid, the border color of this checkbox will be changed to use the themes warning color */
	invalid?: boolean
	variant: RadioGroupVariant
}

export interface RadioGroupOption<T> {
	label: string | React.ReactElement
	value: T | undefined
	disable?: boolean
}

export function RadioGroup<T>(props: RadioGroupProps<T>) {
	function createSmallOption(option: RadioGroupOption<T>): ReactElement {
		const isSelected = props.value === option.value

		return (
			<div
				className={`flex flex-alignItems-center ${props.optionClassName ? props.optionClassName : ''}`}
				key={String(option.label)}
				onClick={() => {
					if (typeof option.value !== 'undefined') {
						props.onChange(option.value)
					}
				}}
			>
				<div className="radio-button">{isSelected && <div className="radio-button__circle"></div>}</div>
				<div className="ml-10">{option.label}</div>
			</div>
		)
	}

	function createLargeOption(option: RadioGroupOption<T>): ReactElement {
		const isSelected = props.value === option.value

		return (
			<Paper
				bgColor="primary"
				className={`radio-button__large ${isSelected ? 'selected' : ''} ${props.optionClassName ? props.optionClassName : ''}`}
				key={String(option.label)}
				onClick={() => {
					if (typeof option.value !== 'undefined') {
						props.onChange(option.value)
					}
				}}
			>
				{option.label}
			</Paper>
		)
	}

	return (
		<>
			{props.options.map((option) => {
				if (props.variant === 'SMALL') {
					return createSmallOption(option)
				}
				if (props.variant === 'LARGE') {
					return createLargeOption(option)
				}
				return <>ERROR</>
			})}
		</>
	)
}
