import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'
import { Outlet, useLoaderData, useNavigate } from 'react-router-dom'
import { transactionMgmtRoutePath, transactionRoutes } from 'src/routes/routes.constant'
import { EndUserProps } from 'src/services/user/user.types'

import { TransactionMgmtTypes } from '../../../services/transaction-mgmt/transaction-mgmt.types'

/** https://reactrouter.com/en/main/hooks/use-outlet-context */
/** Next step, set up context for Transactions */

function TransactionMgmt__Transaction__RoutePrototype(props: { userProfile: EndUserProps | null }) {
	const data = useLoaderData() as TransactionMgmtTypes.TransactionTypes.Transaction | null

	const navigate = useNavigate()

	if (!data) {
		throw new Error(`Cannot load transaction`)
	}

	return (
		<RouteWrapperV2>
			<RouteSubnavigation
				isCustom={false}
				title={data.name}
				backButton={{
					label: 'Transaction Management',
					onClick: () => {
						navigate(`${transactionMgmtRoutePath}`)
					},
				}}
				routes={transactionRoutes().map((route) => {
					return {
						label: route.label,
						isActive: false,
						onClick: () => {
							navigate(`${transactionMgmtRoutePath}/transaction/${data.transactionId}/${route.href}`)
						},
						id: typeof route.href === 'function' ? route.href() : route.href,
					}
				})}
			/>

			<RouteBodyV2 className="flex flex-column flex-alignItems-start">
				<Outlet context={data} />
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
	}
}

export const TransactionMgmt__Transaction__Route = connect(mapStateToProps)(
	TransactionMgmt__Transaction__RoutePrototype,
)
