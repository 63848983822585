import './hub-header-v2.scss'

import { Chip } from '@components/chip/chip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Hub } from 'src/services/hubs/hubs.types'

type ComponentProps = {
	hub: Hub
}

export function HubHeaderV2(props: ComponentProps) {
	return (
		<div className="col-xs-12 py-20 flex flex-alignItems-center">
			<h5 className="mr-20">{props.hub.title}</h5>
			{!props.hub.published && <Chip label="Unpublished" color="var(--colorAdjust60)" className="mr-20" />}

			<div className="hub-header__favorites-button" style={{ display: 'none' }}>
				<FontAwesomeIcon icon={'star-circle'} className="favorites-icon" />
				<div className="favorites-text">Added to Favorites</div>
			</div>
		</div>
	)
}
