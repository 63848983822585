import { ColorService } from '../color/color.service'
import { GoogleAPI } from '../google-api/google-api'
import { defaultTheme } from './default-theme/default-theme'
import { DomainTheme } from './theme.types'

class DomainThemeServicePrototype {
	private getValueOfDerivedProp(
		key: keyof DomainTheme.Theme,
		themeProps: DomainTheme.Theme,
	): Partial<DomainTheme.CompleteTheme> {
		switch (key) {
			case 'appHeaderBgColor':
				const appHeaderBgColor = themeProps[key]
				const appHeaderBgColorStyles = {
					appHeaderTextColor: '#000',
				}
				if (ColorService.getColorTone(appHeaderBgColor) === 'dark') {
					appHeaderBgColorStyles.appHeaderTextColor = '#fff'
				}
				return appHeaderBgColorStyles
			case 'appTrayBgColor':
				const appTrayBgColor = themeProps[key]
				const appTrayBgColorStyles = {
					appTrayIconColor: '#000',
				}
				if (ColorService.getColorTone(appTrayBgColor) === 'dark') {
					appTrayBgColorStyles.appTrayIconColor = '#fff'
				}
				return appTrayBgColorStyles
			case 'borderStyle':
				const borderStyle = themeProps[key]
				const borderStyleStyles = {
					appTrayBorderRadius: '0px',
					elementBorderRadius: '0px',
					buttonBorderRadius: '0px',
				}

				switch (borderStyle) {
					case 'SQUARE':
						borderStyleStyles.appTrayBorderRadius = '0px'
						borderStyleStyles.elementBorderRadius = '0px'
						borderStyleStyles.buttonBorderRadius = '0px'
						break
					case 'SOFT':
						borderStyleStyles.appTrayBorderRadius = '6px'
						borderStyleStyles.elementBorderRadius = '3px'
						borderStyleStyles.buttonBorderRadius = '3px'
						break
					case 'ROUND':
						borderStyleStyles.appTrayBorderRadius = '30px'
						borderStyleStyles.elementBorderRadius = '6px'
						borderStyleStyles.buttonBorderRadius = '25px'
						break
				}

				return borderStyleStyles
			case 'resourceCardBgColor':
				const resourceCardBgColor = themeProps[key]
				const resourceCardBgColorStyles = {
					resourceCardImageBgColor: '#fff',
					resourceCardTextColor: '#000',
				}
				switch (ColorService.getColorTone(resourceCardBgColor)) {
					case 'light':
						resourceCardBgColorStyles.resourceCardImageBgColor = ColorService.adjustHexBrightness(
							resourceCardBgColor,
							-20,
						)
						resourceCardBgColorStyles.resourceCardTextColor = '#000'

						break
					case 'dark':
						resourceCardBgColorStyles.resourceCardImageBgColor = ColorService.adjustHexBrightness(
							resourceCardBgColor,
							20,
						)
						resourceCardBgColorStyles.resourceCardTextColor = '#fff'
						break
				}
				return resourceCardBgColorStyles
			case 'contentBlockBgColor':
				const contentBlockBgColor = themeProps[key]
				const contentBlockBgColorStyles = {
					contentBlockTextColor: '#000',
				}
				switch (ColorService.getColorTone(contentBlockBgColor)) {
					case 'light':
						contentBlockBgColorStyles.contentBlockTextColor = '#000'

						break
					case 'dark':
						contentBlockBgColorStyles.contentBlockTextColor = '#fff'
						break
				}
				return contentBlockBgColorStyles
			case 'buttonBgColor':
				const buttonColor = themeProps[key]
				const buttonColorStyles = {
					buttonContainedBgColor: '#000',
					buttonContainedTextColor: '#000',
					buttonTextTextColor: '#000',
					listOptionBgColorHover: '#000',
				}
				switch (ColorService.getColorTone(buttonColor)) {
					case 'light':
						buttonColorStyles.buttonContainedBgColor = buttonColor
						buttonColorStyles.buttonContainedTextColor = '#000000'
						buttonColorStyles.buttonTextTextColor = ColorService.adjustHexBrightness(buttonColor, -20)
						buttonColorStyles.listOptionBgColorHover = ColorService.hexToRGBA(buttonColor, 0.3)
						break
					case 'dark':
						buttonColorStyles.buttonContainedBgColor = buttonColor
						buttonColorStyles.buttonContainedTextColor = '#ffffff'
						buttonColorStyles.buttonTextTextColor = ColorService.adjustHexBrightness(buttonColor, -20)
						buttonColorStyles.listOptionBgColorHover = ColorService.hexToRGBA(buttonColor, 0.3)
						break
				}
				return buttonColorStyles

			case 'borderWidth':
				const borderWidth = themeProps.borderWidth
				const borderWidthStyle = {
					elementBorderWidth: '1px',
				}
				switch (borderWidth) {
					case 'THIN':
						borderWidthStyle.elementBorderWidth = '1px'
						break
					case 'THICK':
						borderWidthStyle.elementBorderWidth = '2px'
						break
				}
				return borderWidthStyle
			case 'h1FontWeight':
				const h1FontWeight = themeProps[key]
				const headerFontWeightStyles = {
					h2FontWeight: h1FontWeight,
					h3FontWeight: h1FontWeight,
					h4FontWeight: h1FontWeight,
					h5FontWeight: h1FontWeight,
					h6FontWeight: h1FontWeight,
				}
				return headerFontWeightStyles

			case 'h1FontSize':
			case 'bodyFontSize':
				const h1FontSize = parseFloat(themeProps['h1FontSize'])
				const bodyFontSize = parseFloat(themeProps['bodyFontSize'])
				const headerFontStyles = {
					h2FontSize: '11pt',
					h3FontSize: '11pt',
					h4FontSize: '11pt',
					h5FontSize: '11pt',
					h6FontSize: '11pt',
				}

				headerFontStyles.h2FontSize = `${Math.min(bodyFontSize + h1FontSize / 3, h1FontSize)}pt`
				headerFontStyles.h3FontSize = `${Math.min(bodyFontSize + h1FontSize / 4, h1FontSize)}pt`
				headerFontStyles.h4FontSize = `${Math.min(bodyFontSize + h1FontSize / 6, h1FontSize)}pt`
				headerFontStyles.h5FontSize = `${Math.min(bodyFontSize + h1FontSize / 8, h1FontSize)}pt`
				headerFontStyles.h6FontSize = `${Math.min(bodyFontSize + h1FontSize / 12, h1FontSize)}pt`

				return headerFontStyles
			case 'headerFontFamily':
				GoogleAPI.loadFontToDom(themeProps.headerFontFamily)
				return {}
			case 'bodyFontFamily':
				GoogleAPI.loadFontToDom(themeProps.bodyFontFamily)
				return {}
			case 'mode':
				const pageMode = themeProps[key]
				const buttonBgColor = themeProps.buttonBgColor
				const pageModeStyles: Partial<DomainTheme.CompleteTheme> = {}
				switch (pageMode) {
					case 'LIGHT':
						pageModeStyles.colorAdjust0 = '#ffffff'
						pageModeStyles.colorAdjust5 = '#efefef'
						pageModeStyles.colorAdjust10 = '#e0e0e0'
						pageModeStyles.colorAdjust20 = '#d4d4d4'
						pageModeStyles.colorAdjust30 = '#bababa'
						pageModeStyles.colorAdjust40 = '#9f9f9f'
						pageModeStyles.colorAdjust50 = '#858585'
						pageModeStyles.colorAdjust60 = '#6a6a6a'
						pageModeStyles.colorAdjust70 = '#505050'
						pageModeStyles.colorAdjust80 = '#353535'
						pageModeStyles.colorAdjust90 = '#1b1b1b'
						pageModeStyles.colorAdjust100 = '#000000'
						pageModeStyles.colorAdjustAlpha5 = 'rgba(0,0,0,0.05)'
						pageModeStyles.colorAdjustAlpha10 = 'rgba(0,0,0,0.10)'
						pageModeStyles.colorAdjustAlpha20 = 'rgba(0,0,0,0.20)'
						pageModeStyles.colorAdjustAlpha30 = 'rgba(0,0,0,0.30)'
						pageModeStyles.colorAdjustAlpha40 = 'rgba(0,0,0,0.40)'
						pageModeStyles.colorAdjustAlpha50 = 'rgba(0,0,0,0.50)'
						pageModeStyles.colorAdjustAlpha60 = 'rgba(0,0,0,0.60)'
						pageModeStyles.colorAdjustAlpha70 = 'rgba(0,0,0,0.70)'
						pageModeStyles.colorAdjustAlpha80 = 'rgba(0,0,0,0.80)'
						pageModeStyles.colorAdjustAlpha90 = 'rgba(0,0,0,0.90)'
						pageModeStyles.colorAdjustAlpha100 = 'rgba(0,0,0,1.00)'
						pageModeStyles.sideSheetBgColor = '#f4f4f4'
						pageModeStyles.sideSheetHeaderBgColor = '#ffffff'
						pageModeStyles.sideSheetSectionBgColor = '#ffffff'
						pageModeStyles.contextMenuBgColor = 'rgba(255, 255, 255, 0.95)'

						if (ColorService.getToneDifference('#ffffff', buttonBgColor) < 75) {
							pageModeStyles.buttonBgColor = '#000000'
						}

						break
					case 'DARK':
						pageModeStyles.colorAdjust0 = '#000000'
						pageModeStyles.colorAdjust5 = '#111111'
						pageModeStyles.colorAdjust10 = '#1b1b1b'
						pageModeStyles.colorAdjust20 = '#353535'
						pageModeStyles.colorAdjust30 = '#505050'
						pageModeStyles.colorAdjust40 = '#6a6a6a'
						pageModeStyles.colorAdjust50 = '#858585'
						pageModeStyles.colorAdjust60 = '#9f9f9f'
						pageModeStyles.colorAdjust70 = '#bababa'
						pageModeStyles.colorAdjust80 = '#d4d4d4'
						pageModeStyles.colorAdjust90 = '#e0e0e0'
						pageModeStyles.colorAdjust100 = '#ffffff'
						pageModeStyles.colorAdjustAlpha5 = 'rgba(255,255,255,0.05)'
						pageModeStyles.colorAdjustAlpha10 = 'rgba(255,255,255,0.10)'
						pageModeStyles.colorAdjustAlpha20 = 'rgba(255,255,255,0.20)'
						pageModeStyles.colorAdjustAlpha30 = 'rgba(255,255,255,0.30)'
						pageModeStyles.colorAdjustAlpha40 = 'rgba(255,255,255,0.40)'
						pageModeStyles.colorAdjustAlpha50 = 'rgba(255,255,255,0.50)'
						pageModeStyles.colorAdjustAlpha60 = 'rgba(255,255,255,0.60)'
						pageModeStyles.colorAdjustAlpha70 = 'rgba(255,255,255,0.70)'
						pageModeStyles.colorAdjustAlpha80 = 'rgba(255,255,255,0.80)'
						pageModeStyles.colorAdjustAlpha90 = 'rgba(255,255,255,0.90)'
						pageModeStyles.colorAdjustAlpha100 = 'rgba(255,255,255,1.00)'
						pageModeStyles.sideSheetBgColor = '#222222'
						pageModeStyles.sideSheetHeaderBgColor = '#222222'
						pageModeStyles.contextMenuBgColor = 'rgba(0, 0, 0, 0.95)'

						if (ColorService.getToneDifference('#000000', buttonBgColor) < 75) {
							pageModeStyles.buttonBgColor = '#ffffff'
						}
						break
				}
				return pageModeStyles
		}

		return {}
	}

	public getCachedStyles(): DomainTheme.CompleteTheme {
		const cachedStyles = localStorage.getItem(`theme`)

		// get cached theme
		if (cachedStyles) {
			return JSON.parse(cachedStyles) as DomainTheme.CompleteTheme
		} else {
			// get hardcoded fallback theme
			return this.generateThemeProps(defaultTheme)
		}
	}
	public generateThemeProps(themeProps: DomainTheme.Theme): DomainTheme.CompleteTheme {
		let completeTemplateThemeProps: Partial<DomainTheme.CompleteTheme> = { ...themeProps }

		Object.keys(themeProps).forEach((key) => {
			const newDerivedProps = this.getValueOfDerivedProp(key as keyof DomainTheme.Theme, themeProps)
			if (newDerivedProps) {
				completeTemplateThemeProps = {
					...completeTemplateThemeProps,
					...newDerivedProps,
				}
			}
		})

		// @todo, Would be better if we could use TS to know that the theme was complete rather than via casting
		return completeTemplateThemeProps as DomainTheme.CompleteTheme
	}

	public writeThemePropsToCSS(themeProps: Partial<DomainTheme.CompleteTheme>): void {
		Object.keys(themeProps).forEach((key, index, array) => {
			const propertyValue = themeProps[key as keyof DomainTheme.CompleteTheme]

			if (typeof propertyValue === 'string' || typeof propertyValue === 'number') {
				document.documentElement.style.removeProperty(key)
				document.documentElement.style.setProperty(`--${key}`, String(propertyValue))
			}
		})

		document.documentElement.style.setProperty(`--spacing__base-unit`, '7px')
		document.documentElement.style.setProperty(`--btn__standard-height`, '35px')

		localStorage.setItem(`theme`, JSON.stringify(themeProps))
	}
}

export const DomainThemeService = new DomainThemeServicePrototype()
