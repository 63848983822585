import { Typography } from '@components/text/text'

interface ResourceListSectionHeaderProps {
	label: string
	value: number
	onBecomeSticky: () => void
}

export function ResourceListSectionHeader(props: ResourceListSectionHeaderProps) {
	// @todo, Disabled until I can figure out a way to stop this from changing the highlighted section when a user rapidly scrolls past it (via clicking on a link)
	// const headerRef = useRef(null)
	// const [isSticky, ref, setIsSticky] = useDetectSticky(headerRef)

	// useEffect(() => {
	//     if (isSticky) {
	//         props.onBecomeSticky()
	//     }
	// }, [isSticky])

	return (
		<div className="resource-list__section-header" id={`resource-${props.value}`}>
			<Typography type="h6">{props.label}</Typography>
		</div>
	)
}
