import { RootState } from '@redux/store'
import React from 'react'
import ContentLoader from 'react-content-loader'
import { connect } from 'react-redux'
import { DomainTheme } from 'src/services/theme/theme.types'

/** Note: https://skeletonreact.com/ is a good resource for building these out */
interface ConnectedProps {
	theme: DomainTheme.CompleteTheme
}

function MultiColumnTablePlaceholderBodyPrototype(props: ConnectedProps): React.ReactElement {
	return (
		<ContentLoader
			// style={{ width: '100%' }}
			speed={2}
			width={'100%'}
			height={180}
			backgroundColor={props.theme.colorAdjustAlpha10}
			foregroundColor={props.theme.colorAdjustAlpha30}
		>
			<rect x="0" y="0" rx="3" ry="3" width="100%" height="50" />
			<rect x="0" y="60" rx="3" ry="3" width="100%" height="50" />
			<rect x="0" y="120" rx="3" ry="3" width="100%" height="50" />
			<rect x="0" y="180" rx="3" ry="3" width="100%" height="50" />
		</ContentLoader>
	)
}

function mapStateToProps(state: RootState) {
	return {
		theme: state.theme,
	}
}

export const MultiColumnTablePlaceholderBody = connect(mapStateToProps)(MultiColumnTablePlaceholderBodyPrototype)
