import { LoKationButton } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalHeader } from '@components/modal/modal-header'
import { useEffect, useState } from 'react'

import NARDesktop from './desktop-nar.png'
import NARMobile from './mobile-nar.png'

interface MortgageInsightModalProps {
	dismissModal: () => void
}

function HomepageModal({ dismissModal }: MortgageInsightModalProps) {
	const [isMobile, setIsMobile] = useState(false)

	const handleResize = () => {
		setIsMobile(window.innerWidth < 768)
	}

	useEffect(() => {
		handleResize()
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<>
			<Modal
				onClose={dismissModal}
				maxWidth={800}
				maxHeight={825}
				fixedHeight={false}
				className="primary overflow-y__scroll"
			>
				<ModalHeader title="Navigating The New Normal">
					<></>
				</ModalHeader>
				<ModalBody>
					<div>
						<div className="col-12 flex flex-wrap mb-20 flex-alignItems-center">
							<img src={isMobile ? NARMobile : NARDesktop} width={'100%'} className="mb-20" alt="NAR" />
							<div className="col-12 flex flex-column flex-alignItems-center">
								<div className="mb-10">
									<p>
										As the real estate industry evolves, so do the opportunities for those who are
										prepared. How will you leverage these changes to your benefit? Jonathan
										Lickstein, COO of LoKation Real Estate, shares critical strategies in this video
										series to help you turn industry shifts into new avenues for success.
									</p>
								</div>
								<LoKationButton
									className="mt-10 mb-20"
									variant="outlined"
									label="Watch Now!"
									size={'sm'}
									onClick={() => {
										dismissModal()
										window.location.href = `/hubs/education-events`
									}}
								/>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}

export default HomepageModal
