import './scrim.scss'

import React, { useEffect, useState } from 'react'

type ScrimProps = {
	children: React.ReactNode
	isVisible?: boolean
	zIndex?: number
	onClick?: (evt: React.MouseEvent<HTMLDivElement>) => void
	onMouseMove?: (evt: React.MouseEvent<HTMLDivElement>) => void
	style?: React.CSSProperties
}

export function Scrim(props: ScrimProps) {
	const [isScrimClickable, setIsScrimClickable] = useState(false)
	const [showScrim, setShowScrim] = useState(false)
	const [didMouseClickOriginateOnScrim, setDidMouseClickOriginateOnScrim] = useState(false)

	/** ===================================== */
	/** Effects */

	/** Prevent clicking on the scrim for a short period of time. This allows users to double click on things that the scrim may cover */
	useEffect(() => {
		setTimeout(() => {
			setIsScrimClickable(true)
		}, 350)
	}, [])

	/** Begins the animation of showing the scrim shortly after constructing it.
	 * Note: We cannot use CSS animations for this because it breaks the animations of any element rendered inside of the scrim
	 */
	useEffect(() => {
		if (typeof props.isVisible === 'undefined' || props.isVisible === true) {
			setTimeout(() => {
				setShowScrim(true)
			}, 100)
		}
	}, [props.isVisible])

	/** ===================================== */
	/** Methods */

	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`scrim-component`)

		if (showScrim) {
			classes.push(`is-visible`)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	function getStyle(): React.CSSProperties {
		let style: React.CSSProperties = {}

		if (!isScrimClickable) {
			style.pointerEvents = 'none'
		}

		if (props.zIndex) {
			style.zIndex = props.zIndex
		}

		if (props.style) {
			style = { ...style, ...props.style }
		}

		return style
	}

	/** ===================================== */
	/** Render Component */

	return (
		<div
			className={getClass()}
			style={getStyle()}
			onClick={(evt) => {
				if (didMouseClickOriginateOnScrim && props.onClick) {
					props.onClick(evt)
				}
				setDidMouseClickOriginateOnScrim(false)
			}}
			onMouseDown={() => {
				setDidMouseClickOriginateOnScrim(true)
			}}
			onMouseMove={props.onMouseMove}
		>
			{props.children}
		</div>
	)
}
