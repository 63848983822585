import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

type ComponentProps = {
	contact: TransactionMgmtTypes.TransactionTypes.Contact
}

export function Transaction__ContactCard(props: ComponentProps) {
	return (
		<Paper padding={['all']} bgColor="white" elevate={true} margins={['bottom']}>
			<Typography type="h6">
				{props.contact.fullName}, {props.contact.title}
			</Typography>
			<Typography type="strong" color="secondary" margins={['bottom']}>
				{props.contact.organization}
			</Typography>
			<div className="flex flex-alignItems-center">
				<Typography type="normal" color="secondary" className="flex flex-alignItems-center mr-30">
					<FontAwesomeIcon icon={['far', 'envelope']} className="mr-10" />
					{props.contact.email}
				</Typography>

				<Typography type="normal" color="secondary" className="flex flex-alignItems-center">
					<FontAwesomeIcon icon={['far', 'phone']} className="mr-10" />
					{props.contact.phone}
				</Typography>
			</div>
		</Paper>
	)
}
