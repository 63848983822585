/* eslint-disable react/jsx-pascal-case */
import { Typography } from '@components/text/text'
import { useOutletContext } from 'react-router-dom'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

export function TransactionMgmt__Transaction__Updates__Route() {
	const data = useOutletContext() as TransactionMgmtTypes.TransactionTypes.Transaction

	return (
		<div className="col-xs-12" style={{ maxWidth: '1200px' }}>
			<Typography type="h1" margins={['bottom']}>
				Updates
			</Typography>
			<div>{data.closeDate}</div>
		</div>
	)
}
