import { LoKationButton } from '@components/button/button'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Typography } from '@components/text/text'
import { RootState } from '@redux/store'
import React from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { EndUserProps } from '../../services/user/user.types'
import { BackToHomeButton } from '../administration/components/back-to-home-button'
import { RouteService } from '../route.service'
import { transactionMgmtRoutes } from '../routes.constant'

function TransactionMgmt__Landing__RoutePrototype(props: { userProfile: EndUserProps | null }) {
	const navigate = useNavigate()

	return (
		<RouteWrapperV2>
			<RouteBodyV2 className="flex-column">
				<BackToHomeButton />
				<Typography type="h1" margins={['bottom']}>
					Transaction Management
				</Typography>
				<div className="flex flex-wrap">
					<LoKationButton
						size="lg"
						label="Add new Transaction"
						icon="file"
						iconPosition="left"
						className="mr-10"
						variant="contained"
						onClick={() => {
							alert('nothing')
						}}
					/>
					{transactionMgmtRoutes().map((route) => {
						if (RouteService.getIsRouteVisible(route)) {
							return (
								<LoKationButton
									size="lg"
									label={route.label}
									icon={route.icon}
									iconPosition="left"
									className="mr-10 bg-color__adjust-0"
									variant="contained"
									onClick={() => {
										const href = typeof route.href === 'function' ? route.href() : route.href
										navigate(href)
									}}
									key={route.label}
								/>
							)
						}
						return <React.Fragment key={route.label}></React.Fragment>
					})}
				</div>
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
	}
}

export const TransactionMgmt__Landing__Route = connect(mapStateToProps)(TransactionMgmt__Landing__RoutePrototype)
