import { useAppDispatch } from '@redux/hooks'
import { uiSlice } from '@redux/reducers/ui-reducer'
import { RootState, store } from '@redux/store'
import { connect } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'

import { UserDropdownMenu } from '../../routes/shared/components/user-dropdown-menu/user-dropdown-menu'
import { ColorService } from '../../services/color/color.service'
import { EndUserProps } from '../../services/user/user.types'
import { IconButton } from '../icon-button/icon-button'
import { MobileAppHeaderProps } from './mobile-app-header'

interface MobileAppHeaderUserProps {
	currentUser: EndUserProps | null
}

type combinedProps = MobileAppHeaderUserProps & MobileAppHeaderProps

function MobileAppHeaderInnerPrototype(props: combinedProps) {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const themeProps = store.getState().theme
	const isTrayOpen = store.getState().ui.isMobileTrayOpen

	return (
		<div className="app-header__inner flex flex-justifyContent-spaceBetween">
			<IconButton
				icon={'bars'}
				color={props.headerStyle === 'REVERSED' ? '#fff' : 'var(--appHeaderTextColor)'}
				onClick={() => {
					dispatch(uiSlice.actions.setMobileTrayState(!isTrayOpen))
				}}
			/>
			{ColorService.getColorTone(themeProps.appHeaderBgColor) === 'dark' && (
				<img
					onClick={() => {
						dispatch(uiSlice.actions.setMobileTrayState(false))
						navigate(`/hubs`)
					}}
					src={themeProps.logoWhite}
					className="App-logo"
					alt="logo"
					height="20"
				/>
			)}
			{ColorService.getColorTone(themeProps.appHeaderBgColor) === 'light' && (
				<img
					onClick={() => {
						dispatch(uiSlice.actions.setMobileTrayState(false))
						navigate(`/hubs`)
					}}
					src={themeProps.logo}
					className="App-logo"
					alt="logo"
					height="20"
				/>
			)}

			<div className="flex">
				<NavLink to="/search">
					<IconButton
						color={props.headerStyle === 'REVERSED' ? '#fff' : 'var(--appHeaderTextColor)'}
						icon={'search'}
						onClick={() => {
							dispatch(uiSlice.actions.setMobileTrayState(false))
						}}
					/>
				</NavLink>

				<UserDropdownMenu />
			</div>
		</div>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const MobileAppHeaderInner = connect(mapStateToProps)(MobileAppHeaderInnerPrototype)
