import { ContextMenuTypes } from '@components/context-menu/context-menu.types'
import { RootState } from '@redux/store'
import { useState } from 'react'
import { connect } from 'react-redux'
import { useContextMenu } from 'src/services/hooks/use-context-menu'

import FeedbackModal from '../../modals/feedback/feedback__modal'
import { MLSBoardAPI } from '../../services/mls-board/mls-board.api'
import { DomainTheme } from '../../services/theme/theme.types'
import { EndUserProps } from '../../services/user/user.types'
import { UserExport } from '../../services/user-export/user-export.api'
import { LoKationButton } from '../button/button'
import { ContextMenu } from '../context-menu/context-menu'
import { IconButton } from '../icon-button/icon-button'
import { Modal } from '../modal/modal'
import { ModalBody } from '../modal/modal-body'
import { ModalFooter } from '../modal/modal-footer'
import { ModalHeader } from '../modal/modal-header'

interface MlsBoardDropdownPrototypeProps {
	currentUser: EndUserProps | null
	theme: DomainTheme.CompleteTheme
	mlsBoardId: number
	mlsBoardName: string
	onDelete: () => void
}

function MlsBoardDropdownPrototype({
	currentUser,
	theme,
	mlsBoardId,
	mlsBoardName,
	onDelete,
}: MlsBoardDropdownPrototypeProps) {
	const { contextMenu } = useContextMenu()
	const [feedbackModalState, setFeedbackModalState] = useState(false)
	const [isDownloading, setIsDownloading] = useState(false)
	const [modalId, setModalId] = useState<number | null>(null)
	const [boardMembers, setBoardMembers] = useState<EndUserProps[]>([])

	async function getUserExport() {
		try {
			setIsDownloading(true)
			await UserExport.getMlsBoardUserExport(mlsBoardId)
		} catch (error) {
			console.error(error)
		} finally {
			setIsDownloading(false)
		}
	}

	const mlsBoardDeleteConfirmation = (mlsBoardValue: number) => {
		setModalId(mlsBoardValue)
		MLSBoardAPI()
			.getAllBoardMembers(mlsBoardValue)
			.then((res) => {
				setBoardMembers(res.data as EndUserProps[])
			})
			.catch((error) => {
				console.log('Failed to fetch board members:', error)
			})
	}

	const handleDismissModal = () => {
		setModalId(null)
	}

	const deleteBoard = () => {
		setModalId(null)
		onDelete()
	}

	function getUserMenuOptions(): ContextMenuTypes.Option[] {
		const menuOptions: ContextMenuTypes.Option[] = []

		menuOptions.push({
			label: 'Download User List',
			icon: 'users',
			onClick: () => {
				getUserExport()
			},
		})

		menuOptions.push({
			label: 'Delete Board',
			icon: 'trash',
			onClick: () => {
				mlsBoardDeleteConfirmation(mlsBoardId)
			},
		})

		return menuOptions
	}

	return (
		<>
			<div>
				<IconButton
					className=""
					iconClassName={isDownloading ? 'fa-spin' : ''}
					icon={isDownloading ? 'spinner' : 'ellipsis-h'}
					onClick={(evt) => {
						contextMenu.set({ isVisible: true, x: evt.pageX, y: evt.pageY })
					}}
				/>
			</div>
			<ContextMenu
				position={`absolute`}
				x={contextMenu.x}
				y={contextMenu.y}
				visible={contextMenu.isVisible}
				style={{ width: '200px' }}
				options={getUserMenuOptions()}
				onDismiss={() => {
					contextMenu.setIsVisible(false)
				}}
			/>

			{feedbackModalState === true && <FeedbackModal dismissModal={() => setFeedbackModalState(false)} />}

			{modalId && mlsBoardId !== null && (
				<>
					<Modal onClose={deleteBoard} maxWidth={800} maxHeight={800} fixedHeight={false}>
						<ModalHeader title={'Are you sure you want to remove ' + mlsBoardName}>
							<></>
						</ModalHeader>

						<ModalBody>
							<div>
								{boardMembers.length > 0 ? (
									<>
										<div className="mb-20">
											This MLS Board currently has {boardMembers.length} users associated with it.
											Click 'Download User List' to view the associated users.
										</div>
									</>
								) : (
									<div>This board currently has no members associated with it.</div>
								)}
							</div>
						</ModalBody>
						<ModalFooter>
							<div className="flex flex-justifyContent-end mt-20">
								<LoKationButton
									variant="outlined"
									size="sm"
									primary={false}
									label="Cancel"
									className="mr-10"
									onClick={handleDismissModal}
								/>
								<LoKationButton
									variant="contained"
									size="sm"
									primary={true}
									label="Delete board"
									onClick={deleteBoard}
								/>
							</div>
						</ModalFooter>
					</Modal>
				</>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
		theme: state.theme,
	}
}

export const MlsBoardDropdown = connect(mapStateToProps)(MlsBoardDropdownPrototype)
