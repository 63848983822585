import { GoogleMap } from '@components/google-map/google-map'
import { GoogleMapService } from '@components/google-map/google-map.service'
import { GoogleMapTypes } from '@components/google-map/google-map.types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'

import { UserAPI } from '../../services/user/user.api'
import { EndUserProps } from '../../services/user/user.types'
import { UserSearchService } from '../../services/user/user-search.service'
import { AgentSearchState } from './state/agent-search__state.types'

type AgentSearchMapViewProps = MapViewIncompleteResults | MapViewCompleteResults

interface MapViewBaseProps {
	isSearchComplete: boolean
}

interface MapViewIncompleteResults extends MapViewBaseProps {
	isSearchComplete: false
	params: AgentSearchState.SearchParams
	onCompleteSearch: (endUsers: EndUserProps[]) => void
}

interface MapViewCompleteResults extends MapViewBaseProps {
	isSearchComplete: true
	params: AgentSearchState.SearchParams
	endUsers: EndUserProps[]
}

export function AgentSearchMapView(props: AgentSearchMapViewProps) {
	const [endUserMarkers, setEndUserMarkers] = useState<GoogleMapTypes.MarkerData | null>(null)

	useEffect(() => {
		/** If no lat/lon coordinates are provided, use the complete list of users */
		if (
			!props.params.email &&
			!props.params.firstName &&
			!props.params.lastName &&
			!props.params.licensedStateId &&
			!props.params.phone &&
			!props.params.regionId &&
			!props.params.specialties &&
			!props.params.languageSpecialties
		) {
			let showDisabled = false
			if (props.params.showDisabled) {
				showDisabled = true
			}
			UserAPI.getAllEndUserLocations(showDisabled).then((res) => {
				const markers = GoogleMapService().convertUsersToMapMarkers(res.data)
				setEndUserMarkers(markers)
			})
			return
		}

		/** If the search is complete, all we need to do is convert the users props into map markers */
		if (props.isSearchComplete) {
			console.log('search complete')
			const markers = GoogleMapService().convertUsersToMapMarkers(props.endUsers)
			setEndUserMarkers(markers)
		} else {
			UserSearchService()
				.findUsers({ params: props.params })
				.then((endUsers) => {
					const markers = GoogleMapService().convertUsersToMapMarkers(endUsers)
					console.log('user search service')
					setEndUserMarkers(markers)
					props.onCompleteSearch(endUsers)
				})
		}
	}, [])

	useEffect(() => {
		console.log('Markers: ', endUserMarkers)
	}, [endUserMarkers])

	if (!endUserMarkers) {
		return (
			<div className="flex flex-alignItems-center flex-justifyContent-center flex-column col-xs-12 height__100pct">
				<FontAwesomeIcon icon={['fas', 'spinner']} spin={true} size={'5x'} />
				<h5 className="mt-20">Searching</h5>
			</div>
		)
	}

	return (
		<div className="col-xs-12 flex-fillSpace">
			<GoogleMap
				markerData={endUserMarkers}
				centerLat={props.params.geography.srcLat}
				centerLng={props.params.geography.srcLong}
				params={props.params}
			/>
		</div>
	)
}
