import { Paper } from '@components/paper/paper'

export function SupportRouteFlorida() {
	return (
		<>
			<h2 className="mb-20">Florida</h2>
			<Paper bgColor="white" padding={['all']}>
				<h5>Department Email Contacts</h5>
				<table className="lokation__table mb-20" style={{ width: '100%', maxWidth: '1000px' }}>
					<thead>
						<tr>
							<td>Department</td>
							<td>Email</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Compliance / Support</td>
							<td>support@lokationre.com</td>
						</tr>
						<tr>
							<td>Broker Specific Needs</td>
							<td>broker@lokationre.com</td>
						</tr>
						<tr>
							<td>Document Submission</td>
							<td>transactions@lokationre.com</td>
						</tr>
						<tr>
							<td>Commission / Payments</td>
							<td>payments@lokationre.com</td>
						</tr>
						<tr>
							<td>Billing Inquiries</td>
							<td>billing@lokationre.com</td>
						</tr>
						<tr>
							<td>The Mortgage Center</td>
							<td>mortgage@lokationre.com</td>
						</tr>
						<tr>
							<td>The Closing Team</td>
							<td>info@closingteamfl.com</td>
						</tr>
						<tr>
							<td>Programs</td>
							<td>programs@lokationre.com</td>
						</tr>
					</tbody>
				</table>
				<h5 className="mb-10">Support</h5>
				<div>Phone: 954.545.5583 Option 2 Text: 954.231.1377</div>
				<div>
					Set an Appointment with an Online Calendar for Agents: https://appt.link/agent-support-session
				</div>
				<div>Live Zoom Room for Support questions from 9 AM-5 PM M-F https://lokationsupport.com</div>

				<h5 className="mt-20 mb-10">Corporate Headquarters</h5>
				<div>1500 East Atlantic Blvd, Suite B</div>
				<div>Pompano Beach, Florida 33060</div>
				<div>844-LOKATION</div>
				<div>www.lokationre.com | info@lokationre.com</div>
			</Paper>
		</>
	)
}
