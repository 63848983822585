/* eslint-disable react/jsx-pascal-case */
import { store } from '@redux/store'
import { LoaderFunctionArgs, RouteObject } from 'react-router-dom'
import { AdAccountAPI } from 'src/services/advertising/ad-account.api'
import { AdvertisingTypes } from 'src/services/advertising/advertising.types'
import { ChatGptSettingsAPI } from 'src/services/chat-gpt-settings/chat-gpt-settings.api'
import { ChatGptSettings } from 'src/services/chat-gpt-settings/chat-gpt-settings.typings'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

import { AgentSearchRoute } from '../lokation-routes/agent-search/agent-search__route-provider'
import { CalendarFullSizeRoute } from '../lokation-routes/calendar/calendar-full'
import { DepartmentContactsRoute } from '../lokation-routes/department-contacts/department-contacts'
import { DOMAIN_ID, DomainAPI } from '../services/domain/domain.api'
import { Domain } from '../services/domain/domain.types'
import { FAQAPI } from '../services/faq/faq.api'
import { FAQ } from '../services/faq/faq.types'
import { HubsAPI } from '../services/hubs/hubs.api'
import { Hub } from '../services/hubs/hubs.types'
import { LandingPageAPI } from '../services/landing-page/landing-page.api'
import { LoginCarouselAPI } from '../services/login-carousel/login-carousel.api'
import { LoginCarouselNode } from '../services/login-carousel/login-carousel.types'
import { MLSBoardAPI } from '../services/mls-board/mls-board.api'
import { MLSBoard } from '../services/mls-board/mls-board.types'
import { NewsFeedAPI } from '../services/news-feed/news-feed.api'
import { NewsFeed } from '../services/news-feed/news-feed.types'
import { ReferralTypeAPI } from '../services/registration/referral-type.api'
import { UserAPI } from '../services/user/user.api'
import { UserService } from '../services/user/user.service'
import { EndUserProps } from '../services/user/user.types'
import { AdvertisingAccountRouteWrapper } from './administration/ad-account/ad-account'
import { AdvertisingAccount__Route__Advertisements } from './administration/ad-account/routes/advertisements/ad-account__advertisements'
import { AdvertisingAccount__Route__Billing } from './administration/ad-account/routes/billing/ad-account__billing'
import { AdvertisingAccount__Route__General } from './administration/ad-account/routes/general/ad-account__general'
import { AdministrationHomeRoute } from './administration/administration-home'
import { AdvertisingAdministrationRoute } from './administration/advertising/advertising__admin'
import { ApiStatusAdministrationRoute } from './administration/api-status/api-status-administration'
import { CalendarAdministrationRoute } from './administration/calendar/calendar-administration'
import { DomainAdminProviderWrapper } from './administration/domain/domain-provider'
import { DomainAdminState } from './administration/domain/state/domain-admin__state.types'
import { FAQAdministrationRoute } from './administration/faq/faq-administration'
import { FormAdministrationRoute } from './administration/form/form-administration'
import { HubAdministrationRoute } from './administration/hub/hub-administration'
import { LeadsAdministrationRoute } from './administration/lead/lead-administration'
import { MessagesAdminProviderWrapper } from './administration/messages/messages-admin__provider'
import { NewsFeedAdministrationRoute } from './administration/news-feed/news-feed-administration'
import { ResourceAdministrationRoute } from './administration/resources/resources-administration'
import { TagAdministrationRoute } from './administration/tags/tag-administration'
import { ThemeAdministrationRoute } from './administration/theme/theme-administration'
import { UserAdministrationRoute } from './administration/user/user-administration'
import { UserProfileRoute } from './administration/user-profile/user-profile-provider'
import AllHubsRoute from './all-hubs/all-hubs'
import { AppWrapper } from './app-wrapper'
import { AuthenticatedRootRoute } from './authenticated-root/authenticated-root'
import CreateAccountSuccessRoute from './create-account-success/create-account-success'
import { ErrorPage } from './error.page'
import { FAQsRoute } from './faqs/faqs'
import { SingleFAQRoute } from './faqs/single-faq'
import ForgotPasswordRoute from './forgot-password/forgot-password'
import { HomeRoute } from './home/home'
import { HubRoute } from './hub/hub'
import { LoginRoute } from './login/login'
import { MessagesRoute } from './messages/messages'
import { MessagesRouteBody } from './messages/messages__body'
import { MessagesRouteNoSelectedChatPlaceholder } from './messages/messages__no-selected-chat-placeholder'
import AllStoriesRoute from './news-feed/all-stories'
import { SingleNewsFeedStoryRoute } from './news-feed/single-news-feed-story'
import { Registration } from './registration/registration.types'
import { RegistrationProviderWrapper } from './registration/registration-provider'
import { RejoinRoute } from './rejoin/rejoin-provider'
import ResetPasswordRoute, { ResetPasswordParams } from './reset-password/reset-password'
import { RouteData } from './routes.types'
import { SitewideSearchRoute } from './sitewide-search/sitewide-search'
import { SupportRoute } from './support/support'
import { TransactionMgmt__AdminLanding__Route } from './transaction-mgmt/admin/admin-landing.route'
import { TransactionMgmt__Forms__Route } from './transaction-mgmt/forms/forms.route'
import { TransactionMgmt__Reports__Route } from './transaction-mgmt/reports/reports.route'
import { TransactionMgmt__Tasks__Route } from './transaction-mgmt/tasks/tasks.route'
import { TransactionMgmt__Transaction__Commissions__Route } from './transaction-mgmt/transaction/routes/commissions/commissions.route'
import { TransactionMgmt__Transaction__Documents__Route } from './transaction-mgmt/transaction/routes/documents/documents.route'
import { TransactionMgmt__Transaction__Overview__Route } from './transaction-mgmt/transaction/routes/overview/overview.route'
import { TransactionMgmt__Transaction__Tasks__Route } from './transaction-mgmt/transaction/routes/tasks/tasks.route'
import { TransactionMgmt__Transaction__Timeline__Route } from './transaction-mgmt/transaction/routes/timeline/timeline.route'
import { TransactionMgmt__Transaction__Updates__Route } from './transaction-mgmt/transaction/routes/updates/updates.route'
import { TransactionMgmt__Transaction__Route } from './transaction-mgmt/transaction/transaction.route'
import { TransactionMgmt__Landing__Route } from './transaction-mgmt/transaction-mgmt.route'
import { TransactionMgmt__Transactions__Route } from './transaction-mgmt/transactions/transactions.route'
// import StripeSubscribe from "./registration/stripe/stripe_subscribe";
export const hubRoutePath = `/hubs`
export const settingsRoutePath = `/administration`
export const transactionMgmtRoutePath = `/transaction-management`

/** Attempts to authenticate the user. If user cannot be authenticated, an error is thrown */
export async function loadUserProfileProps(args: LoaderFunctionArgs): Promise<EndUserProps | null> {
	if (!args.params.userId) {
		return null
	} else {
		return (await UserAPI.getUserDetails(parseFloat(args.params.userId))).data
	}
}

export async function loadAdvertisingAccountProps(args: LoaderFunctionArgs): Promise<AdvertisingTypes.Account | null> {
	if (!args.params.accountId) {
		return null
	} else {
		return (await AdAccountAPI.getAccountById(parseFloat(args.params.accountId))).data
	}
}

export function loadHubProps(args: LoaderFunctionArgs): Promise<Hub> {
	return new Promise((resolve, reject) => {
		if (!args.params.slug) {
			reject()
		} else {
			HubsAPI.getHubBySlug(args.params.slug)
				.then((hubRes) => {
					if (hubRes.data) {
						resolve(hubRes.data)
					} else {
						reject()
					}
				})
				.catch(() => {
					reject()
				})
		}
	})
}

export function loadTransactionProps(
	args: LoaderFunctionArgs,
): Promise<TransactionMgmtTypes.TransactionTypes.Transaction> {
	return new Promise((resolve, reject) => {
		if (!args.params.transactionId) {
			reject()
		} else {
			TransactionManagementAPI.getTransactionById(Number(args.params.transactionId))
				.then((res) => {
					if (res.data) {
						resolve(res.data)
					} else {
						reject()
					}
				})
				.catch(() => {
					reject()
				})
		}
	})
}

export async function loadNewsFeedStoryProps(args: LoaderFunctionArgs): Promise<NewsFeed.Story | undefined> {
	if (!args.params.storyId) {
		return undefined
	} else {
		return (await NewsFeedAPI.getStory(parseFloat(args.params.storyId))).data
	}
}

export async function loadFAQProps(args: LoaderFunctionArgs): Promise<FAQ | undefined> {
	if (!args.params.faqId) {
		return undefined
	} else {
		return (await FAQAPI.getFAQById(parseFloat(args.params.faqId))).data
	}
}

export function loadDomainAdminState(): Promise<DomainAdminState.DomainAdminData> {
	return new Promise<DomainAdminState.DomainAdminData>((resolve) => {
		let general: Domain.Properties | undefined = undefined
		let loginCarousel: LoginCarouselNode[] = []
		let mlsBoardOptions: MLSBoard.Complete[] = []
		let referralTypeOptions: Registration.ReferralTypeOption[] = []
		let landingPageResources: Domain.ResourceRef[] = []
		let landingPageHubs: Domain.HubRef[] = []
		let chatGptSettings: ChatGptSettings.CompleteResponse

		const requestPromises: Promise<any>[] = []

		async function getAllMlsBoardOptions(page: number = 0, size: number = 20): Promise<MLSBoard.Complete[]> {
			const response = await MLSBoardAPI().getAllOptions({
				page,
				size,
				sort: [{ property: 'displayValue', direction: 'asc' }],
			})
			if (response.data.totalPages > page + 1) {
				return response.data.items.concat(await getAllMlsBoardOptions(page + 1, size))
			}
			return response.data.items
		}

		const mlsBoardPromise = getAllMlsBoardOptions().then((items: MLSBoard.Complete[]) => {
			mlsBoardOptions = items
		})
		requestPromises.push(mlsBoardPromise)

		const referralTypePromise = ReferralTypeAPI()
			.getAllOptions({ page: 0, size: 20, sort: [{ property: 'displayValue', direction: 'asc' }] })
			.then((res) => {
				referralTypeOptions = res.data.items
			})
		requestPromises.push(referralTypePromise)

		const loginCarouselPromise = LoginCarouselAPI.getNodes({ page: 0, size: 20 }).then((res) => {
			loginCarousel = res.data.items
		})
		requestPromises.push(loginCarouselPromise)

		const generalPropsPromise = DomainAPI.get(DOMAIN_ID).then((res) => {
			general = res.data
		})
		requestPromises.push(generalPropsPromise)

		const landingPageHubPromise = LandingPageAPI.getHubs(DOMAIN_ID).then((res) => {
			landingPageHubs = res.data.items
		})
		requestPromises.push(landingPageHubPromise)

		const landingPageResourcePromise = LandingPageAPI.getResources(DOMAIN_ID).then((res) => {
			landingPageResources = res.data.items
		})
		requestPromises.push(landingPageResourcePromise)

		const chatGptSettingsPromise = ChatGptSettingsAPI.get(DOMAIN_ID).then((res) => {
			chatGptSettings = res.data
		})
		requestPromises.push(chatGptSettingsPromise)

		Promise.all(requestPromises).then((res) => {
			if (general) {
				const state: DomainAdminState.DomainAdminData = {
					general,
					loginCarousel,
					mlsBoardOptions,
					referralTypeOptions,
					landingPageHubs,
					landingPageResources,
					chatGptSettings,
				}

				resolve(state)
			}
		})
	})
}

export function getResetPasswordParams(): ResetPasswordParams {
	const resetPasswordParams: ResetPasswordParams = {
		userId: null,
		resetToken: null,
	}

	const urlParams = new URLSearchParams(window.location.search)
	const userId = urlParams.get(`d`)
	const resetPasswordToken = urlParams.get(`token`)
	if (typeof userId === 'string') {
		resetPasswordParams.userId = parseFloat(userId)
	}
	if (typeof resetPasswordToken === 'string') {
		resetPasswordParams.resetToken = resetPasswordToken
	}

	return resetPasswordParams
}

function isUserAdmin(): boolean {
	const currentUserProps = store.getState().user
	if (currentUserProps) {
		return UserService.isUserAdmin(currentUserProps)
	}
	return false
}

function isSuperUserAdmin(): boolean {
	const currentUserProps = store.getState().user
	if (currentUserProps) {
		return UserService.isUserSuperAdmin(currentUserProps)
	}
	return false
}

export function adminRoutes(): RouteData[] {
	return [
		{
			label: 'Advertising',
			description: 'Manage accounts and advertisements shown on this site',
			href: `${settingsRoutePath}/advertising`,
			icon: 'money-bill',
			routeBody: <AdvertisingAdministrationRoute />,
			visible: isSuperUserAdmin(),
		},
		{
			label: 'API Status Management',
			description: 'Monitor the status of API integrations',
			href: `${settingsRoutePath}/api-status-management`,
			icon: 'circle-nodes',
			routeBody: <ApiStatusAdministrationRoute />,
			visible: isSuperUserAdmin(),
		},
		{
			label: 'Calendar',
			description: 'Create events for the community calendar',
			href: `${settingsRoutePath}/calendar`,
			icon: 'calendar',
			routeBody: <CalendarAdministrationRoute />,
			visible: isUserAdmin(),
		},
		{
			label: 'Domain',
			description: 'Edit general domain settings',
			href: `${settingsRoutePath}/domain`,
			icon: 'globe',
			routeBody: <DomainAdminProviderWrapper />,
			visible: isSuperUserAdmin(),
			loader: loadDomainAdminState,
		},
		{
			label: 'FAQs',
			description: 'Manage Frequently Asked Questions',
			href: `${settingsRoutePath}/faq`,
			icon: 'question-circle',
			routeBody: <FAQAdministrationRoute />,
			visible: isUserAdmin(),
		},
		{
			label: 'Forms',
			description: 'Manage forms',
			href: `${settingsRoutePath}/forms`,
			icon: 'input-text',
			routeBody: <FormAdministrationRoute />,
			visible: isSuperUserAdmin(),
		},
		{
			label: 'Hubs',
			description: 'Manage hubs',
			href: `${settingsRoutePath}/hubs`,
			icon: 'anchor',
			routeBody: <HubAdministrationRoute />,
			visible: isSuperUserAdmin(),
		},
		{
			label: 'Leads',
			description: 'Manage leads and conversions',
			href: `${settingsRoutePath}/leads`,
			icon: 'chart-line',
			routeBody: <LeadsAdministrationRoute />,
			visible: isUserAdmin(),
		},
		{
			label: 'Messages',
			description: 'Send messages to users in your domain',
			href: `${settingsRoutePath}/messages`,
			icon: 'message',
			routeBody: <MessagesAdminProviderWrapper />,
			visible: isSuperUserAdmin(),
		},

		{
			label: 'News Feed',
			description: 'Create and edit stories shown in the news feed',
			href: `${settingsRoutePath}/news-feed`,
			icon: 'newspaper',
			routeBody: <NewsFeedAdministrationRoute />,
			visible: isSuperUserAdmin(),
		},
		{
			label: 'Resources',
			description: 'Manage resources',
			href: `${settingsRoutePath}/resources`,
			icon: 'link',
			routeBody: <ResourceAdministrationRoute />,
			visible: isSuperUserAdmin(),
		},

		{
			label: 'Tags',
			description: 'Manage tags applied to resources, FAQs, etc.',
			href: `${settingsRoutePath}/tag`,
			icon: 'tag',
			routeBody: <TagAdministrationRoute />,
			visible: isSuperUserAdmin(),
		},
		{
			label: 'Theme',
			description: 'Update application themed used throughout platform',
			href: `${settingsRoutePath}/theme`,
			icon: 'palette',
			routeBody: <ThemeAdministrationRoute />,
			visible: isSuperUserAdmin(),
		},
		{
			label: 'Users',
			description: 'Manage users',
			href: `${settingsRoutePath}/users`,
			icon: 'users',
			routeBody: <UserAdministrationRoute />,
			visible: isUserAdmin(),
		},
	]
}

export const allRoutes: RouteObject[] = [
	{
		path: '/',
		errorElement: <ErrorPage />,
		element: <AppWrapper />,
		children: [
			{
				path: '/',
				element: <LoginRoute />,
				loader: undefined,
			},
			{
				path: '/registration',
				element: <RegistrationProviderWrapper />,
				loader: undefined,
			},
			{
				path: '/forgot-password',
				element: <ForgotPasswordRoute />,
				loader: undefined,
			},
			{
				path: '/resetPassword',
				element: <ResetPasswordRoute />,
				loader: getResetPasswordParams,
			},
			{
				path: '/create-account-success',
				element: <CreateAccountSuccessRoute />,
				loader: undefined,
			},
			{
				path: '/rejoin',
				element: <RejoinRoute />,
				loader: undefined,
			},
			{
				path: '/search',
				element: <AuthenticatedRootRoute routeSection="HUBS" />,
				children: [
					{
						path: '',
						index: true,
						element: <SitewideSearchRoute />,
						loader: undefined,
					},
				],
			},
			{
				path: '/user-search',
				element: <AuthenticatedRootRoute routeSection="HUBS" />,
				children: [
					{
						path: '',
						index: true,
						element: <AgentSearchRoute />,
						loader: undefined,
					},
				],
			},
			{
				path: '/messages',
				element: <AuthenticatedRootRoute routeSection="HUBS" />,
				children: [
					{
						path: '',
						element: <MessagesRoute />,
						loader: undefined,
						children: [
							{
								index: true,
								element: <MessagesRouteNoSelectedChatPlaceholder />,
							},
							{
								path: ':chatGroupId',
								element: <MessagesRouteBody />,
							},
						],
					},
				],
			},
			{
				path: '/department-contacts',
				element: <AuthenticatedRootRoute routeSection="HUBS" />,
				children: [
					{
						path: '',
						index: true,
						element: <DepartmentContactsRoute />,
						loader: undefined,
					},
				],
			},
			{
				path: '/calendar',
				element: <AuthenticatedRootRoute routeSection="HUBS" />,
				children: [
					{
						path: '',
						index: true,
						element: <CalendarFullSizeRoute />,
						loader: undefined,
					},
				],
			},
			{
				path: '/faqs',
				element: <AuthenticatedRootRoute routeSection="HUBS" />,
				children: [
					{
						path: '',
						index: true,
						element: <FAQsRoute />,
						loader: undefined,
					},
					{
						path: `:faqId`,
						loader: loadFAQProps,
						element: <SingleFAQRoute />,
					},
				],
			},
			{
				path: '/support',
				element: <AuthenticatedRootRoute routeSection="HUBS" />,
				children: [
					{
						path: '',
						index: true,
						element: <SupportRoute />,
						loader: undefined,
					},
				],
			},
			{
				path: `/news-feed`,
				element: <AuthenticatedRootRoute routeSection="HUBS" />,
				errorElement: <ErrorPage />,
				children: [
					{
						path: '',
						index: true,
						element: <AllStoriesRoute />,
						loader: undefined,
					},
					{
						path: `:storyId`,
						loader: loadNewsFeedStoryProps,
						element: <SingleNewsFeedStoryRoute />,
					},
				],
			},
			{
				path: `/hubs`,
				element: <AuthenticatedRootRoute routeSection="HUBS" />,
				errorElement: <ErrorPage />,
				children: [
					{
						index: true,
						path: ``,
						element: <HomeRoute />,
					},
					{
						path: 'explore',
						element: <AllHubsRoute />,
					},
					{
						path: `:slug`,
						loader: loadHubProps,
						element: <HubRoute />,
					},
				],
			},
			{
				path: `/administration`,
				element: <AuthenticatedRootRoute routeSection="SETTINGS" />,
				errorElement: <ErrorPage />,
				children: (function () {
					const routes: RouteObject[] = [
						{
							index: true,
							path: ``,
							element: <AdministrationHomeRoute />,
						},
					]

					adminRoutes().forEach((route) => {
						const routeHref = typeof route.href === 'string' ? route.href : route.href()
						routes.push({
							path: routeHref,
							element: route.routeBody,
							loader: route.loader ? route.loader : undefined,
						})
					})

					routes.push({
						path: `users/:userId`,
						loader: loadUserProfileProps,
						element: <UserProfileRoute />,
					})

					routes.push({
						path: `ad-account/:accountId`,
						loader: loadAdvertisingAccountProps,
						element: <AdvertisingAccountRouteWrapper />,
						children: [
							// eslint-disable-next-line react/jsx-pascal-case
							{ path: 'general', element: <AdvertisingAccount__Route__General /> },
							// eslint-disable-next-line react/jsx-pascal-case
							{ path: 'advertisements', element: <AdvertisingAccount__Route__Advertisements /> },
							// eslint-disable-next-line react/jsx-pascal-case
							{ path: 'billing', element: <AdvertisingAccount__Route__Billing /> },
						],
					})

					return routes
				})(),
			},
			{
				path: transactionMgmtRoutePath,
				element: <AuthenticatedRootRoute routeSection="TRANSACTION_MGMT" />,
				errorElement: <ErrorPage />,
				children: (function () {
					const routes: RouteObject[] = [
						{
							index: true,
							path: ``,
							element: <TransactionMgmt__Landing__Route />,
						},
						{
							path: `transactions`,
							element: <TransactionMgmt__Transactions__Route />,
						},
						{
							path: `forms`,
							element: <TransactionMgmt__Forms__Route />,
						},
						{
							path: `tasks`,
							element: <TransactionMgmt__Tasks__Route />,
						},
						{
							path: `reports`,
							element: <TransactionMgmt__Reports__Route />,
						},
						{
							path: `admin`,
							element: <TransactionMgmt__AdminLanding__Route />,
						},
						{
							path: `transaction/:transactionId`,
							loader: loadTransactionProps,
							element: <TransactionMgmt__Transaction__Route />,
							children: transactionRoutes().map((route) => {
								return {
									path: typeof route.href === 'function' ? route.href() : route.href,
									element: route.routeBody,
								}
							}),
						},
					]

					return routes
				})(),
			},
		],
	},
]

export function transactionMgmtRoutes(): RouteData[] {
	return [
		{
			label: 'Transactions',
			description: 'View all transactions lorem ipsum',
			href: `${transactionMgmtRoutePath}/transactions`,
			icon: 'file',
			routeBody: <TransactionMgmt__Transactions__Route />,
			visible: true,
		},
		{
			label: 'Forms / Documents',
			description: 'Lorem ipsum more text goes here',
			href: `${transactionMgmtRoutePath}/forms`,
			icon: 'file',
			routeBody: <TransactionMgmt__Forms__Route />,
			visible: true,
		},
		{
			label: 'Tasks',
			description: 'Lorem ipsum more text goes here',
			href: `${transactionMgmtRoutePath}/tasks`,
			icon: 'file',
			routeBody: <TransactionMgmt__Tasks__Route />,
			visible: true,
		},
		{
			label: 'Commission Report',
			description: 'View all transactions lorem ipsum',
			href: `${transactionMgmtRoutePath}/reports`,
			icon: 'file',
			routeBody: <TransactionMgmt__Reports__Route />,
			visible: true,
		},
		{
			label: 'Administration',
			description: 'View all transactions lorem ipsum',
			href: `${transactionMgmtRoutePath}/admin`,
			icon: 'file',
			routeBody: <TransactionMgmt__AdminLanding__Route />,
			visible: isUserAdmin(),
		},
	]
}

export function transactionRoutes(): RouteData[] {
	return [
		{
			label: 'Overview',
			description: 'Lorem ipsum',
			href: ``,
			icon: 'file',
			routeBody: <TransactionMgmt__Transaction__Overview__Route />,
			visible: true,
		},
		{
			label: 'Timeline',
			description: 'Lorem ipsum',
			href: `timeline`,
			icon: 'file',
			routeBody: <TransactionMgmt__Transaction__Timeline__Route />,
			visible: true,
		},
		{
			label: 'Tasks',
			description: 'Lorem ipsum',
			href: `tasks`,
			icon: 'file',
			routeBody: <TransactionMgmt__Transaction__Tasks__Route />,
			visible: true,
		},
		{
			label: 'Documents',
			description: 'Lorem ipsum',
			href: `documents`,
			icon: 'file',
			routeBody: <TransactionMgmt__Transaction__Documents__Route />,
			visible: true,
		},
		{
			label: 'Commissions',
			description: 'Lorem ipsum',
			href: `commissions`,
			icon: 'file',
			routeBody: <TransactionMgmt__Transaction__Commissions__Route />,
			visible: true,
		},
		{
			label: 'Updates',
			description: 'Lorem ipsum',
			href: `updates`,
			icon: 'file',
			routeBody: <TransactionMgmt__Transaction__Updates__Route />,
			visible: true,
		},
	]
}
