import { MultiColumnTableTypes } from '@components/multi-column-table/multi-column-table.types'
import { MultiColumnTable } from '@components/multi-column-table/multi-column-table__wrapper'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { transactionMgmtRoutePath } from 'src/routes/routes.constant'
import { TransactionManagementAPI } from 'src/services/transaction-mgmt/transaction-mgmt.api'

import { TransactionMgmtTypes } from '../../../services/transaction-mgmt/transaction-mgmt.types'

export function TransactionMgmt__Tasks__Route() {
	const navigate = useNavigate()
	const [tasks, setTasks] = useState<TransactionMgmtTypes.TaskTypes.Task[] | null>(null)

	useEffect(() => {
		TransactionManagementAPI.findTasks().then((res) => {
			setTasks(res.data)
		})
	}, [])

	function getTableColumns(): MultiColumnTableTypes.Column<TransactionMgmtTypes.TaskTypes.Task>[] {
		const columns: MultiColumnTableTypes.Column<TransactionMgmtTypes.TaskTypes.Task>[] = []

		columns.push({
			label: 'Name',
			columnId: 'name',
			value: 'name',
			displayIndex: 0,
			visible: true,
			width: 'fill',
		})

		return columns
	}

	return (
		<RouteWrapperV2>
			<RouteSubnavigation
				isCustom={false}
				title={`Tasks`}
				backButton={{
					label: 'Transaction Management',
					onClick: () => {
						navigate(`${transactionMgmtRoutePath}`)
					},
				}}
				routes={[]}
			/>

			<RouteBodyV2 className="flex flex-column flex-alignItems-start">
				<MultiColumnTable<TransactionMgmtTypes.TaskTypes.Task>
					columns={getTableColumns()}
					idKey={`taskId`}
					className="col-xs-12"
					items={tasks}
					isConfigurable={false}
					onSelect={(transaction) => {
						/** empty */
					}}
					onDoubleClick={(transaction) => {
						/** empty */
					}}
					selectBehavior={'single'}
					selectedItems={[]}
					sortBehavior={{
						sortMethod: 'column-value',
						columnId: 'name',
					}}
					tableId={''}
				/>
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}
