import { MultiColumnTableTypes } from '@components/multi-column-table/multi-column-table.types'
import { MultiColumnTable } from '@components/multi-column-table/multi-column-table__wrapper'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { transactionMgmtRoutePath } from 'src/routes/routes.constant'
import { TransactionManagementService } from 'src/services/transaction-mgmt/transaction-mgmt.service'
import { TransactionMgmt__Transactions } from 'src/services/transaction-mgmt/transaction-mgmt.static-data'
import { EndUserProps } from 'src/services/user/user.types'

import { TransactionMgmtTypes } from './../../../services/transaction-mgmt/transaction-mgmt.types'

function TransactionMgmt__Transactions__RoutePrototype(props: { userProfile: EndUserProps | null }) {
	const navigate = useNavigate()

	function getTransactionTableColumns(): MultiColumnTableTypes.Column<TransactionMgmtTypes.TransactionTypes.Transaction>[] {
		const columns: MultiColumnTableTypes.Column<TransactionMgmtTypes.TransactionTypes.Transaction>[] = []

		columns.push({
			label: 'Name',
			columnId: 'name',
			value: 'name',
			displayIndex: 0,
			visible: true,
			width: 'fill',
		})

		columns.push({
			label: 'Type',
			columnId: 'type',
			value: 'type',
			displayedValue: (item) => {
				return TransactionManagementService.getLabelForTransactionType(item.type)
			},
			displayIndex: 1,
			visible: true,
			width: 200,
		})

		columns.push({
			label: 'Status',
			columnId: 'status',
			value: 'status',
			displayedValue: (item) => {
				return TransactionManagementService.getLabelForTransactionStatus(item.status)
			},
			displayIndex: 2,
			visible: true,
			width: 200,
		})

		columns.push({
			label: 'Sale Price',
			columnId: 'salePrice',
			value: 'salePrice',
			displayIndex: 3,
			visible: true,
			width: 200,
		})

		columns.push({
			label: 'Close Date',
			columnId: 'closeDate',
			value: 'closeDate',
			displayIndex: 4,
			visible: true,
			width: 200,
		})

		return columns
	}

	return (
		<RouteWrapperV2>
			<RouteSubnavigation
				isCustom={false}
				title={`Transactions`}
				backButton={{
					label: 'Transaction Management',
					onClick: () => {
						navigate(`${transactionMgmtRoutePath}`)
					},
				}}
				routes={[]}
			/>

			<RouteBodyV2 className="flex flex-column flex-alignItems-start">
				<MultiColumnTable<TransactionMgmtTypes.TransactionTypes.Transaction>
					columns={getTransactionTableColumns()}
					idKey={'transactionId'}
					className="col-xs-12"
					items={TransactionMgmt__Transactions}
					isConfigurable={false}
					onSelect={(transaction) => {
						/** empty */
					}}
					onDoubleClick={(transaction) => {
						navigate(`${transactionMgmtRoutePath}/transaction/${transaction.transactionId}`)
					}}
					selectBehavior={'single'}
					selectedItems={[]}
					sortBehavior={{
						sortMethod: 'column-value',
						columnId: 'name',
					}}
					tableId={''}
				/>
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
	}
}

export const TransactionMgmt__Transactions__Route = connect(mapStateToProps)(
	TransactionMgmt__Transactions__RoutePrototype,
)
