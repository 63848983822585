import { AxiosResponse } from 'axios'

import { TransactionMgmt__Transactions } from './transaction-mgmt.static-data'
import { TransactionMgmtTypes } from './transaction-mgmt.types'

export namespace TransactionManagementAPI {
	export function getTransactionById(
		transactionId: number,
	): Promise<AxiosResponse<TransactionMgmtTypes.TransactionTypes.Transaction>> {
		return new Promise((resolve, reject) => {
			const thisTransaction = TransactionMgmt__Transactions.find(
				(transaction) => transaction.transactionId === transactionId,
			)

			if (!thisTransaction) {
				reject()
				return
			}

			const response: AxiosResponse<TransactionMgmtTypes.TransactionTypes.Transaction> = {
				status: 200,
				statusText: '',
				headers: {},
				data: thisTransaction,
				config: {},
			}

			resolve(response)
		})
	}

	export function findTasks(): Promise<AxiosResponse<TransactionMgmtTypes.TaskTypes.Task[]>> {
		return new Promise((resolve, reject) => {
			let allTasks: TransactionMgmtTypes.TaskTypes.Task[] = []

			TransactionMgmt__Transactions.forEach((transaction) => {
				allTasks = [...allTasks, ...transaction.tasks]
			})

			const response: AxiosResponse<TransactionMgmtTypes.TaskTypes.Task[]> = {
				status: 200,
				statusText: '',
				headers: {},
				data: allTasks,
				config: {},
			}

			resolve(response)
		})
	}
}
