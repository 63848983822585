import {
	closestCenter,
	DndContext,
	DragEndEvent,
	MouseSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'

import { MultiColumnTableService } from '../multi-column-table.service'
import { MultiColumnTableTypes } from '../multi-column-table.types'
import { useMultiColumnTable } from '../state/multi-column-table__state'
import { MultiColumnTableOption } from './multi-column-table-option'

export function MultiColumnTableBody<T extends Object>(props: MultiColumnTableTypes.Body<T>) {
	const newItemTableState = useMultiColumnTable()
	/** ================================ */
	/** Props and State */
	const [sortedItems, setSortedItems] = useState(props.items)
	const [orderedItemIds, setOrderedItemIds] = useState<string[]>([])
	const [activeDragItem, setActiveDragItem] = useState<T | null>(null)

	const selectedItemIds = useMemo(() => {
		return MultiColumnTableService.getSelectedItemIds<T>({ selectedItems: props.selectedItems, idKey: props.idKey })
	}, [props.selectedItems])

	const sortedColumns = MultiColumnTableService.sortColumnsByDisplayIndex(
		props.columns,
		newItemTableState.columnConfigOverrides,
	)

	/** ================================ */
	/** Code related to DND Kit */

	/** Create state for the order of the items in this table. This is used if the table is sortable */
	useEffect(() => {
		setSortedItems(props.items)
		updateOrderedItemIds(props.items)
	}, [props.items])

	/** If this table is sortable, we also need to create an array of item ID's sorted by display order */
	function updateOrderedItemIds(items: T[]): void {
		setOrderedItemIds(
			items.map((item) => {
				const itemId = item[props.idKey]
				if (typeof itemId === 'number' || typeof itemId === 'string') {
					return String(itemId)
				}
				console.error(`Could not get item ID of item in table`)
				return `-1`
			}),
		)
	}

	const mouseSensor = useSensor(MouseSensor, {
		activationConstraint: {
			distance: 40,
		},
	})
	const pointerSensor = useSensor(PointerSensor)

	const sensors = useSensors(pointerSensor, mouseSensor)

	/** This is used to sort items by the value of their display order. Only used when the items are sortable by the user */
	function sortItemsByDisplayOrder(items: T[]) {
		return items.sort((a, b) => {
			if (props.sortBehavior.sortMethod !== 'display-index') {
				return 1
			}
			const aDisplayOrder = a[props.sortBehavior.displayIndexKey]
			const bDisplayOrder = b[props.sortBehavior.displayIndexKey]

			if (typeof aDisplayOrder === 'number' && typeof bDisplayOrder === 'number') {
				return aDisplayOrder > bDisplayOrder ? -1 : 1
			}
			return 1
		})
	}

	/** ================================ */
	/** Methods */

	function isOptionSelected(item: T): boolean {
		const itemId = item[props.idKey]
		if (typeof itemId === 'string' || typeof itemId === 'number') {
			const isSelected = selectedItemIds.includes(itemId)
			return isSelected
		}
		return false
	}

	function updateSelectedOptions(fcProps: {
		item: T
		isSelected: boolean
		unselectOtherOptions: boolean
		pointerType: MultiColumnTableTypes.PointerType
	}): void {
		let updatedSelectedOptions: T[] = []

		/** Reselect all other options if applicable */
		if (!fcProps.unselectOtherOptions) {
			updatedSelectedOptions = [...props.selectedItems]

			if (props.disableMultiselectRule) {
				/** If there is a "disable multiselect" rule, unselect other options that cannot be multiselected */
				updatedSelectedOptions = updatedSelectedOptions.filter((option) => {
					if (props.disableMultiselectRule) {
						const cannotBeMultiselected = props.disableMultiselectRule(option)
						return !cannotBeMultiselected
					}
					return true
				})
			}
		}

		if (fcProps.isSelected) {
			updatedSelectedOptions.push(fcProps.item)
		} else {
			updatedSelectedOptions = updatedSelectedOptions.filter((item) => {
				return item[props.idKey] !== fcProps.item[props.idKey]
			})
		}

		props.onSelect(updatedSelectedOptions, fcProps.pointerType)
	}

	function getIsMultiselectAvailableForItem(item: T): boolean {
		if (props.selectBehavior === 'single') {
			return false
		}

		if (props.disableMultiselectRule) {
			return !props.disableMultiselectRule(item)
		}
		return true
	}

	function createItem(item: T, isSortable: boolean, isDraggable: boolean): React.ReactNode {
		const isSelected = isOptionSelected(item)
		const isBeingDragged = activeDragItem !== null && activeDragItem[props.idKey] === item[props.idKey]
		const isDroppableTarget = props.dropBehavior !== undefined && props.dropBehavior.isDroppable(item)
		const isDroppable = isDroppableTarget && !isBeingDragged
		const isMultiselectAvailableForItem = getIsMultiselectAvailableForItem(item)

		return (
			<MultiColumnTableOption
				key={`${item[props.idKey]}__${isDroppable ? 'droppable' : 'not-droppable'}`}
				item={item}
				disabled={props.disabled}
				sortedColumns={sortedColumns}
				isSelected={isSelected}
				isSortable={isSortable}
				isDroppable={isDroppable}
				isDraggable={isDraggable}
				isDragActive={activeDragItem !== null}
				includeCheckbox={isMultiselectAvailableForItem && props.selectBehavior === 'multi-checkbox'}
				showAllColumns={props.showAllColumns}
				onDoubleClick={(evt) => {
					if (props.onDoubleClick) {
						props.onDoubleClick(item)
					}
				}}
				onLongPress={props.onLongPress}
				idKey={props.idKey}
				onClick={(evt) => {
					let updatedSelectState = false
					let unselectOtherOptions = false

					/** If selectBehavior 'multi-checkbox' is used, toggle the select state of this option */
					if (props.selectBehavior === 'multi-checkbox' && isMultiselectAvailableForItem) {
						updatedSelectState = !isSelected
						unselectOtherOptions = false
					}
					/** If selectBehavior 'single' is used, a click event should always result in this option being selected */
					if (props.selectBehavior === 'single' || !isMultiselectAvailableForItem) {
						updatedSelectState = true
						unselectOtherOptions = true
					}
					/** If selectBehavior 'single' is used, a click event should always result in this option being selected */
					if (props.selectBehavior === 'multi' && isMultiselectAvailableForItem) {
						/** If user shift+clicks, the selected state of this option should be toggled */
						if (evt.shiftKey || evt.ctrlKey || evt.metaKey) {
							updatedSelectState = !isSelected
							unselectOtherOptions = false
						} else {
							/** Otherwise, select this option and unselect all others */
							updatedSelectState = true
							unselectOtherOptions = true
						}
					}

					const nativeEvent = evt.nativeEvent as PointerEvent
					let pointerType: MultiColumnTableTypes.PointerType = 'mouse'
					if ('pointerType' in nativeEvent) {
						switch (nativeEvent.pointerType) {
							case 'touch':
								pointerType = 'touch'
								break
							case 'pen':
								pointerType = 'pen'
								break
							default:
							case 'mouse':
								pointerType = 'mouse'
								break
						}
					}

					updateSelectedOptions({ item, isSelected: updatedSelectState, unselectOtherOptions, pointerType })
				}}
			/>
		)
	}

	/** Function to call when a user finishes dragging an item in this table that is sortable */
	function handleSortableDragEnd(event: DragEndEvent) {
		const { active, over } = event
		let updatedItemRefs = _.cloneDeep(sortedItems)

		if (props.sortBehavior.sortMethod !== 'display-index') {
			return
		}
		if (!over) {
			return
		}

		if (active.id !== over.id) {
			/** Determine the old and new index of the item that was just moved */
			const oldIndex = updatedItemRefs.findIndex((itemRef) => String(itemRef[props.idKey]) === active.id)
			const newIndex = updatedItemRefs.findIndex((itemRef) => String(itemRef[props.idKey]) === over.id)

			/** Update the array of items to order them according to the new sort order  */
			updatedItemRefs = arrayMove(updatedItemRefs, oldIndex, newIndex)

			/** Update the 'display index' value of each item according to their new position in the array */
			updatedItemRefs.forEach((ref, index) => {
				if (props.sortBehavior.sortMethod !== 'display-index') {
					return
				}
				updatedItemRefs[index] = {
					...updatedItemRefs[index],
					[props.sortBehavior.displayIndexKey]: index,
				}
			})

			updatedItemRefs = sortItemsByDisplayOrder(updatedItemRefs)

			/** Commit the changes to local state and call the callback */
			setSortedItems(updatedItemRefs)
			updateOrderedItemIds(updatedItemRefs)
			props.sortBehavior.onUpdateSort(updatedItemRefs)
		}
	}

	/** Function to call when a user finishes dragging an item in this table that is drag-and-drop enabled */
	function handleDraggableDragEnd(event: DragEndEvent) {
		setActiveDragItem(null)
		if (event.over) {
			const dropTarget = event.over.data.current as T
			const dragItem = event.active.data.current as T
			props.dropBehavior?.handleDrop(dropTarget, [dragItem])
		}
	}

	/** ================================ */
	/** Render Component */

	/** If this is sortable, create component with DND kit wrapping the table body  */
	if (props.sortBehavior.sortMethod === 'display-index') {
		return (
			<div>
				<DndContext
					modifiers={[restrictToVerticalAxis, restrictToParentElement]}
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragEnd={handleSortableDragEnd}
				>
					<SortableContext items={orderedItemIds} strategy={verticalListSortingStrategy}>
						{sortedItems.map((item) => {
							return createItem(item, true, false)
						})}
					</SortableContext>
				</DndContext>
			</div>
		)
	}

	/** If this is droppable, create component with DND kit wrapping the table body  */
	if (props.dropBehavior) {
		return (
			<div>
				<DndContext
					modifiers={[restrictToVerticalAxis, restrictToParentElement]}
					sensors={sensors}
					onDragEnd={handleDraggableDragEnd}
					onDragStart={(evt) => {
						const activeItemProps = evt.active.data.current as T
						setActiveDragItem(activeItemProps)
					}}
				>
					<SortableContext items={orderedItemIds} strategy={verticalListSortingStrategy}>
						{sortedItems.map((item) => {
							return createItem(item, false, true)
						})}
					</SortableContext>
				</DndContext>
			</div>
		)
	}
	/** Otherwise, render the table with no extra functionality */
	return (
		<div>
			{props.items.map((item) => {
				return createItem(item, false, false)
			})}
		</div>
	)
}
