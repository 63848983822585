import { ListOption } from '@components/list-option/list-option'
import React from 'react'

import { ContextMenuTypes } from './context-menu.types'

export function ContextMenu__Inner(props: ContextMenuTypes.Component) {
	function getStyle(): React.CSSProperties {
		let style: React.CSSProperties = {}

		if (props.maxWidth) {
			style.maxWidth = `${props.maxWidth}px`
		}

		if (props.width) {
			switch (props.width) {
				case 'min':
					style.width = 'min-content'
					break
				default:
					style.width = `${props.width}px`
					break
			}
		} else {
			style.width = '300px'
		}

		if (props.style) {
			style = { ...style, ...props.style }
		}
		return style
	}

	return (
		<div className="context-menu__popover fs-unmask" style={getStyle()}>
			{props.header && <div className="bg-color__adjust-alpha-5 rel-p-2 text-bold">{props.header}</div>}
			<div className="p-5">
				{props.options.length === 0 && (
					<div className="flex flex-alignItems-center" style={{ height: '40px' }}>
						<em className="opacity-60 p-10">No options available</em>
					</div>
				)}
				{props.options.map((option, index) => {
					switch (option.type) {
						case 'divider':
							return (
								<div
									className="col-xs-12 border-bottom-thin"
									style={{ height: '3px', marginBottom: '3px' }}
									key={index}
								></div>
							)
						case 'option':
						case undefined:
							return (
								<ListOption
									icon={option.icon}
									hotkey={option.hotkey}
									disabled={option.disabled}
									key={index}
									onClick={() => {
										if (option.disabled) {
											return
										}
										option.onClick()
										props.onDismiss()
									}}
									style={{ fontWeight: '500' }}
									label={option.label}
								/>
							)
					}
					return <></>
				})}
			</div>
		</div>
	)
}
