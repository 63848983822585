import './avatar.scss'

import { Placement } from '@floating-ui/react'
import React, { ForwardedRef, useRef, useState } from 'react'

import { UsePopoverOnEvent } from '../../services/hooks/use-popover-on-event'
import { Popover } from '../popover/popover'
import { TooltipPopover } from '../tooltip/tooltip-popover'

export interface AvatarProps {
	className?: string
	size: AvatarSize
	tooltip?: string
	tooltipPlacement?: Placement
	id?: string
	onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void
	imageUrl?: string
	/** The letter to show in place of an image, when an image is not available */
	fallbackCharacter?: string
}

type AvatarSize = 'lg' | 'md' | 'sm'

export const Avatar = React.forwardRef((props: AvatarProps, externalRef: ForwardedRef<HTMLButtonElement>) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false)
	const popoverProps = UsePopoverOnEvent({
		isPopoverOpen: isPopoverOpen,
		setIsPopoverOpen: setIsPopoverOpen,
		delay: 250,
	})
	const internalRef = useRef<HTMLButtonElement>(null)
	const refToUse = externalRef && externalRef instanceof HTMLDivElement ? externalRef : internalRef.current

	/** ================================ */
	/** Methods */

	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`avatar`)

		if (props.className) {
			classes.push(props.className)
		}

		if (props.onClick) {
			classes.push(`is-clickable`)
		}

		switch (props.size) {
			case 'lg':
				classes.push(`is-lg`)
				break
			case 'md':
				classes.push(`is-md`)
				break
			case 'sm':
				classes.push(`is-sm`)
				break
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	/** ================================ */
	/** Render Component */

	return (
		<>
			<button
				ref={externalRef ? externalRef : internalRef}
				className={getClass()}
				onClick={(evt) => {
					if (props.onClick) {
						props.onClick(evt)
					}
				}}
				onMouseEnter={() => {
					setIsPopoverOpen(true)
				}}
				onMouseLeave={() => {
					setIsPopoverOpen(false)
				}}
				style={{
					backgroundImage: `url(${props.imageUrl})`,
				}}
			>
				{!props.imageUrl && props.fallbackCharacter && <>{props.fallbackCharacter}</>}
			</button>
			{refToUse instanceof HTMLElement && props.tooltip && (
				<Popover
					hideOnMouseOut={true}
					hideOnClickOutside={true}
					isScrimVisible={false}
					{...popoverProps}
					refElement={refToUse}
					setShowPopover={(newState) => {
						setIsPopoverOpen(newState)
					}}
					options={{ placement: props.tooltipPlacement }}
				>
					<TooltipPopover width={'min-content'}>
						<div className="col-xs-12 text-center">{props.tooltip}</div>
					</TooltipPopover>
				</Popover>
			)}
		</>
	)
})
