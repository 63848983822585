import { LoKationButton } from '@components/button/button'
import { Checkbox } from '@components/checkbox/checkbox'
import { ImageUploadTile } from '@components/image-upload-tile/image-upload-tile'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalFooter } from '@components/modal/modal-footer'
import { SpecialtySelectorDropdown } from '@components/specialty-selector-dropdown/specialty-selector-dropdown'
import { StateSelectorDropdown } from '@components/state-selector-dropdown/state-selector-dropdown'
import Switch from '@components/switch/switch'
import { TagSelectorDropdown } from '@components/tag-selector-dropdown/tag-selector-dropdown'
import { TextInput } from '@components/text-input/text-input'
import { store } from '@redux/store'
import { useEffect, useState } from 'react'
import { RequestBaseURL } from 'src/services/axios/http-common.service'
import { ToastService } from 'src/services/toast/toast.service'

import { AnalyticsService } from '../../../services/analytics/analytics.service'
import { ResourcesAPI } from '../../../services/resources/resources.api'
import { ResourceService } from '../../../services/resources/resources.service'
import { LokationResource } from '../../../services/resources/resources.types'
import { AdminItemEditorProps } from '../../../services/utility.types'
import { ValidationService } from '../../../services/validation/validation.service'
import { AdminEditorTitle, AdminEditorTitleProps } from '../components/editor-title'

export function EditResourceRoute(props: AdminItemEditorProps<LokationResource>) {
	const [modifiedResourceProps, setModifiedResourceProps] = useState<Partial<LokationResource>>({})
	const mergedResourceProps = getMergedFormProps()
	const [isStarred, setIsStarred] = useState(mergedResourceProps?.starred ?? false)
	const [isPublished, setIsPublished] = useState(mergedResourceProps?.published ?? false)
	const [resourcePicture, setResourcePicture] = useState<string | null>(null)

	useEffect(() => {
		if (props.item && props.item.hasResourcePicture) {
			setResourcePicture(RequestBaseURL + '/api/v1/resources/' + props.item.resourceId + '/resourcePicture')
		}
	}, [])

	function getMergedFormProps(): LokationResource | Omit<LokationResource, 'resourceId'> {
		let form = getOriginalResourceProps()
		Object.keys(modifiedResourceProps).forEach((key) => {
			if (typeof key === 'string') {
				// @ts-ignore, I'm not sure how to tell typescript that the keys are always going to be keys of the form :(
				form[key] = modifiedResourceProps[key]
			}
		})
		return form
	}

	function getOriginalResourceProps(): LokationResource | Omit<LokationResource, 'resourceId'> {
		return props.item ? props.item : newResourceProps()
	}

	function updateResourceProp(key: keyof LokationResource, value: any): void {
		const updatedState = { ...modifiedResourceProps }
		updatedState[key] = value
		setModifiedResourceProps(updatedState)
	}

	function newResourceProps(): Omit<LokationResource, 'resourceId'> {
		return {
			title: '',
			description: '',
			createdTimestamp: String(Date.now()),
			bgImage: '',
			tags: [],
			licensedStates: [],
			stateAgnostic: true,
			specialties: [],
			specialtyAgnostic: true,
			starred: false,
			url: '',
			hasResourcePicture: false,
			published: true,
		}
	}

	function createResource(): void {
		ResourcesAPI.createResource(mergedResourceProps).then((newResourceProps) => {
			AnalyticsService().pushEvent({
				event_category: 'change',
				event_label: 'create_resource',
				value: {
					resource_id: newResourceProps.data.resourceId,
					resource_title: newResourceProps.data.title,
				},
			})

			ToastService().create({ type: 'SUCCESS', body: `Resource has been created` })
			if (props.onCreate) {
				props.onCreate(newResourceProps.data)
				if (resourcePicture != null) {
					ResourcesAPI.updateResourcePicture(newResourceProps.data.resourceId, resourcePicture)
				}
			}
			props.dismissModal()
		})
	}

	function updateResource(): void {
		if ('resourceId' in mergedResourceProps) {
			const resourceId = mergedResourceProps.resourceId
			ResourcesAPI.updateResource(resourceId, modifiedResourceProps).then((updatedResourceRes) => {
				ToastService().create({ type: 'SUCCESS', body: `Resource has been updated` })
				if (props.onUpdate) {
					props.onUpdate(updatedResourceRes.data)
				}
				if (resourcePicture != null) {
					ResourcesAPI.updateResourcePicture(updatedResourceRes.data.resourceId, resourcePicture)
				}
				props.dismissModal()
			})
		}
	}

	function getEditorTitleProps(): AdminEditorTitleProps<LokationResource> {
		return {
			itemLabel: 'Resource',
			item: props.item,
			itemIdKey: 'resourceId',
			deleteItemFunction: ResourcesAPI.deleteResource,
			onDelete: (item) => {
				if (props.onDelete) {
					props.onDelete(item)
				}
				props.dismissModal()
			},
		}
	}

	const handlePinnedChange = () => {
		setIsStarred((prevState) => !prevState)
		updateResourceProp('starred', !isStarred)
	}

	const handlePublishedChange = () => {
		setIsPublished((prevState) => !prevState)
		updateResourceProp('published', !isPublished)
	}

	return (
		<Modal
			maxHeight={800}
			maxWidth={1000}
			fixedHeight={true}
			className="flex flex-column"
			onClose={props.dismissModal}
		>
			<>
				<AdminEditorTitle {...getEditorTitleProps()} />

				<ModalBody>
					<div className="flex flex-alignItems-center mb-20">
						<Switch className="mx-5" checked={isStarred} onChange={handlePinnedChange} />
						<span>
							<strong>{isStarred ? 'Pinned' : 'Unpinned'}</strong>
						</span>
					</div>

					<div className="flex flex-alignItems-center mb-20">
						<Switch className="mx-5" checked={isPublished} onChange={handlePublishedChange} />
						<span className="flex flex-alignItems-center flex-justifyContent-center">
							<strong>{isPublished ? 'Published' : 'Unpublished'}</strong>
						</span>
					</div>

					<TextInput
						label="Title"
						dataType="text"
						value={mergedResourceProps.title}
						width="100%"
						className="mb-20"
						onChange={(updatedValue) => {
							updateResourceProp('title', updatedValue)
						}}
					/>
					<TextInput
						label="Description"
						dataType="text"
						value={mergedResourceProps.description}
						width="100%"
						rows={3}
						className="mb-20"
						onChange={(updatedValue) => {
							updateResourceProp('description', updatedValue)
						}}
					/>

					<TextInput
						label="Resource URL"
						dataType="text"
						value={mergedResourceProps.url ? mergedResourceProps.url : ''}
						width="100%"
						className="mb-20"
						onChange={(updatedValue) => {
							updateResourceProp('url', updatedValue)
						}}
					/>

					<div className=" mb-20" style={{ gridColumn: '1 / span 2' }}>
						<strong>Background Image</strong>
						<ImageUploadTile
							className="bg-color__adjust-5"
							image={resourcePicture ? resourcePicture : ''}
							onUpload={(img) => {
								setResourcePicture(img)
								updateResourceProp('hasResourcePicture', true)
							}}
							onRemove={() => {
								setResourcePicture(null)
							}}
						/>
					</div>

					<TextInput
						label="Image URL"
						dataType="text"
						value={mergedResourceProps.bgImage}
						width="100%"
						className="mb-20"
						onChange={(updatedValue) => {
							updateResourceProp('bgImage', updatedValue)
						}}
						disabled={resourcePicture != null}
					/>

					<div className="mb-20 flex flex-column">
						<strong className="mb-5">Applicable States</strong>
						<Checkbox
							checked={mergedResourceProps.stateAgnostic}
							onChange={(checked) => {
								updateResourceProp('stateAgnostic', checked)
							}}
							style={{ marginBottom: '8px' }}
						>
							This is applicable to users in all states
						</Checkbox>
						<StateSelectorDropdown
							multiselect={true}
							selectedStates={mergedResourceProps.licensedStates}
							options={store.getState().licensedStates}
							onSelect={(selectedStates) => {
								updateResourceProp('licensedStates', selectedStates)
							}}
							disabled={mergedResourceProps.stateAgnostic}
						/>
					</div>
					<div className="mb-20 flex flex-column">
						<strong className="mb-5">Tags</strong>
						<TagSelectorDropdown
							selectedTags={mergedResourceProps.tags}
							options={store.getState().tags}
							onSelect={(selectedTags) => {
								updateResourceProp('tags', selectedTags)
							}}
						/>
					</div>

					<div className="mb-20 flex flex-column">
						<strong className="mb-5">Applicable Specialties</strong>
						<Checkbox
							checked={mergedResourceProps.specialtyAgnostic}
							onChange={(checked) => {
								updateResourceProp('specialtyAgnostic', checked)
							}}
							style={{ marginBottom: '8px' }}
						>
							This resource is not tied to any specialties
						</Checkbox>
						<SpecialtySelectorDropdown
							selectedSpecialties={mergedResourceProps.specialties}
							options={store.getState().specialties}
							onSelect={(selectedSpecialties) => {
								updateResourceProp('specialties', selectedSpecialties)
							}}
							disabled={mergedResourceProps.specialtyAgnostic}
						/>
					</div>
				</ModalBody>

				<ModalFooter>
					<LoKationButton
						variant="outlined"
						size="sm"
						primary={false}
						label="Cancel"
						className="mr-10"
						onClick={props.dismissModal}
					/>
					{props.item && (
						<LoKationButton
							variant="contained"
							size="sm"
							label="Update"
							onClick={() => {
								const validationResults = ResourceService.validate(mergedResourceProps)
								if (validationResults.isValid) {
									updateResource()
								} else {
									ValidationService.showValidationErrors(validationResults)
								}
							}}
						/>
					)}
					{!props.item && (
						<LoKationButton
							variant="contained"
							size="sm"
							label="Save"
							onClick={() => {
								const validationResults = ResourceService.validate(mergedResourceProps)
								if (validationResults.isValid) {
									createResource()
								} else {
									ValidationService.showValidationErrors(validationResults)
								}
							}}
						/>
					)}
				</ModalFooter>
			</>
		</Modal>
	)
}
