import { MultiColumnTableInner } from './multi-column-table'
import { MultiColumnTableTypes } from './multi-column-table.types'
import { MultiColumnTableProvider } from './state/multi-column-table__state'

export function MultiColumnTable<T extends Object>(props: MultiColumnTableTypes.Component<T>) {
	return (
		<MultiColumnTableProvider tableProps={props}>
			<MultiColumnTableInner {...props} />
		</MultiColumnTableProvider>
	)
}
