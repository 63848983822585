import { Paper } from '@components/paper/paper'

export function SupportRouteSouthCarolina() {
	return (
		<>
			<h2 className="mb-20">South Carolina</h2>
			<Paper bgColor="white" padding={['all']}>
				<h5>Department Email Contacts</h5>
				<table className="lokation__table mb-20" style={{ width: '100%', maxWidth: '1000px' }}>
					<thead>
						<tr>
							<td>Department</td>
							<td>Email</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Compliance / Support</td>
							<td>romany@lokationre.com / doris@lokationre.com</td>
						</tr>
						<tr>
							<td>Broker Specific Needs</td>
							<td>doris@lokationre.com</td>
						</tr>
						<tr>
							<td>Document Submission</td>
							<td>docs+sc@lokationre.com</td>
						</tr>
						<tr>
							<td>Commission / Payments</td>
							<td>payments@lokationre.com</td>
						</tr>
						<tr>
							<td>Billing Inquiries</td>
							<td>billing@lokationre.com</td>
						</tr>
						<tr>
							<td>The Mortgage Center</td>
							<td>mortgage@lokationre.com</td>
						</tr>
						<tr>
							<td>Programs</td>
							<td>programs@lokationre.com</td>
						</tr>
					</tbody>
				</table>
				<h5 className="mb-10">Support</h5>
				<div>Phone: (803) 400-6216</div>
				<div>
					Set an Appointment with an Online Calendar for Agents:
					https://appt.link/dir-of-operations-se-region-ga-al-sc
				</div>
				<div>Live Zoom Room for Support questions from 9 AM-5 PM M-F: http://www.lokationsupport.com</div>

				<h5 className="mt-20 mb-10">South Carolina Office</h5>
				<div>1320 Main Street, Suite 300</div>
				<div>Columbia, SC 29201</div>
				<div>(803) 400-6216</div>
				<div>www.lokationre.com | info@lokationre.com</div>
			</Paper>
		</>
	)
}
