/* eslint-disable react/jsx-pascal-case */
import { MultiColumnTableTypes } from '@components/multi-column-table/multi-column-table.types'
import { MultiColumnTable } from '@components/multi-column-table/multi-column-table__wrapper'
import { Typography } from '@components/text/text'
import { useOutletContext } from 'react-router-dom'
import { TransactionMgmtTypes } from 'src/services/transaction-mgmt/transaction-mgmt.types'

export function TransactionMgmt__Transaction__Tasks__Route() {
	const data = useOutletContext() as TransactionMgmtTypes.TransactionTypes.Transaction

	function getTableColumns(): MultiColumnTableTypes.Column<TransactionMgmtTypes.TaskTypes.Task>[] {
		const columns: MultiColumnTableTypes.Column<TransactionMgmtTypes.TaskTypes.Task>[] = []

		columns.push({
			label: `Name`,
			value: `name`,
			columnId: `name`,
			width: `fill`,
			displayIndex: 0,
			visible: true,
		})

		columns.push({
			label: `Due Date`,
			value: `dueDate`,
			columnId: `dueDate`,
			width: 200,
			displayIndex: 1,
			visible: true,
		})

		columns.push({
			label: `Status`,
			value: (task) => {
				const areAllDocsComplete = task.requiredDocs.every((doc) => doc.status === 'APPROVED')
				if (areAllDocsComplete) {
					return `Complete`
				} else {
					return `Incomplete`
				}
			},
			columnId: `status`,
			width: 200,
			displayIndex: 2,
			visible: true,
		})

		return columns
	}

	return (
		<div className="col-xs-12" style={{ maxWidth: '1200px' }}>
			<Typography type="h1" margins={['bottom']}>
				Tasks and Notifications
			</Typography>

			<MultiColumnTable<TransactionMgmtTypes.TaskTypes.Task>
				columns={getTableColumns()}
				idKey={`taskId`}
				className="col-xs-12"
				items={data.tasks}
				isConfigurable={false}
				onSelect={(transaction) => {
					/** empty */
				}}
				onDoubleClick={(transaction) => {
					/** empty */
				}}
				selectBehavior={'single'}
				selectedItems={[]}
				sortBehavior={{
					sortMethod: 'column-value',
					columnId: 'name',
				}}
				tableId={''}
			/>
		</div>
	)
}
