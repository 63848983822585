import './multi-column-card.scss'

import { Checkbox } from '@components/checkbox/checkbox'
import React, { forwardRef } from 'react'

import { MultiColumnCardTypes } from './multi-column-card.types'

/** Renders a card. Typically used when rendering a list of selectable elements */
export const MultiColumnCard = forwardRef(
	(props: MultiColumnCardTypes.Component, externalRef: React.ForwardedRef<HTMLDivElement>) => {
		/** =================================== */
		/** Methods */

		function getClass(): string {
			const classes: string[] = []
			let classString = ''

			classes.push(`fs-unmask multi-column-card__component`)

			if (props.onClick) {
				classes.push(`is-interactive`)
			}

			if (props.isSelected) {
				classes.push(`is-selected`)
			}

			switch (props.size) {
				case 'md':
					classes.push(`size-md`)
					break
				case 'sm':
					classes.push(`size-sm`)
					break
			}

			if (props.disabled) {
				classes.push(`is-disabled`)
			}

			if (props.className) {
				classes.push(props.className)
			}

			classes.forEach((thisClass) => {
				classString += `${thisClass} `
			})

			return classString
		}

		function renderItemInterior(): React.ReactNode {
			return (
				<>
					{props.includeCheckbox && typeof props.isSelected === 'boolean' && (
						<Checkbox checked={props.isSelected} style={{ pointerEvents: 'none' }} className="mr-20" />
					)}
					{props.children}
				</>
			)
		}

		/** =================================== */
		/** Render Component */

		return (
			<div
				tabIndex={0}
				style={props.style}
				ref={externalRef}
				className={getClass()}
				onClick={props.onClick}
				onDoubleClick={(evt) => {
					if (props.onDoubleClick) {
						props.onDoubleClick(evt)
					}
				}}
				onMouseDown={(evt) => {
					if (props.onMouseDown) {
						props.onMouseDown(evt)
					}
				}}
				onKeyDown={(evt) => {
					if (evt.key === 'Enter' && props.onClick) {
						props.onClick(evt)
					}
				}}
			>
				{renderItemInterior()}
			</div>
		)
	},
)
