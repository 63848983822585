import { useCallback, useLayoutEffect, useState } from 'react'

/**
 * Returns the height and width of a HTML element, uses the `ResizeObserver` api if available to detect changes to the
 * size. Falls back to listening for resize events on the window.
 *
 * @param ref A ref to the HTML element to be measured
 */
export const useComponentSize = (ref: React.RefObject<HTMLElement>) => {
	const [size, setSize] = useState(getSize(ref.current))

	const resizeHandler = useCallback(() => {
		if (ref.current) {
			setSize(getSize(ref.current))
		}
	}, [ref])

	useLayoutEffect(() => {
		const { ResizeObserver } = window

		if (!ref.current) {
			return
		}
		resizeHandler()

		if (typeof ResizeObserver === 'function') {
			const resizeObserver = new ResizeObserver(resizeHandler)
			resizeObserver.observe(ref.current)

			return () => resizeObserver.disconnect()
		} else {
			window.addEventListener('resize', resizeHandler)

			return () => window.removeEventListener('resize', resizeHandler)
		}
	}, [ref, resizeHandler])

	return size
}

/**
 * Gets the height and width of the provided HTML element
 *
 * @param element The html element to measure
 */
export function getSize(element?: HTMLElement | null) {
	if (!element) {
		return { height: 0, width: 0 }
	}

	return {
		height: element.offsetHeight,
		width: element.offsetWidth,
	}
}
