import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { Modal } from '@components/modal/modal'
import Switch from '@components/switch/switch'
import { useEffect, useState } from 'react'
import { ToastService } from 'src/services/toast/toast.service'

import { AgentContractCard } from '../../../../../components/agent-contract-card/agent-contract-card'
import { AgentContractSignatureCard } from '../../../../../components/agent-contract-signature-card/agent-contract-signature-card'
import { AgentLicenseCard } from '../../../../../components/agent-license-card/agent-license-card'
import { LoKationButton } from '../../../../../components/button/button'
import { CollapsibleContent } from '../../../../../components/collapsible-content/collapsible-content'
import { ModalBody } from '../../../../../components/modal/modal-body'
import { ModalFooter } from '../../../../../components/modal/modal-footer'
import { ModalHeader } from '../../../../../components/modal/modal-header'
import { Paper } from '../../../../../components/paper/paper'
import { StripeAPI } from '../../../../../services/stripe/stripe.api'
import { LicenseInformationAPI } from '../../../../../services/user/license-information.api'
import { AgentLicense, EndUserProps } from '../../../../../services/user/user.types'
import { RegistrationService } from '../../../../registration/registration.service'
import { useUserProfileDispatch } from '../../state/user-profile__state'
import { UserProfileService } from '../../user-profile.service'
import ManagePaymentMethods from '../billing-components/manage-payment-methods'

interface AddNewAgentLicenseModalProps {
	dismissModal: () => void
	canUserEdit: boolean
	userToEdit: EndUserProps
	onAddLicense: (license: AgentLicense.CompleteEntry) => void
	onUpdateLicense: (license: AgentLicense.CompleteEntry) => void
	existingLicenses: AgentLicense.Entry[]
	currentUser: EndUserProps | null
	licenseToEdit?: number
	rejoin: boolean
}

interface GetPriceIdsResponse {
	priceIds: string[]
	futurePriceIds: string[]
}

type AddNewLicenseSections = 'PLAN' | 'LICENSE' | 'AGREEMENT' | 'PAYMENT' | 'PURCHASE' | 'NONE'

export function AddNewAgentLicenseModal(props: AddNewAgentLicenseModalProps) {
	const userProfileDispatch = useUserProfileDispatch()

	const [submitted, setSubmitted] = useState(false)
	const [licenseWasUpdated, setLicenseWasUpdated] = useState(false)
	const [subscriptionLoading, setSubscriptionLoading] = useState(false)
	const [openCollapsible, setOpenCollapsible] = useState<AddNewLicenseSections>('LICENSE')
	const [newLicense, setNewLicense] = useState<AgentLicense.Entry>(getDefaultLicenseProps())
	const [disableFields, setDisableFields] = useState<boolean>(false)
	const [hasUserClickedNavButton, setHasUserClickedNavButton] = useState(false)
	let showSignatureError = false
	const [isAgreementChecked, setIsAgreementChecked] = useState(false)
	const [signature, setSignature] = useState('')
	const [oldPlan, setOldPlan] = useState<AgentLicense.PlanType | null>(null)
	const [quarterlyFees, setQuarterlyFees] = useState(0)
	const [existingQuarterlyFees, setExistingQuarterlyFees] = useState(0)
	const [prorationCost, setProrationCost] = useState(0)
	const hasBluePlan = getUserHasBluePlan()
	const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<string | undefined>('')
	const [newDefaultPaymentMethod, setNewDefaultPaymentMethod] = useState<string | undefined>('')
	const [isUserAdmin, setIsUserAdmin] = useState(false)
	const [billCustomer, setBillCustomer] = useState(true)

	let endUserId: number | undefined

	/** =================================== */
	/** Effect */

	useEffect(() => {
		if (props.currentUser && props.userToEdit) {
			const isAdminEditing = props.currentUser.endUserId !== props.userToEdit.endUserId
			if (isAdminEditing) {
				endUserId = props.userToEdit.endUserId
			}
		}

		if (props.currentUser) {
			const isAdminCheck = props.currentUser.roles.some((roles) => roles.roleType === 'SUPER_ADMIN')
			if (isAdminCheck) {
				setIsUserAdmin(true)
			}
		}

		if (props.currentUser && props.userToEdit) {
			const isUserBeingEdited = props.currentUser.endUserId !== props.userToEdit.endUserId
			if (isUserBeingEdited) {
				endUserId = props.userToEdit.endUserId
			} else {
				fetchUserDefaultPaymentMethod()
			}
		}
	}, [])

	useEffect(() => {
		// Check if admin is accessing user's profile to add license - fetch correct default payment method
		if (endUserId) {
			console.log('endUserId')
			fetchUserDefaultPaymentMethod(endUserId)
		}
	}, [endUserId])

	useEffect(() => {
		const matchingLicense = props.existingLicenses.find(
			(license) => license.licenseInformationId === props.licenseToEdit,
		)
		if (matchingLicense) {
			setOldPlan(matchingLicense.plan)
			setDisableFields(true)
			setNewLicense({
				licenseInformationId: matchingLicense.licenseInformationId,
				licenseNumber: matchingLicense.licenseNumber,
				licensedState: matchingLicense.licensedState,
				ppUserId: matchingLicense.ppUserId,
				region: matchingLicense.region,
				endUser: props.userToEdit,
				plan: matchingLicense.plan,
				licenseAgreement: matchingLicense.licenseAgreement,
			})
		} else {
			setDisableFields(false)
			setNewLicense(getDefaultLicenseProps())
		}
	}, [props.existingLicenses, props.licenseToEdit, props.userToEdit])

	useEffect(() => {
		if (newLicense) {
			const combinedLicenses = [
				...(props.existingLicenses as AgentLicense.Entry[]),
				newLicense as AgentLicense.Entry,
			]
			calculateTotalComplianceFees(combinedLicenses as AgentLicense.Entry[])
			calculateExistingComplianceFees(props.existingLicenses as AgentLicense.Entry[])
		}
	}, [openCollapsible])

	useEffect(() => {
		calculateCompliaceFeeDifference(existingQuarterlyFees, quarterlyFees)
	}, [quarterlyFees])

	/** =================================== */
	/** Methods */

	function getDefaultLicenseProps(): AgentLicense.Entry {
		const defaultPlan = getUserHasBluePlan() ? 'BLUE' : null
		return {
			licenseInformationId: -1,
			licenseNumber: '',
			licensedState: null,
			ppUserId: '',
			region: null,
			endUser: props.userToEdit,
			plan: defaultPlan,
			licenseAgreement: null,
		}
	}

	/** Checks to see whether the user has any other licenses with a 'BLUE' plan */
	function getUserHasBluePlan(): boolean {
		if (!props.existingLicenses) {
			return false
		}

		return props.existingLicenses.some((license) => {
			return license.plan === 'BLUE' && license.licenseInformationId > 0
		})
	}

	/** Changes which step within the modal is currently open */
	function toggleCollapsible(type: AddNewLicenseSections) {
		setOpenCollapsible((prevState) => (prevState === type ? 'NONE' : type))
	}

	function calculatePriceIds(license: AgentLicense.Entry): GetPriceIdsResponse {
		const planPriceId = RegistrationService()
			.getLicensePlans()
			.find(
				(licensePlan) =>
					licensePlan.plan === license.plan && licensePlan.state === license.licensedState?.abbreviation,
			)?.recurringPriceId

		const complianceFeePriceId = RegistrationService()
			.getLicensePlans()
			.find(
				(licensePlan) =>
					licensePlan.plan === license.plan && licensePlan.state === license.licensedState?.abbreviation,
			)?.complianceFeeRecurringPriceId

		const priceIds: string[] = []
		const futurePriceIds: string[] = []

		if (planPriceId) {
			priceIds.push(...planPriceId)
		}
		if (complianceFeePriceId) {
			futurePriceIds.push(...complianceFeePriceId)
		}

		return { priceIds, futurePriceIds }
	}

	function getUTCTimeMinus10Hours() {
		const nowUTC = new Date()
		const tenHoursInMilliseconds = 10 * 60 * 60 * 1000 // 10 hours in milliseconds
		return new Date(nowUTC.getTime() - tenHoursInMilliseconds)
	}
	function calculateCompliaceFeeDifference(oldFees: number, totalFees: number) {
		const feeDiff = 50 - oldFees

		if (feeDiff > 0) {
			const startDateUTC = getUTCTimeMinus10Hours()

			const billingDates = [
				new Date(Date.UTC(startDateUTC.getUTCFullYear(), 0, 15)),
				new Date(Date.UTC(startDateUTC.getUTCFullYear(), 3, 15)),
				new Date(Date.UTC(startDateUTC.getUTCFullYear(), 6, 15)),
				new Date(Date.UTC(startDateUTC.getUTCFullYear(), 9, 15)),
			]
			// Find the next billing date
			let nextBillingDate = billingDates.find((date) => date > startDateUTC)
			if (!nextBillingDate) {
				// If there's no next billing date this year, use next year
				nextBillingDate = new Date(Date.UTC(startDateUTC.getUTCFullYear() + 1, 0, 15))
			}
			const oneDay = 24 * 60 * 60 * 1000 // milliseconds in a day
			const daysUntilNextBilling = (nextBillingDate.getTime() - startDateUTC.getTime()) / oneDay
			const daysInQuarter = 365 / 4 // Approximate days in a quarter
			const proration = Number(((feeDiff / daysInQuarter) * daysUntilNextBilling).toFixed(2))
			setProrationCost(proration)
		}
	}

	function calculateExistingComplianceFees(licenses: AgentLicense.Entry[]) {
		let totalFees = 0
		licenses.forEach((license) => {
			const complianceFee = RegistrationService()
				.getLicensePlans()
				.find(
					(licensePlan) =>
						licensePlan.plan === license.plan && licensePlan.state === license.licensedState?.abbreviation,
				)?.complianceFeeRecurringCost
			if (complianceFee) {
				totalFees += complianceFee
			}
		})
		setExistingQuarterlyFees(totalFees)
	}

	function calculateTotalComplianceFees(licenses: AgentLicense.Entry[]) {
		let totalFees = 0
		licenses.forEach((license) => {
			const complianceFee = RegistrationService()
				.getLicensePlans()
				.find(
					(licensePlan) =>
						licensePlan.plan === license.plan && licensePlan.state === license.licensedState?.abbreviation,
				)?.complianceFeeRecurringCost

			if (complianceFee) {
				totalFees += complianceFee
			}
		})

		setQuarterlyFees(totalFees)
	}

	async function runSubscription() {
		if (!newLicense.licensedState) {
			throw new Error('Licensed state is not set')
		}

		if (!billCustomer) {
			setSubmitted(true)
			return
		}

		const { priceIds, futurePriceIds } = calculatePriceIds(newLicense)
		if (priceIds.length > 0) {
			if (endUserId) {
				await StripeAPI.createNewSubscription(priceIds, futurePriceIds, endUserId)
			} else {
				await StripeAPI.createNewSubscription(priceIds, futurePriceIds)
			}

			console.log('Subscription was created')
			setSubmitted(true)
		} else {
			throw new Error('No price IDs to create a subscription')
		}
	}

	function getIsLicenseValid(): boolean {
		const { priceIds } = calculatePriceIds(newLicense)
		console.log(priceIds)

		/** Validate whether the license is ready to be submitted or not */
		if (!signature) {
			ToastService().create({
				type: 'ERROR',
				body: `You must sign the agreement`,
			})
			setSubscriptionLoading(false)
			return false
		}
		if (!isAgreementChecked) {
			ToastService().create({
				type: 'ERROR',
				body: `You must agree to the terms on the agreement`,
			})
			setSubscriptionLoading(false)
			return false
		}
		if (!UserProfileService().validateLicenses([newLicense])) {
			ToastService().create({
				type: 'ERROR',
				body: `Your licenses are not valid`,
			})
			setSubscriptionLoading(false)
			return false
		}
		if (
			props.existingLicenses?.some(
				(license) => license.licensedState?.licensedStateId === newLicense.licensedState?.licensedStateId,
			) &&
			newLicense.licenseInformationId < 0
		) {
			ToastService().create({
				type: 'ERROR',
				body: `You cannot have multiple licenses within the same state`,
			})
			setSubscriptionLoading(false)
			return false
		}

		if (disableFields && oldPlan === newLicense.plan && !props.rejoin) {
			ToastService().create({
				type: 'ERROR',
				body: `Your plan must change`,
			})
			setSubscriptionLoading(false)
			return false
		}

		if (!newDefaultPaymentMethod && !defaultPaymentMethod && billCustomer) {
			ToastService().create({
				type: 'ERROR',
				body: `You must select a payment method`,
			})
			setSubscriptionLoading(false)
			return false
		}

		return true
	}

	async function submitNewLicense(): Promise<void> {
		return new Promise(async (resolve, reject) => {
			setSubscriptionLoading(true)

			const isLicenseValid = getIsLicenseValid()

			if (!isLicenseValid) {
				reject()
				return
			}

			try {
				const completeLicense = newLicense as AgentLicense.CompleteEntry
				const licenseWithContract = await UserProfileService().attachContractToLicense(
					completeLicense,
					signature,
					props.userToEdit,
				)

				if (disableFields) {
					LicenseInformationAPI.deleteLicense(
						props.userToEdit.endUserId,
						licenseWithContract.licenseInformationId,
					)
					userProfileDispatch({ type: 'remove-license', payload: licenseWithContract.licenseInformationId })
					const randomNegativeNumber = Math.floor(Math.random() * -1000)
					licenseWithContract.licenseInformationId = randomNegativeNumber
					setLicenseWasUpdated(true)
				}

				const addedLicense = await LicenseInformationAPI.addLicense(
					props.userToEdit.endUserId,
					licenseWithContract,
				)
				const formattedAddedLicense = addedLicense.data as AgentLicense.CompleteEntry
				await runSubscription()
				props.onAddLicense(formattedAddedLicense)
				resolve()
			} catch (error) {
				console.error('Error in submitNewLicense: ', error)
				reject()
			}
		})
	}

	// Function to handle payment method change
	const handlePaymentMethodChange = (paymentMethodId: string) => {
		console.log('handlePaymentMethodChange triggered')
		if (paymentMethodId !== 'add-new') {
			setNewDefaultPaymentMethod(paymentMethodId)
			console.log('PMID Before API Call: ', paymentMethodId)
			StripeAPI.setDefaultPaymentMethod(paymentMethodId, endUserId)
				.then((res) => {
					console.log('new payment method set as default')
				})
				.catch((error) => {
					console.log('error setting default', error)
				})
		}
	}

	function fetchUserDefaultPaymentMethod(endUserId?: number) {
		console.log('End User ID: ', endUserId)
		StripeAPI.fetchCustomerDefaultPaymentMethod(endUserId)
			.then((res) => {
				console.log('Results: ', res)
				setDefaultPaymentMethod(res.id)
			})
			.catch((error) => {
				setDefaultPaymentMethod('')
				console.log('Error fetching default payment method:', error)
				return null
			})
	}

	const handleBillCustomerSwitchChange = () => {
		setBillCustomer((prevState) => !prevState)
	}

	/** =================================== */
	/** Render Component */

	if (!props.userToEdit) {
		return <>ERROR</>
	}

	return (
		<Modal
			onClose={props.dismissModal}
			maxWidth={800}
			maxHeight={850}
			fixedHeight={false}
			className="overflow-y__scroll"
		>
			<ModalHeader
				title={
					submitted
						? licenseWasUpdated
							? 'Your license has been updated!'
							: 'Your new license has been added!'
						: `${oldPlan ? 'Your Plan Change Information' : 'Add your new license information'} `
				}
			>
				<></>
			</ModalHeader>
			<ModalBody>
				<div className="col-12 flex flex-wrap pb-20">
					{submitted && !licenseWasUpdated && (
						<div>
							Thank you for subscribing to a LoKation license plan! You will receive a confirmation email
							shortly.
						</div>
					)}

					{submitted && licenseWasUpdated && !props.rejoin && (
						<div>
							Thank you for updating your LoKation license plan! You will receive a confirmation email
							shortly.
						</div>
					)}

					{submitted && licenseWasUpdated && props.rejoin && (
						<div>Thank you for re-activating your LoKation account - we're glad to have you back!</div>
					)}

					{!submitted && (
						<>
							<Paper
								bgColor="primary"
								padding={['all']}
								marginSize="section"
								margins={['bottom']}
								style={{ width: '100%' }}
							>
								<CollapsibleContent
									label={'Add your license info'}
									state={openCollapsible === 'LICENSE' ? 'OPEN' : 'CLOSED'}
									setState={() => toggleCollapsible('LICENSE')}
									className="mt-10 px-10"
								>
									<>
										<AgentLicenseCard
											license={newLicense}
											canUserEdit={true}
											userToEdit={props.userToEdit}
											paymentFlow={true}
											updateFlow={disableFields}
											onUpdateLicense={(license) => {
												setNewLicense(license)
											}}
											onDeleteLicense={() => {
												/** empty */
											}}
											userEditPlan={() => {
												/** empty */
											}}
											rejoin={props.rejoin}
										/>

										<div className="flex flex-justifyContent-end">
											<LoKationButton
												icon={'plus'}
												size="sm"
												variant={'outlined'}
												label="Next"
												className="mt-10"
												onClick={() => {
													setOpenCollapsible('AGREEMENT')
												}}
											/>
										</div>
									</>
								</CollapsibleContent>
							</Paper>

							<Paper
								bgColor="primary"
								padding={['all']}
								marginSize="section"
								margins={['bottom']}
								style={{ width: '100%' }}
							>
								<CollapsibleContent
									label={'Sign your License Agreement'}
									state={openCollapsible === 'AGREEMENT' ? 'OPEN' : 'CLOSED'}
									setState={() => toggleCollapsible('AGREEMENT')}
									className="mt-10 px-10"
								>
									<>
										<div>
											<AgentContractCard
												license={newLicense}
												firstName={props.userToEdit.firstName}
												lastName={props.userToEdit.lastName}
												signature={signature}
												hasUserClickedNavButton={hasUserClickedNavButton}
												hasAgreed={isAgreementChecked}
												onChange={(newState) => {
													setIsAgreementChecked(newState)
												}}
											/>
										</div>

										<AgentContractSignatureCard
											firstName={props.userToEdit.firstName}
											lastName={props.userToEdit.lastName}
											hasUserClickedNavButton={hasUserClickedNavButton}
											signature={signature}
											onChange={(newState) => {
												setSignature(newState)
											}}
											showSignatureError={showSignatureError}
										/>

										<div className="flex flex-justifyContent-end">
											<LoKationButton
												icon={'plus'}
												size="sm"
												variant={'outlined'}
												label="Proceed to Payment"
												className="mt-10"
												onClick={() => {
													setHasUserClickedNavButton(true)
													setOpenCollapsible('PAYMENT')
												}}
											/>
										</div>
									</>
								</CollapsibleContent>
							</Paper>

							<Paper
								bgColor="primary"
								padding={['all']}
								marginSize="section"
								margins={['bottom']}
								style={{ width: '100%' }}
							>
								<CollapsibleContent
									label={'Choose your payment method'}
									state={openCollapsible === 'PAYMENT' ? 'OPEN' : 'CLOSED'}
									setState={() => toggleCollapsible('PAYMENT')}
									className="mt-10 px-10"
								>
									<>
										<div>
											{isUserAdmin && (
												<>
													<div className="flex flex-alignItems-center mb-20">
														<strong>Should the customer be billed?</strong>
														<Switch
															className="mx-5"
															checked={billCustomer}
															onChange={handleBillCustomerSwitchChange}
														/>
														<span>{billCustomer ? 'Yes' : 'No'}</span>
													</div>
													<p>
														Note: If this is set to "Yes", then the customer's payment
														method will be charged and a subscription will be created in
														Stripe
													</p>
												</>
											)}
											{billCustomer && (
												<ManagePaymentMethods
													key={defaultPaymentMethod}
													defaultPaymentMethod={defaultPaymentMethod}
													onPaymentMethodChange={handlePaymentMethodChange}
													endUserId={endUserId}
												/>
											)}
										</div>

										<div className="flex flex-justifyContent-end">
											<LoKationButton
												icon={'plus'}
												size="sm"
												variant={'outlined'}
												label="Next"
												className="mt-10"
												onClick={() => {
													setHasUserClickedNavButton(true)
													setOpenCollapsible('PURCHASE')
												}}
												// disabled={!newDefaultPaymentMethod && !defaultPaymentMethod}
												disabled={
													!newDefaultPaymentMethod && !defaultPaymentMethod && billCustomer
												}
											/>
										</div>
									</>
								</CollapsibleContent>
							</Paper>

							<Paper
								bgColor="primary"
								padding={['all']}
								marginSize="section"
								margins={['bottom']}
								style={{ width: '100%' }}
							>
								<CollapsibleContent
									label={'Confirm your subscription'}
									state={openCollapsible === 'PURCHASE' ? 'OPEN' : 'CLOSED'}
									setState={() => toggleCollapsible('PURCHASE')}
									className="mt-10 px-10"
								>
									<>
										{!submitted && subscriptionLoading ? (
											<GenericContentLoader height={200} width={'fill'} />
										) : (
											<div className="p-20 mt-10">
												<>
													{isUserAdmin && !billCustomer ? (
														<>
															<div className="col-xs-12">
																<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween border-bottom-thin py-10">
																	<div>
																		<strong>
																			{newLicense.licensedState?.stateName}{' '}
																			{newLicense.plan} Plan
																		</strong>
																	</div>
																	{newLicense.plan === 'BLUE' && (
																		<div>
																			<em>$0/month</em>
																		</div>
																	)}
																	{newLicense.plan === 'SILVER' && (
																		<div>
																			<em>$0/year</em>
																		</div>
																	)}
																</div>
																{newLicense.plan === 'BLUE' && (
																	<div className="flex flex-column">
																		<div className="flex flex-justifyContent-end col-xs-12 border-bottom-thin py-10">
																			<strong className="mr-10">
																				Monthly cost:
																			</strong>{' '}
																			<div className="cost-num">$0</div>
																		</div>
																	</div>
																)}
																{newLicense.plan === 'SILVER' && (
																	<div className="flex flex-column">
																		<div className="flex flex-justifyContent-end col-xs-12 border-bottom-thin py-10">
																			<strong className="mr-10">
																				Yearly cost:
																			</strong>{' '}
																			<div className="cost-num">$0</div>
																		</div>
																	</div>
																)}
															</div>

															<div className="flex flex-justifyContent-end col-xs-12 border-bottom-thin py-10">
																<div className="mr-10">
																	<strong>Quarterly Compliance Fee:</strong>
																</div>{' '}
																<div className="cost-num">$0</div>
															</div>

															<div className="flex flex-justifyContent-end col-xs-12 border-bottom-thin py-10">
																<div className="mr-10">
																	<strong>
																		Customer will not be billed for this transaction
																	</strong>
																</div>
															</div>
														</>
													) : (
														<>
															<div className="col-xs-12">
																<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween py-10">
																	<div>
																		<strong>
																			{newLicense.licensedState?.stateName}{' '}
																			{newLicense.plan} Plan
																		</strong>
																	</div>
																	{newLicense.plan === 'BLUE' && (
																		<div>
																			<em>{hasBluePlan ? '$20' : '$99'}/month</em>
																		</div>
																	)}
																	{newLicense.plan === 'SILVER' && (
																		<div>
																			<em>$99/year</em>
																		</div>
																	)}
																</div>
																<div className="flex flex-alignItems-center flex-justifyContent-spaceBetween border-bottom-thin pb-10">
																	<div>
																		<strong>
																			Quarterly Compliance Fee (this includes all
																			licenses):
																		</strong>
																	</div>
																	<div className="cost-num">
																		{quarterlyFees > 50
																			? '$50'
																			: '$' + quarterlyFees}
																	</div>
																</div>

																{newLicense.plan === 'BLUE' && (
																	<div className="flex flex-column">
																		<div className="flex flex-justifyContent-end col-xs-12 border-bottom-thin py-10">
																			<strong className="mr-10">
																				Monthly cost:
																			</strong>{' '}
																			<div className="cost-num">
																				{hasBluePlan ? '$20' : '$99'}
																			</div>
																		</div>
																	</div>
																)}
																{newLicense.plan === 'SILVER' && (
																	<div className="flex flex-column">
																		<div className="flex flex-justifyContent-end col-xs-12 border-bottom-thin py-10">
																			<strong className="mr-10">
																				Yearly cost:
																			</strong>{' '}
																			<div className="cost-num">$99</div>
																		</div>
																	</div>
																)}
															</div>

															<div className="flex flex-justifyContent-end col-xs-12 border-bottom-thin py-10">
																<div className="mr-10">
																	<strong>Prorated Quarterly Compliance Fee:</strong>
																</div>{' '}
																<div className="cost-num">{'$' + prorationCost}</div>
															</div>

															<div className="flex flex-justifyContent-end py-10">
																<strong className="mr-10">Total due:</strong>{' '}
																<div className="cost-num">
																	{newLicense.plan === 'SILVER'
																		? '$' + (99 + prorationCost)
																		: newLicense.plan === 'BLUE'
																			? hasBluePlan
																				? '$' + (20 + prorationCost).toFixed(2)
																				: '$' + (99 + prorationCost).toFixed(2)
																			: 'N/A'}
																</div>
															</div>

															<div
																className="flex flex-justifyContent-end col-xs-12  py-10"
																style={{ maxWidth: '860px' }}
															>
																<em>
																	* The quarterly compliance fee is billed on January
																	15, April 15, July 15, and October 15. If you join
																	the company mid-quarter, the first charge is
																	prorated based on the remaining days until the next
																	billing date.
																</em>{' '}
															</div>
														</>
													)}
												</>
											</div>
										)}

										<div className="flex flex-justifyContent-end">
											<div className="flex flex-alignItems-center">
												<LoKationButton
													size="sm"
													variant={'outlined'}
													className="mt-10"
													label={
														!submitted && subscriptionLoading
															? 'Completing Purchase...'
															: 'Complete Purchase'
													}
													disabled={!submitted && subscriptionLoading}
													onClick={submitNewLicense}
												/>
											</div>
										</div>
									</>
								</CollapsibleContent>
							</Paper>
						</>
					)}
				</div>
			</ModalBody>
			<ModalFooter>
				{submitted && !props.rejoin && (
					<LoKationButton
						icon={'plus'}
						size="sm"
						variant={'outlined'}
						label="Close"
						className="mt-10"
						onClick={() => {
							props.dismissModal()
						}}
					/>
				)}
				{submitted && props.rejoin && (
					<LoKationButton
						icon={'plus'}
						size="sm"
						variant={'outlined'}
						label="Go To The Sphere"
						className="mt-10"
						onClick={() => {
							props.dismissModal()
						}}
					/>
				)}
			</ModalFooter>
		</Modal>
	)
}
