import './collapsible-content.scss'

import { Typography } from '@components/text/text'

import { Caret } from '../caret/caret'

export interface CollapsibleContentProps {
	state: CollapsibleContentStates
	setState: (newState: CollapsibleContentStates) => void
	label: string
	children: React.ReactElement
	className?: string
}

export type CollapsibleContentStates = 'OPEN' | 'CLOSED'

export function CollapsibleContent(props: CollapsibleContentProps) {
	return (
		<div
			className={`collapsible-content__wrapper ${props.state === 'OPEN' ? 'open' : 'closed'} ${props.className ? props.className : ''}`}
		>
			<div
				className="collapsible-content__header"
				onClick={() => {
					if (props.state === 'CLOSED') {
						props.setState('OPEN')
					} else {
						props.setState('CLOSED')
					}
				}}
			>
				<Typography type="strong" color="secondary">
					{props.label}
				</Typography>
				<Caret state={props.state === 'OPEN' ? 'opened' : 'closed'} />
			</div>
			{props.state === 'OPEN' && <div className="px-10 pb-10">{props.children}</div>}
		</div>
	)
}
