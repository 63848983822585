import { ResourceCardResource } from '@components/resource-card-list/resource-card-list'
import { RequestBaseURL } from 'src/services/axios/http-common.service'

type ComponentProps = {
	resourceCard: ResourceCardResource
}

export function ResourceCard__Image(props: ComponentProps) {
	function getClass(): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`resource-card__image`)

		if (!props.resourceCard.published) {
			classes.push(`opacity-50`)
		}
		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	return (
		<div
			className={getClass()}
			style={{
				backgroundImage: props.resourceCard.hasResourcePicture
					? `url(${RequestBaseURL}/api/v1/resources/${props.resourceCard.resourceId}/resourcePicture)`
					: `url(${props.resourceCard.bgImage})`,
			}}
		></div>
	)
}
