import { LoKationButton } from '@components/button/button'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownOptionProps } from '@components/dropdown/dropdown.types'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalHeader } from '@components/modal/modal-header'
import { Paper } from '@components/paper/paper'
import { TextInput } from '@components/text-input/text-input'
import { useState } from 'react'
import { ToastService } from 'src/services/toast/toast.service'

import { EmailNotificationAPI } from '../../services/email-notification/email-notification.api'
import { EmailNotificationTypes } from '../../services/email-notification/email-notification.typings'

interface FeedbackModalProps {
	dismissModal: () => void
}

function FeedbackModal(props: FeedbackModalProps) {
	const [submitted, setSubmitted] = useState(false)
	const [formFields, setFormFields] = useState<EmailNotificationTypes.EmailSubmitFeedback>({
		requestType: '',
		priorityLevel: '',
		moreInformation: '',
	})
	const [selectedRequestType, setSelectedRequestType] = useState<(string | null)[]>([])
	const [selectedPriorityLevel, setSelectedPriorityLevel] = useState<(string | null)[]>([])

	const [errors, setErrors] = useState({
		requestType: '',
		priorityLevel: '',
		moreInformation: '',
	})

	function validateSimpleString(value: string): boolean {
		return value.length > 0
	}

	// Validation function
	const validateFormFields = () => {
		let formErrors = { ...errors }

		formErrors.requestType = validateSimpleString(formFields.requestType) ? '' : 'You must select a request type'
		formErrors.priorityLevel = validateSimpleString(formFields.priorityLevel)
			? ''
			: 'You must select a priority level'
		formErrors.moreInformation = validateSimpleString(formFields.moreInformation) ? '' : 'You must provide details'

		setErrors(formErrors)
		return Object.values(formErrors).every((x) => x === '')
	}

	async function sendNotification() {
		if (!validateFormFields()) {
			ToastService().create({
				type: 'ERROR',
				body: 'Please fill out all fields',
			})
			return
		}

		const formData: EmailNotificationTypes.EmailSubmitFeedback = {
			requestType: formFields.requestType,
			priorityLevel: formFields.priorityLevel,
			moreInformation: formFields.moreInformation,
		}

		console.log(formData)

		EmailNotificationAPI.feedbackNotification(formData)
			.then((res) => {
				setSubmitted(true)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	function getRequestType(): DropdownOptionProps<string | null>[] {
		return [
			{ value: 'Feature/Bug', label: 'Feature/Bug' },
			{ value: 'Support/Help', label: 'Support/Help' },
		]
	}

	function getPriorityLevel(): DropdownOptionProps<string | null>[] {
		return [
			{ value: 'High', label: 'High' },
			{ value: 'Medium', label: 'Medium' },
			{ value: 'Low', label: 'Low' },
		]
	}

	const handleChange = (name: string) => (value: string | number | null) => {
		setFormFields((prevState) => ({ ...prevState, [name]: value ? value.toString() : '' }))
	}
	return (
		<>
			<Modal onClose={props.dismissModal} maxWidth={750} maxHeight={800} fixedHeight={false}>
				<ModalHeader title="Do you have feedback?">
					<></>
				</ModalHeader>
				<ModalBody>
					<Paper bgColor="primary" padding={['all']} marginSize="section" margins={['bottom']}>
						<div className="col-12 flex flex-column-xs-down flex-columnReverse-xs-down">
							<div className="col-12">
								<>
									{!submitted ? (
										<>
											<div className="registration__field-wrapper flex-justifyContent-center">
												<Dropdown<string | null>
													label="Request Type"
													value={selectedRequestType}
													options={getRequestType()}
													onSelect={(selectedValues) => {
														setSelectedRequestType(selectedValues)
														if (
															selectedValues &&
															selectedValues.length > 0 &&
															selectedValues[0]
														) {
															const updatedRequestType = selectedValues[0]
															setFormFields((prevState) => ({
																...prevState,
																requestType: updatedRequestType
																	? updatedRequestType.toString()
																	: '',
															}))
														}
													}}
												/>

												<Dropdown<string | null>
													label="Priority"
													value={selectedPriorityLevel}
													options={getPriorityLevel()}
													onSelect={(selectedValues) => {
														setSelectedPriorityLevel(selectedValues)
														if (
															selectedValues &&
															selectedValues.length > 0 &&
															selectedValues[0]
														) {
															const updatedPriorityLevel = selectedValues[0]
															setFormFields((prevState) => ({
																...prevState,
																priorityLevel: updatedPriorityLevel
																	? updatedPriorityLevel.toString()
																	: '',
															}))
														}
													}}
												/>

												<div className="col-xs-12 mb-20" style={{ gridColumn: '1 / span 2' }}>
													<TextInput
														label={'Please provide some details...'}
														rows={4}
														dataType="text"
														value={formFields.moreInformation}
														width="100%"
														onChange={handleChange('moreInformation')}
													/>
												</div>
												<div>
													<LoKationButton
														variant="contained"
														size="lg"
														primary={true}
														label={'Submit'}
														onClick={sendNotification}
														className="mb-20"
													/>
												</div>
											</div>
										</>
									) : (
										<div className="mb-20">Your request has been submitted.</div>
									)}
								</>
							</div>
						</div>
					</Paper>
				</ModalBody>
			</Modal>
		</>
	)
}

export default FeedbackModal
