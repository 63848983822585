import './color-chip.scss'

import { useEffect, useRef, useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import { ToastService } from 'src/services/toast/toast.service'

import { ColorService } from '../../services/color/color.service'
import { UsePopoverOnEvent } from '../../services/hooks/use-popover-on-event'
import { Popover } from '../popover/popover'
import { ColorPickerSuggestionsBar } from './color-picker-suggestions-bar'

interface ColorChipProps {
	color: string
	contrastAmount?: number
	requireContrastWith?: string
	requireLowContrastWith?: string
	/** If set to true, the color chip will not allow users to select a color that is close to either black or white */
	requireNoExtremes?: boolean
	className?: string
	handleChange: (color: string) => void
	showSuggestions?: boolean
}

export const ColorChip = (props: ColorChipProps) => {
	// Set state for component
	const [isPopoverOpen, setIsPopoverOpen] = useState(false)
	const [tempColor, updateTempColor] = useState(props.color) // Descibes the color selected by the user in the color picker. This isn't necessarily the color that is committed, and could be an invalid color

	const popoverRef = useRef<HTMLDivElement>(null)
	const popoverProps = UsePopoverOnEvent({
		isPopoverOpen: isPopoverOpen,
		setIsPopoverOpen: setIsPopoverOpen,
		delay: 0,
	})

	useEffect(() => {
		updateTempColor(props.color)
		if (textInput.current) {
			textInput.current.value = props.color
		}
	}, [props.color])

	const textInput = useRef<HTMLInputElement>(null)

	// Validate color before setting it
	function validateColor(color: string, showToast: boolean): Array<string> {
		const contrastAmount = props.contrastAmount ? props.contrastAmount : 75
		const errorMessages: Array<string> = []

		// Add a pound sign if it isn't already part of the string
		if (!color.includes('#')) {
			color = `#${color}`
		}

		if (!ColorService.isValidHexColor(color)) {
			errorMessages.push(`Hexedecimal color is not valid`)
		}

		// Check contrast (if required)
		if (props.requireContrastWith) {
			const colorContrast = ColorService.getToneDifference(color, props.requireContrastWith)
			if (colorContrast < contrastAmount) {
				errorMessages.push(`Color conflicts with theme settings`)
			}
		}
		if (props.requireLowContrastWith) {
			const colorContrast = ColorService.getToneDifference(color, props.requireLowContrastWith)
			if (colorContrast > contrastAmount) {
				errorMessages.push(`Color conflicts with theme settings`)
			}
		}
		if (props.requireNoExtremes) {
			const colorTone = ColorService.getColorToneValue(color)
			if (colorTone < 35) {
				errorMessages.push(`Color is too dark`)
			}
			if (colorTone > 210) {
				errorMessages.push(`Color is too light`)
			}
		}

		if (showToast && errorMessages.length > 0) {
			const errorMessage = errorMessages.reduce((fullString, thisErrorString) => {
				return `${fullString}, ${thisErrorString}`
			})

			ToastService().create({
				type: 'ERROR',
				body: errorMessage,
			})
		}

		return errorMessages
	}

	function handleOnChange(value: string): void {
		const validationErrorMessages = validateColor(value, false)
		if (validationErrorMessages.length === 0) {
			setColor(value)
		}
		updateTempColor(value)
	}

	function setColor(color: string): void {
		// Add a pound sign if it isn't already part of the string
		if (!color.includes('#')) {
			color = `#${color}`
		}

		props.handleChange(color)

		if (textInput.current) {
			textInput.current.value = color
		}
	}

	const validationErrors = validateColor(tempColor, false)

	// Render component
	return (
		<div
			className={`color-chip mr-5 mb-5 text-input ${props.className}`}
			style={{ position: 'relative' }}
			ref={popoverRef}
		>
			<div className="swatch">
				<div
					className="swatch-inner"
					style={{ backgroundColor: props.color }}
					onClick={() => {
						setIsPopoverOpen(true)
					}}
				></div>
			</div>
			<div className="flex-fillSpace flex flex-alignItems-center flex-justifyContent-center">
				<input
					className="input__no-frame col-xs-12 text-center text-semibold sm"
					ref={textInput}
					maxLength={7}
					defaultValue={props.color}
					onFocus={(evt) => evt.target.select()}
					onBlur={(evt) => {
						if (validateColor(evt.target.value, true).length === 0) {
							setColor(evt.target.value)
						} else if (textInput.current) {
							textInput.current.value = props.color
						}
						updateTempColor(evt.target.value)
					}}
				/>
			</div>

			{popoverRef.current && (
				<Popover
					hideOnMouseOut={false}
					hideOnClickOutside={true}
					isScrimVisible={true}
					{...popoverProps}
					refElement={popoverRef.current}
					setShowPopover={(newState) => {
						setIsPopoverOpen(newState)
					}}
					options={{}}
				>
					<div className="color-chip__popover picker-wrapper">
						{validationErrors.length > 0 && (
							<>
								{/* // <Tooltip
						// 	body={validationErrors.reduce((fullString, thisErrorString) => {
						// 		return `${fullString}, ${thisErrorString}`
						// 	})}
						// 	icon={['fas', 'exclamation-triangle']}
						// 	style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 10 }}
						// 	iconColor={'#ffffff'}
						// /> */}
							</>
						)}
						<HexColorPicker
							color={props.color}
							onChange={(value) => {
								handleOnChange(value)
							}}
						/>

						{props.showSuggestions && (
							<ColorPickerSuggestionsBar
								onChange={(value) => {
									handleOnChange(value)
								}}
							/>
						)}
					</div>
				</Popover>
			)}
		</div>
	)
}
