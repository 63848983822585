import { LoKationButton } from '@components/button/button'
import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'

import { MLSBoardOption } from '../mls-board__option'
import { ReferralTypeOption } from '../referral-type__option'
import { useDomainAdmin, useDomainAdminDispatch } from '../state/domain-admin__state'

export function DomainAdminRegistrationSection() {
	const domainAdminState = useDomainAdmin()
	const domainAdminDispatch = useDomainAdminDispatch()

	return (
		<>
			<h1 className="mb-10">Registration</h1>
			<Paper bgColor="primary" padding={['all']} marginSize="section" margins={['bottom']}>
				<Typography type="h6" color="primary" margins={['bottom']}>
					MLS Board Options
				</Typography>
				{domainAdminState.modified.mlsBoardOptions &&
					domainAdminState.modified.mlsBoardOptions.map((mlsBoardOption) => {
						return (
							<MLSBoardOption
								mlsBoard={mlsBoardOption}
								key={mlsBoardOption.mlsBoardId}
								onChange={(updatedOption) => {
									domainAdminDispatch({
										type: 'update-mls-board-option',
										payload: updatedOption,
									})
								}}
								onDelete={() => {
									domainAdminDispatch({
										type: 'remove-mls-board-option',
										payload: mlsBoardOption.mlsBoardId,
									})
								}}
							/>
						)
					})}
				<LoKationButton
					label="Add Option"
					variant="outlined"
					className="mb-40"
					size="sm"
					onClick={() => {
						domainAdminDispatch({
							type: 'create-mls-board-option',
							payload: null,
						})
					}}
				/>

				<Typography type="h6" color="primary" margins={['bottom']}>
					"How did you hear about us" Options
				</Typography>
				{domainAdminState.modified.referralTypeOptions &&
					domainAdminState.modified.referralTypeOptions.map((referralTypeOption) => {
						return (
							<ReferralTypeOption
								referralType={referralTypeOption}
								key={referralTypeOption.referralTypeId}
								onChange={(updatedOption) => {
									domainAdminDispatch({
										type: 'update-referral-type-option',
										payload: updatedOption,
									})
								}}
								onDelete={() => {
									domainAdminDispatch({
										type: 'remove-referral-type-option',
										payload: referralTypeOption.referralTypeId,
									})
								}}
							/>
						)
					})}
				<LoKationButton
					label="Add Option"
					variant="outlined"
					size="sm"
					onClick={() => {
						domainAdminDispatch({
							type: 'create-referral-type-option',
							payload: null,
						})
					}}
				/>
			</Paper>
		</>
	)
}
