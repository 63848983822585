import { Paper } from '@components/paper/paper'
import { Typography } from '@components/text/text'

import { FeaturedHubEditor } from '../featured-hub-editor/featured-hub-editor'
import { FeaturedResourceEditor } from '../featured-resource-editor/featured-resource-editor'

export function DomainAdminLandingSection() {
	return (
		<>
			<h1 className="mb-10">Landing Page</h1>
			<Paper bgColor="primary" padding={['all']} marginSize="section" margins={['bottom']}>
				<Typography type="h6" color="primary">
					Featured Hubs
				</Typography>
				<Typography type="normal" color="secondary" margins={['bottom']}>
					Determine which hubs you will present to users immediately after logging in.
				</Typography>
				<FeaturedHubEditor className="mb-40" />

				<Typography type="h6" color="primary">
					Featured Resources
				</Typography>
				<Typography type="normal" color="secondary" margins={['bottom']}>
					Determine which resources you will present to users immediately after logging in.
				</Typography>
				<FeaturedResourceEditor />
			</Paper>
		</>
	)
}
