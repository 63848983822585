import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { LabelValueDisplayTypes } from './label-value-display.types'

/** Component to display a label, as well as a non-editable value, in a single row */
export function LabelValueDisplay(props: LabelValueDisplayTypes.Component) {
	return (
		<div className="flex flex-alignItems-center col-xs-12 py-5">
			<div className="flex flex-alignItems-center" style={{ width: '150px' }}>
				{props.icon && <FontAwesomeIcon icon={['far', props.icon]} className="mr-10" />}
				<Typography type="semibold" color="secondary">
					{props.label}
				</Typography>
			</div>
			<div className="flex flex-fillSpace">
				<Typography type="semibold" color="primary">
					{props.value}
				</Typography>
			</div>
		</div>
	)
}
