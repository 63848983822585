import { Scrim } from '@components/scrim/scrim'
import { flip, offset, shift, useDismiss, useFloating, UseFloatingOptions, useInteractions } from '@floating-ui/react'
import React, { useEffect } from 'react'
import { useState } from 'react'
import ReactDOM from 'react-dom'

import { DISMISS_DELAY } from './popover.services'

export type PopoverProps = {
	children: React.ReactElement
	/** Hides the popover when the mouse leaves the contents of the popover and onto the scrim */
	hideOnMouseOut: boolean
	hideOnClickOutside: boolean
	options: Partial<UseFloatingOptions>
	showPopover: boolean
	/** Reference to the element this will be rendered next to */
	refElement: HTMLElement
	/** ParentRef allows you to render the tooltip somewhere other than the document body. This is not recommended for most implementations */
	parentRef?: HTMLElement
	style?: React.CSSProperties
	zIndex?: number
	setShowPopover: (state: boolean) => void
	isScrimVisible: boolean
}

/** Generic component used to simplify implementation of other components that use popovers */
export function Popover(props: PopoverProps) {
	const [mouseInFirstTimeTimer, setMouseInFirstTimeTimer] = useState<NodeJS.Timeout | null>(null)
	const [popoverTimer, setPopoverTimer] = useState<NodeJS.Timeout | null>(null)

	const floating = useFloating(getFloatingOptions())

	const popoverStyle: React.CSSProperties = {
		...floating.floatingStyles,
		...(props.style ? props.style : {}),
		...{ maxHeight: getMaxHeight(floating.floatingStyles), pointerEvents: 'auto' },
	}

	/** Set the popover to close itself if the user never mouses into it */
	useEffect(() => {
		if (!props.hideOnMouseOut) {
			return
		}

		if (props.showPopover) {
			const mouseEnterFirstTimeTimer = setTimeout(() => {
				props.setShowPopover(false)
			}, 2000)
			setMouseInFirstTimeTimer(mouseEnterFirstTimeTimer)
		}
	}, [props.showPopover])

	// const role = useRole(context, { role: 'menu' })
	const dismiss = useDismiss(floating.context, {
		enabled: props.hideOnClickOutside ? true : false,
	})
	const { getFloatingProps } = useInteractions([dismiss])

	function getFloatingOptions(): UseFloatingOptions {
		let options: UseFloatingOptions = {
			placement: 'bottom',
			middleware: [
				offset({ mainAxis: 5, alignmentAxis: 4 }),
				flip({
					fallbackPlacements: ['top', 'left'],
				}),
				shift({ rootBoundary: 'viewport', padding: 10 }),
			],
			open: props.showPopover,
			onOpenChange: (newState) => {
				props.setShowPopover(newState)
			},
			elements: {
				reference: props.refElement,
			},
		}

		if (props.options) {
			options = { ...options, ...props.options }
		}

		return options
	}

	function getMaxHeight(cssProps: React.CSSProperties): number {
		if ('transform' in cssProps && typeof cssProps.transform === 'string') {
			const yAxisPos = parseFloat(cssProps.transform.split(',')[1].split('px')[0])
			return Math.min(window.innerHeight - yAxisPos - 50, 400)
		} else {
			return 400
		}
	}

	function getOnMouseMoveForPortal(): (() => void) | undefined {
		if (props.hideOnMouseOut) {
			return () => {
				const timer = setTimeout(() => {
					props.setShowPopover(false)
				}, DISMISS_DELAY)
				setPopoverTimer(timer)
			}
		} else {
			return undefined
		}
	}

	/** ================================= */
	/** Render Component */

	if (!props.showPopover) {
		return <></>
	}

	return (
		<>
			{ReactDOM.createPortal(
				<Scrim
					onMouseMove={() => {
						if (!props.hideOnMouseOut) {
							return
						}
						getOnMouseMoveForPortal()
					}}
					isVisible={props.isScrimVisible}
					zIndex={props.zIndex ? props.zIndex : 9000}
					style={{ pointerEvents: 'none' }}
				>
					<div
						ref={floating.refs.setFloating}
						style={popoverStyle}
						onClick={(evt) => {
							evt.stopPropagation()
						}}
						onMouseMove={(evt) => {
							if (props.hideOnMouseOut) {
								evt.stopPropagation()
							}
						}}
						onMouseLeave={() => {
							if (!props.hideOnMouseOut) {
								return
							}
							const timer = setTimeout(() => {
								props.setShowPopover(false)
							}, DISMISS_DELAY)
							setPopoverTimer(timer)
						}}
						onMouseEnter={() => {
							if (!props.hideOnMouseOut) {
								return
							}
							if (mouseInFirstTimeTimer) {
								clearTimeout(mouseInFirstTimeTimer)
								setMouseInFirstTimeTimer(null)
							}
							props.setShowPopover(true)
							if (popoverTimer) {
								clearTimeout(popoverTimer)
								setPopoverTimer(null)
							}
						}}
						{...getFloatingProps()}
					>
						{props.children}
					</div>
				</Scrim>,
				props.parentRef ? props.parentRef : document.body,
			)}
		</>
	)
}
