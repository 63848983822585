import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { EventCalendar } from '../../components/event-calendar/event-calendar'
import { Paper } from '../../components/paper/paper'
import { RootState } from '../../redux/store'
import { CalendarSelectorDropdown } from '../../routes/home/components/calendar-selector-dropdown'
import { DownloadCalendarButton } from '../../routes/home/components/download-calendar-button/download-calendar-button'
import { EndUserProps } from '../../services/user/user.types'

interface CalendarFullSizeRouteProps {
	currentUser: EndUserProps | null
}

export function CalendarFullSizeRoutePrototype({ currentUser }: CalendarFullSizeRouteProps) {
	const [selectedCalendar, setSelectedCalendar] = useState<null | number>(null)
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)

	// Function to update the window width when the window is resized
	const handleResize = () => {
		setWindowWidth(window.innerWidth)
	}

	useEffect(() => {
		// Add event listener for window resize
		window.addEventListener('resize', handleResize)

		// Remove event listener when component unmounts
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	function getLicensedStateIdForCurrentUser(): number[] {
		if (!currentUser) {
			return []
		}

		return currentUser.licenseInformation.map((license) => {
			return license.licensedState.licensedStateId
		})
	}

	return (
		<>
			<RouteWrapperV2>
				<RouteBodyV2>
					<h1 className="mb-20">Calendar</h1>
					<Paper bgColor="primary" padding={['all']} marginSize="section" margins={['bottom']}>
						<CalendarSelectorDropdown
							selectedCalendarId={selectedCalendar}
							onSelect={(value) => {
								setSelectedCalendar(value)
							}}
						/>
						<EventCalendar
							initialView={windowWidth >= 999 ? 'dayGridMonth' : 'listMonth'}
							style={{ height: '800px' }}
							calendarId={selectedCalendar ? selectedCalendar : 'all'}
							filterByTags={[]}
							filterByLicensedStates={getLicensedStateIdForCurrentUser()}
							onEventClick="open-details"
						/>

						<DownloadCalendarButton selectedCalendarId={selectedCalendar} />
					</Paper>
				</RouteBodyV2>
			</RouteWrapperV2>
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const CalendarFullSizeRoute = connect(mapStateToProps)(CalendarFullSizeRoutePrototype)
