import './administration.scss'

import { Paper } from '@components/paper/paper'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { Typography } from '@components/text/text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from '@redux/store'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { UserService } from '../../services/user/user.service'
import { EndUserProps } from '../../services/user/user.types'
import { adminRoutes } from '../routes.constant'
import { RouteData } from '../routes.types'
import { BackToHomeButton } from './components/back-to-home-button'

function AdministrationHomeRoutePrototype(props: { userProfile: EndUserProps | null }) {
	const isUserAdmin = props.userProfile ? UserService.isUserAdmin(props.userProfile) : false

	const navigate = useNavigate()

	useEffect(() => {
		if (!isUserAdmin) {
			navigate('/hubs')
		}
	}, [isUserAdmin])

	function createRouteCard(route: RouteData): React.ReactElement {
		const href = typeof route.href === 'function' ? route.href() : route.href

		return (
			<Paper
				bgColor="primary"
				padding={['all']}
				margins={['right', 'bottom']}
				interactive={true}
				className="flex flex-alignItems-center col-xs-12 col-sm-5"
				onClick={() => {
					navigate(href)
				}}
				key={href}
			>
				<FontAwesomeIcon
					icon={['far', route.icon]}
					className="mr-20 opacity-50"
					size="2x"
					style={{ width: '50px' }}
				/>
				<div>
					<Typography type="h6" color="primary">
						{route.label}
					</Typography>
					<Typography type="normal" color="secondary">
						{route.description}
					</Typography>
				</div>
			</Paper>
		)
	}

	return (
		<RouteWrapperV2>
			<RouteBodyV2 className="flex-column">
				<BackToHomeButton />
				<h1 className="mb-10">Administration</h1>

				<div className="flex flex-wrap">
					{adminRoutes().map((route) => {
						if (route.visible) {
							return createRouteCard(route)
						} else {
							return <React.Fragment key={route.label}></React.Fragment>
						}
					})}
				</div>
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
	}
}

export const AdministrationHomeRoute = connect(mapStateToProps)(AdministrationHomeRoutePrototype)
