import { TransactionMgmtTypes } from './transaction-mgmt.types'

export const TransactionMgmt__Transactions: TransactionMgmtTypes.TransactionTypes.Transaction[] = [
	{
		transactionId: 100,
		name: '1115 Coyote Gulch Way, Bluffdale UT 84065',
		type: `RENTAL_COMMERCIAL`,
		status: 'ACTIVE',
		salePrice: 100000,
		closeDate: 1718060148000,
		acceptedDate: 1718080148000,
		mlsNumber: `A19220312`,
		location: `LoKation Real Estate — Florida`,
		contacts: [
			{
				fullName: `Lilian Giselle Madrid Montaldo`,
				title: `Listing Agent`,
				organization: `United Realty Group, Inc.`,
				email: `gmadridrealestate@yahoo.com`,
				phone: 9549912767,
			},
			{
				fullName: `Lynn Magdali`,
				title: `Listing Agent`,
				organization: `Butler Title, LLC`,
				email: `lyn@butlertitle.com`,
				phone: 9546168735,
			},
		],
		sellerContact: {
			fullName: `Jose Damian Passarini`,
			title: ``,
			organization: ``,
			email: ``,
			phone: 2082062329,
		},
		buyerContact: {
			fullName: `Eugenia Sanon / Jajah Tassay`,
			title: ``,
			organization: ``,
			email: ``,
			phone: 2083578819,
		},
		transactionOwners: [],
		transactionWatchers: [],
		side: 'BUYING',
		tasks: [
			{
				taskId: 1001,
				name: `[Task Name]`,
				description: `[Task Description]`,
				parentTransactionId: 100,
				dueDate: 1718080148000,
				completionDate: 1718080148000,
				requiredDocs: [
					{
						documentId: 1001,
						name: `Document (Online Form)`,
						description: `Lorem ipsum dolor iset`,
						submittedBy: 909,
						submittedDate: 1718080148000,
						formUrl: `www.google.com`,
						parentTaskId: 1001,
						parentTransactionId: 100,
						status: `APPROVED`,
						type: `ONLINE_FORM`,
						reviewedByUserId: 20,
						reviewedDate: 123535456,
						reviewReason: ``,
					},
					{
						documentId: 1002,
						name: `Document (Upload)`,
						description: `Lorem ipsum dolor iset`,
						parentTaskId: 1001,
						parentTransactionId: 100,
						status: `PENDING`,
						type: `FILE_UPLOAD`,
						reviewedByUserId: 20,
						reviewedDate: 123535456,
						reviewReason: ``,
						uploadDate: 4355346645,
						uploadedBy: 10,
					},
				],
			},
		],
	},
]
