import { IconName } from '@fortawesome/fontawesome-svg-core'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef, useState } from 'react'

import { UsePopoverOnEvent } from '../../services/hooks/use-popover-on-event'
import { Popover } from '../popover/popover'
import { TooltipPopover } from './tooltip-popover'

export interface TooltipProps {
	body: string | React.ReactNode
	icon?: IconName
	tooltipName?: string
	className?: string
	disablePopoverBackground?: boolean
	maxWidth?: string
	size?: SizeProp
}

export function Tooltip(props: TooltipProps) {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false)
	const popoverRef = useRef<HTMLDivElement>(null)
	const popoverProps = UsePopoverOnEvent({ isPopoverOpen, setIsPopoverOpen, delay: 250 })

	function renderIconOrText() {
		if (props.icon) {
			return <FontAwesomeIcon icon={['far', props.icon]} size={props.size} />
		} else if (props.tooltipName) {
			return <span>{props.tooltipName}</span>
		} else {
			return <FontAwesomeIcon icon={['far', 'question-circle']} />
		}
	}

	function getClassName(): string {
		let className = 'tooltip-icon'

		if (props.className) {
			className = `${className} ${props.className}`
		}

		return className
	}

	return (
		<>
			<div
				className={getClassName()}
				ref={popoverRef}
				onMouseEnter={() => {
					setIsPopoverOpen(true)
				}}
				onMouseLeave={() => {
					setIsPopoverOpen(false)
				}}
			>
				{renderIconOrText()}
			</div>

			{popoverRef.current && (
				<Popover
					hideOnMouseOut={true}
					hideOnClickOutside={true}
					isScrimVisible={false}
					{...popoverProps}
					refElement={popoverRef.current}
					setShowPopover={(newState) => {
						setIsPopoverOpen(newState)
					}}
					options={{}}
				>
					<TooltipPopover width={'min-content'}>
						<div className="col-xs-12 text-center" style={{ maxWidth: props.maxWidth }}>
							{props.body}
						</div>
					</TooltipPopover>
				</Popover>
			)}
		</>
	)
}
