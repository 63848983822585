import { LoginRouteWrapper } from '@components/login-route-wrapper/login-route-wrapper'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'
import { EndUserProps } from 'src/services/user/user.types'

import { Domain } from '../../services/domain/domain.types'
import AgentLicenses from '../administration/user-profile/components/agent-licenses'
interface ConnectedProps {
	currentUser: EndUserProps | null
	domain: Domain.Properties
}

function RejoinPrototype(props: { userToEdit: EndUserProps } & ConnectedProps) {
	return (
		<LoginRouteWrapper
			isLoginRoute={false}
			bodyClassName="flex flex-column flex-justifyContent-center flex-fillSpace flex-alignItems-center col-xs-12"
		>
			<>
				{props.domain && (
					<div className="clamp-width-for-rejoin-route">
						<h3 className="mb-20">Hey, {props.currentUser?.firstName}!</h3>
						<p>
							<strong>Follow the steps to reactivate your {props.domain.label} account.</strong>
						</p>
						<p>
							In order to re-activate your account, you need to have an active plan. Please follow the
							steps to purchase a new plan for your current license or modify an existing one. If you have
							multiple licenses, you can pay for it once you are re-activated.
						</p>
						{props.currentUser && (
							<AgentLicenses
								userToEdit={props.currentUser}
								currentUser={props.currentUser}
								rejoin={true}
							/>
						)}
					</div>
				)}
			</>
		</LoginRouteWrapper>
	)
}

function mapStateToProps(state: RootState) {
	return {
		domain: state.domain,
		currentUser: state.user,
	}
}

export const Rejoin = connect(mapStateToProps)(RejoinPrototype)
