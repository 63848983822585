/* eslint-disable react/jsx-pascal-case */
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { MultiColumnTableService } from '../multi-column-table.service'
import { MultiColumnTableTypes } from '../multi-column-table.types'
import { useMultiColumnTable, useMultiColumnTableDispatch } from '../state/multi-column-table__state'

/** Header on the item table. Displays column names as well as functionality to click on a column to sort by it */
export function MultiColumnTableHeader<T extends Object>(props: MultiColumnTableTypes.Header<T>) {
	const newItemTableState = useMultiColumnTable()
	const newItemTableDispatch = useMultiColumnTableDispatch()
	const sortDirectionToUse: MultiColumnTableTypes.SortDirection =
		newItemTableState.sortMethod.sortMethod === 'column-value' && newItemTableState.sortMethod.direction
			? newItemTableState.sortMethod.direction
			: 'desc'
	const sortedColumns = MultiColumnTableService.sortColumnsByDisplayIndex(
		props.columns,
		newItemTableState.columnConfigOverrides,
	)

	/** ================================= */
	/** Methods */

	function getColumnClass(column: MultiColumnTableTypes.Column<T>, isActiveSortColumn: boolean): string {
		const classes: string[] = []
		let classString = ''

		classes.push(`multi-column-table__header-column fs-unmask`)

		if (isActiveSortColumn) {
			classes.push(`is-active`)
		}

		if ((!column.align && column.displayIndex > 0) || column.align === 'right') {
			classes.push(`flex-justifyContent-end`)
		}
		if (column.align === 'left') {
			classes.push(`flex-justifyContent-start`)
		}

		if (column.columnId === null) {
			classes.push(`is-disabled`)
		}

		classes.forEach((thisClass) => {
			classString += `${thisClass} `
		})

		return classString
	}

	function createHeaderColumn(column: MultiColumnTableTypes.Column<T>): React.ReactNode {
		const isActiveSortColumn =
			newItemTableState.sortMethod.sortMethod === 'column-value' &&
			newItemTableState.sortMethod.columnId === column.columnId
		const sortIcon: IconName =
			newItemTableState.sortMethod.sortMethod === 'column-value' && sortDirectionToUse === 'desc'
				? 'angle-down'
				: 'angle-up'

		const hasExternalSortMethod = newItemTableState.sortMethod.sortMethod === 'sort-function'

		return (
			<div
				className={getColumnClass(column, isActiveSortColumn)}
				key={column.columnId}
				style={MultiColumnTableService.getColumnStyle(column)}
				onClick={() => {
					onClickColumn(column)
				}}
			>
				<span>{column.label}</span>
				{isActiveSortColumn && !hasExternalSortMethod && (
					<FontAwesomeIcon icon={['far', sortIcon]} className="ml-10" />
				)}
			</div>
		)
	}

	function onClickColumn(column: MultiColumnTableTypes.Column<T>): void {
		let newSortMethod: MultiColumnTableTypes.ColumnValueSortMethod

		if (!column.columnId) {
			return
		}

		/** Do not update the sort method if this table is sortable */
		if (newItemTableState.sortMethod.sortMethod === 'display-index') {
			return
		}

		/** Do not update the sort method if this table is sorted by an external function */
		if (newItemTableState.sortMethod.sortMethod === 'sort-function') {
			return
		}

		if (newItemTableState.sortMethod.columnId !== column.columnId) {
			/** If user is changing the selected sort column */
			newSortMethod = {
				sortMethod: 'column-value',
				columnId: column.columnId,
				direction: 'desc',
			}
		} else {
			/** If user is changing the sort direction but the selected column has not changed */
			newSortMethod = {
				sortMethod: 'column-value',
				columnId: column.columnId,
				direction: sortDirectionToUse === 'asc' ? 'desc' : 'asc',
			}
		}

		newItemTableDispatch({ type: 'set-sort-method', payload: newSortMethod })
	}

	/** ================================= */
	/** Render Component */

	return (
		<div className="multi-column-table__header">
			{sortedColumns.map((column, index) => {
				const isColumnVisible = MultiColumnTableService.isColumnVisible(
					column,
					newItemTableState.columnConfigOverrides,
				)
				if (!isColumnVisible) {
					return <React.Fragment key={column.columnId}></React.Fragment>
				}
				if (!props.showAllColumns && index > 0) {
					return <React.Fragment key={column.columnId}></React.Fragment>
				}

				return createHeaderColumn(column)
			})}
			{newItemTableState.sortMethod.sortMethod === 'display-index' && (
				<div className="multi-column-table__header__sort-column-spacer"></div>
			)}
		</div>
	)
}
