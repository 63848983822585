/* eslint-disable react/jsx-pascal-case */
import './context-menu.scss'

import { autoUpdate, flip, offset, shift, useDismiss, useFloating, useInteractions, useRole } from '@floating-ui/react'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import { ContextMenuTypes } from './context-menu.types'
import { ContextMenu__Inner } from './context-menu__inner'

/** The min z index is based off the z-index of a modal. This number gaurantees it will open above a modal */
const MIN_Z_INDEX = 1000

export function ContextMenu(props: ContextMenuTypes.Component) {
	const [zIndex, setZIndex] = useState(MIN_Z_INDEX)
	const { refs, floatingStyles, context } = useFloating({
		open: props.visible,
		onOpenChange: () => {
			props.onDismiss()
		},
		middleware: [
			offset({ mainAxis: 5, alignmentAxis: 5 }),
			flip({
				fallbackPlacements: ['left-start'],
			}),
			shift({ padding: 10 }),
		],
		placement: props.placement ? props.placement : 'right-start',
		strategy: 'fixed',
		elements: {
			reference: props.position === 'relative' ? props.referenceElement : undefined,
		},
		whileElementsMounted: autoUpdate,
	})

	useEffect(() => {
		if (props.position === 'absolute') {
			refs.setPositionReference({
				getBoundingClientRect() {
					return {
						width: 0,
						height: 0,
						x: props.x,
						y: props.y,
						top: props.y,
						right: props.x,
						bottom: props.y,
						left: props.x,
					}
				},
			})
		}

		let highestZIndex = MIN_Z_INDEX
		document.body.childNodes.forEach((childNode) => {
			if (childNode instanceof HTMLElement && typeof childNode.style.zIndex !== undefined) {
				if (parseFloat(childNode.style.zIndex) > highestZIndex) {
					highestZIndex = parseFloat(childNode.style.zIndex)
				}
			}
		})
		setZIndex(highestZIndex)
	}, [props.visible])

	const role = useRole(context, { role: 'menu' })
	const dismiss = useDismiss(context)

	const { getFloatingProps } = useInteractions([role, dismiss])

	if (!props.visible) {
		return <></>
	}

	return (
		<>
			{ReactDOM.createPortal(
				<div
					ref={refs.setFloating}
					style={{ ...floatingStyles, zIndex }}
					onClick={(evt) => {
						props.onDismiss()
						evt.preventDefault()
						evt.stopPropagation()
					}}
					onMouseDown={(evt) => {
						evt.preventDefault()
						evt.stopPropagation()
					}}
					{...getFloatingProps()}
				>
					<ContextMenu__Inner {...props} />
				</div>,

				document.body,
			)}
		</>
	)
}
