import { TransactionMgmtTypes } from './transaction-mgmt.types'

export namespace TransactionManagementService {
	export function getLabelForTransactionType(type: TransactionMgmtTypes.TransactionTypes.Type): string {
		switch (type) {
			case 'RENTAL_COMMERCIAL':
				return `Rental (Commercial)`
			case 'RENTAL_COMPLEX_INVOICE':
				return `Rental (Complex Invoice)`
			case 'RENTAL_RESIDENTIAL':
				return `Rental (Residential)`
			case 'SALE_COMMERCIAL':
				return `Sale (Commercial)`
			case 'SALE_EQUITABLE_TITLE':
				return `Sale (Equitable Title)`
			case 'SALE_LISTING_SIDE_RESIDENTIAL':
				return `Sale (Listing Side Residential)`
			case 'SALE_NEW_CONSTRUCTION':
				return `Sale (New Construction)`
			case 'SALE_SALE_SIDE_RESIDENTIAL':
				return `Sale (Sale Side Residential)`
			case 'SALE_VACANT_LAND':
				return `Sale (Vacant Land)`
			case 'BUYER_REPRESENTATION_AGREEMENT_FOLDER':
				return `Buyer Representation Agreement Folder`
			case 'REFERRAL_TRANSACTION':
				return `Referral Transaction`
		}
	}

	export function getLabelForTransactionStatus(status: TransactionMgmtTypes.TransactionTypes.Status): string {
		switch (status) {
			case 'ACTIVE':
				return `Active`
			case 'INACTIVE':
				return `Inactive`
		}
	}

	export function getLabelForTransactionSide(side: TransactionMgmtTypes.TransactionTypes.Side): string {
		switch (side) {
			case 'BUYING':
				return `Buying`
			case 'BUYING_SELLING':
				return `Buying / Selling`
			case 'SELLING':
				return `Selling`
		}
	}
}
