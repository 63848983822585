import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { transactionMgmtRoutePath } from 'src/routes/routes.constant'
import { EndUserProps } from 'src/services/user/user.types'

function TransactionMgmt__Reports__RoutePrototype(props: { userProfile: EndUserProps | null }) {
	const navigate = useNavigate()

	return (
		<RouteWrapperV2>
			<RouteSubnavigation
				isCustom={false}
				title={`Commission Reports`}
				backButton={{
					label: 'Transaction Management',
					onClick: () => {
						navigate(`${transactionMgmtRoutePath}`)
					},
				}}
				routes={[]}
			/>

			<RouteBodyV2 className="flex flex-column flex-alignItems-start">BODY</RouteBodyV2>
		</RouteWrapperV2>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
	}
}

export const TransactionMgmt__Reports__Route = connect(mapStateToProps)(TransactionMgmt__Reports__RoutePrototype)
