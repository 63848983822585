import { LoKationButton } from '@components/button/button'
import { Checkbox } from '@components/checkbox/checkbox'
import { IconButton } from '@components/icon-button/icon-button'
import { Paper } from '@components/paper/paper'
import { RichTextEditor } from '@components/rich-text-editor/rich-text-editor'
import { SideSheet } from '@components/side-sheet/side-sheet'
import { SideSheetTypes } from '@components/side-sheet/side-sheet.types'
import { PhoneInput } from '@components/text-input/phone-input'
import { TextInput } from '@components/text-input/text-input'
import { useEffect, useState } from 'react'
import { GenericDeleteConfirmationModal } from 'src/modals/generic-delete-confirmation/generic-delete-confirmation'
import { LeadAPI } from 'src/services/lead/lead.api'
import { FullLeadProps } from 'src/services/lead/lead.types'
import { LeadNoteAPI } from 'src/services/lead-note/lead-note.api'
import { LeadNotesTypes } from 'src/services/lead-note/lead-note.types'
import { UserAPI } from 'src/services/user/user.api'
import { EndUserProps } from 'src/services/user/user.types'

interface EditLeadSideSheetProps {
	leadToEdit: string
	onClose: () => void
}

export function EditLeadSideSheet(props: EditLeadSideSheetProps) {
	/** ======================================= */
	/** Props and State */

	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [leadObject, setLeadObject] = useState<FullLeadProps>()
	const [leadNotes, setLeadNotes] = useState<LeadNotesTypes.Note[]>([])
	const [newNote, setNewNote] = useState('')
	const [referrer, setReferrer] = useState<EndUserProps>()

	/** ======================================= */
	/** Effects */

	useEffect(() => {
		if (props.leadToEdit) {
			LeadAPI.getLeadDetails(props.leadToEdit).then((res) => {
				setLeadObject(res.data)
			})
			fetchLeadNotes()
		}
	}, [props.leadToEdit])

	useEffect(() => {
		if (newNote === '') {
			LeadNoteAPI.getLeadNotes(props.leadToEdit).then((res) => {
				setLeadNotes(res.data)
			})
		}
	}, [newNote])

	useEffect(() => {
		if (leadObject && leadObject.referrerId) {
			UserAPI.getUserDetails(leadObject.referrerId).then((res) => {
				setReferrer(res.data)
			})
		}
	}, [leadObject])

	/** ======================================= */
	/** Methods */

	function renderFooter(): React.ReactNode {
		return (
			<div className="flex flex-justifyContent-end">
				<LoKationButton
					variant="outlined"
					label="Close"
					size="sm"
					onClick={() => {
						props.onClose()
					}}
				/>
			</div>
		)
	}

	function getActions(): SideSheetTypes.Action[] {
		const actions: SideSheetTypes.Action[] = []

		actions.push({
			label: 'Delete',
			icon: 'trash',
			onClick: () => {
				setShowDeleteModal(true)
			},
		})

		return actions
	}

	const fetchLeadNotes = () => {
		if (props.leadToEdit) {
			LeadNoteAPI.getLeadNotes(props.leadToEdit).then((res) => {
				setLeadNotes(res.data)
			})
		}
	}

	const saveNote = async () => {
		if (leadObject && newNote.trim() !== '') {
			try {
				await LeadNoteAPI.createNote(leadObject.email, newNote)
				setNewNote('')
				fetchLeadNotes()
			} catch (error) {
				console.error(error)
			}
		}
	}

	/** ======================================= */
	/** Render Component */

	return (
		<>
			<SideSheet
				title={`Lead: ${leadObject?.firstName} ${leadObject?.lastName}`}
				actions={getActions()}
				onClose={props.onClose}
				persistent={false}
				width="wide"
				footer={renderFooter()}
			>
				{leadObject && (
					<>
						<h4 className="mb-20">Contact Information</h4>
						<div className="flex flex-wrap mb-20 mt-10">
							<TextInput
								width={300}
								dataType="text"
								label="First name"
								value={leadObject.firstName}
								disabled={true}
								className={`mb-20 mr-20`}
							/>

							<TextInput
								width={300}
								dataType="text"
								label="Last name"
								value={leadObject.lastName}
								disabled={true}
								className={`mb-20 mr-20`}
							/>

							<TextInput
								width={300}
								dataType="text"
								label="Email"
								disabled={true}
								value={leadObject.email}
								className={`mb-20 mr-20`}
							/>

							<PhoneInput
								width={300}
								dataType="number"
								label="Phone number"
								value={parseFloat(leadObject.phone)}
								disabled={true}
								className={`mb-20 mr-20`}
							/>
							{referrer && (
								<TextInput
									width={300}
									dataType="text"
									label="Referrer"
									disabled={true}
									value={referrer.firstName + ' ' + referrer.lastName}
									className={`mb-20 mr-20`}
									postpend={
										<IconButton
											icon={'arrow-up-right-from-square'}
											variant="inline"
											onClick={() => {
												console.log('test')
												window.open(`/administration/users/${referrer.endUserId}`, '_blank')
											}}
											color="#000"
											className="ml-10"
										/>
									}
								/>
							)}
						</div>
					</>
				)}

				<div className="mb-20">
					<h4 className="mb-20">Steps Completed</h4>
					<div>
						{leadObject && (
							<>
								<div className="flex flex-wrap mb-20 mt-10">
									<p>
										The steps below are in order based on the registration process. If a step is
										checked, that means the users has completed that step.
									</p>
									<div className="col-12 col-md-6 mb-20 pr-10">
										<Checkbox
											className="mb-10"
											checked={leadObject.personalInfoCompleted}
											disabled={true}
										>
											1. Personal Information
										</Checkbox>
									</div>
									<div className="col-12 col-md-6 mb-20">
										<Checkbox
											className="mb-10"
											checked={leadObject.agentInfoCompleted}
											disabled={true}
										>
											2. Agent Information
										</Checkbox>
									</div>
									<div className="col-12 col-md-6 mb-20 pr-10">
										<Checkbox
											className="mb-10"
											checked={leadObject.contractCompleted}
											disabled={true}
										>
											3. Contract Conmpletion
										</Checkbox>
									</div>
									<div className="col-12 col-md-6 mb-20">
										<Checkbox
											className="mb-10"
											checked={leadObject.paymentCompleted}
											disabled={true}
										>
											4. Payment Completion
										</Checkbox>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
				<div>
					<h4 className="mb-20">Notes</h4>

					{leadNotes && leadNotes.length > 0 && (
						<>
							{leadNotes.map((note) => (
								<div key={note.leadNotesId} className="mb-20">
									{' '}
									<Paper bgColor="primary" padding={['all']}>
										<div className="flex flex-justifyContent-end mb-5">
											<div style={{ fontSize: '.8em', fontStyle: 'italic' }}>
												{new Date(note.noteTimestamp).toLocaleDateString('en-US', {
													year: 'numeric',
													month: 'long',
													day: 'numeric',
												})}
												{' at '}
												{new Date(note.noteTimestamp).toLocaleTimeString('en-US', {
													hour: '2-digit',
													minute: '2-digit',
													hour12: true,
												})}
											</div>
										</div>

										<div
											dangerouslySetInnerHTML={{
												__html: decodeURIComponent(
													note.note.replace('note=', '').replace(/\+/g, ' '),
												),
											}}
										/>
									</Paper>
								</div>
							))}
						</>
					)}
				</div>

				<div>
					<RichTextEditor
						label={`Enter new note`}
						className="mb-20"
						value={newNote}
						rows={4}
						onChange={(updatedValue) => {
							setNewNote(updatedValue)
						}}
					/>
					<div className="flex flex-justifyContent-end">
						<LoKationButton
							size={'lg'}
							label="Save Note"
							onClick={() => {
								saveNote()
							}}
							variant="contained"
						/>
					</div>
				</div>
			</SideSheet>
			{showDeleteModal && (
				<GenericDeleteConfirmationModal
					itemLabel={`this lead`}
					onDelete={() => {
						if (leadObject && leadObject.email) {
							LeadAPI.deleteLead(leadObject.email).then((res) => {
								setShowDeleteModal(false)
								props.onClose()
							})
						}
					}}
					zIndex={10000}
					onClose={() => {
						setShowDeleteModal(false)
					}}
				/>
			)}
		</>
	)
}
