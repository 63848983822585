import { EndUserProps } from './user.types'

export class UserService {
	static isUserAdmin(userProps: EndUserProps): boolean {
		let isAdmin = false
		userProps.roles.forEach((role) => {
			if (role.roleType === 'ADMIN') {
				isAdmin = true
			}
		})
		return isAdmin
	}

	static isUserSuperAdmin(userProps: EndUserProps): boolean {
		let isSuperAdmin = false
		userProps.roles.forEach((role) => {
			if (role.roleType === 'SUPER_ADMIN') {
				isSuperAdmin = true
			}
		})
		return isSuperAdmin
	}

	// static isUserActive(userProps: EndUserProps): boolean {
	// 	let isUserActive = false
	// 	userProps.roles.forEach((role) => {
	// 		if (role.roleType === 'USER' && userProps.enabled) {
	// 			isUserActive = true
	// 		}
	// 	})
	// 	return isUserActive
	// }
}
